import { DateTime } from 'luxon';

import styles from './BaseDate.module.scss';
import { mcn } from 'common/funcs/mcn.func';

type PropsType = {
  className?: string;
  variant: 'material' | 'request' | 'common';
  value: string;
};

const BaseDate = ({ className = '', variant, value }: PropsType) => {
  const date = new Date(value);
  const dn = date.toLocaleDateString("ru-RU", { timeZone: "Europe/Moscow" });
  const dt = date.toLocaleTimeString("ru-RU", { timeZone: "Europe/Moscow" }).split(':').slice(0, 2).join(':');
  return (
    <>
      <p className={mcn([className, styles['BaseDate_' + variant]])}>
        {
          variant === 'material'
            ? DateTime.fromISO(value)
              .setLocale('ru-RU')
              .setZone("Europe/Moscow")
              .toLocaleString(DateTime.DATE_FULL)
            : variant === "common"
              ? `${dn}`
              : `${dn} - ${dt}`
        }
      </p>
    </>
  );
};

export default BaseDate;

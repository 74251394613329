import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from 'react-hot-toast/headless';

import { BaseButton, BaseInputError, BaseModal, BaseTextarea, BaseTextField } from "components/base"
import { UpdateOneRequestDto, useRequestsControllerDeleteOneMutation, useRequestsControllerGetOneQuery, useRequestsControllerUpdateOneMutation } from "app/api/hooks/requests";
import { Preloader, Select } from "content";

import { useOrganizationsWhoHelpControllerGetAllQuery } from "app/api/hooks/who-help";
import { UpdateRequestScheme } from "common/validation";
import { isSelectedOption } from "common/funcs/is-selected-option.func";
import { OptionType } from "common/types/option.type";
import { useLoading } from 'hooks';
import { em } from "common/funcs/em";
import styles from "./UpdateRequest.module.scss";

const UpdateRequest = () => {
  const navigation = useNavigate();
  const { isRequestLoading, onRequestLoadingHandler } = useLoading();
  const { id } = useParams();

  // Requests to api
  const [updateOneRequest] = useRequestsControllerUpdateOneMutation({ fixedCacheKey: "request-one-mutation" });
  const [deleteOneRequest] = useRequestsControllerDeleteOneMutation({ fixedCacheKey: "request-one-mutation" });
  const { data: whoHelpTypes } = useOrganizationsWhoHelpControllerGetAllQuery();
  const { data: request, isLoading } = useRequestsControllerGetOneQuery({ id: id ? +id : 0 },
    {
      refetchOnMountOrArgChange: true
    }
  );

  // Form hook initialization
  const form = useForm<UpdateOneRequestDto>({
    resolver: yupResolver(UpdateRequestScheme),
    defaultValues: {
      name: "",
      description: "",
      address: "",
      whoHelpTypes: []
    }
  });

  // Form state handler function
  const onStateHandler = (key: keyof UpdateOneRequestDto, value: string | Array<OptionType>) => {
    form.setValue(key, value, {
      shouldValidate: true
    });
  };

  // Form state submit function
  const onSubmitHandler = async (updateOneRequestDto: UpdateOneRequestDto) => {

    if (!id) return;

    try {
      onRequestLoadingHandler(true);
      await updateOneRequest({ id: +id, updateOneRequestDto, }).unwrap();
      toast.success("Запрос успешно обновлён");
      navigation(-1);
    } catch (e) {
      toast.error(em(e));
    } finally {
      onRequestLoadingHandler(false);
    }
  };

  // Delete handler
  const onDeleteHandler = (id: string | number | undefined) => async () => {

    if (!id) return;

    try {
      onRequestLoadingHandler(true);
      await deleteOneRequest({ id: +id }).unwrap()
      toast.success("Запрос успешно удалён");
      navigation(-1);
    } catch (e) {
      toast.error(em(e));
    } finally {
      onRequestLoadingHandler(false);
    }
  }

  // Update state
  React.useEffect(() => {
    if (!request) return;

    form.setValue("name", request.name);
    form.setValue("description", request.description);
    form.setValue("address", request.address);
    form.setValue("whoHelpTypes", request.whoHelpTypes);
  }, [request]);

  // Form state
  const errors = form.formState.errors;
  const state = form.watch();

  return (
    <>
      <BaseModal title="Запрос" isLoading={isLoading}>
        <form className={styles.UpdateRequest} onSubmit={form.handleSubmit(onSubmitHandler)}>
          <ul className={styles.UpdateRequest__list}>
            <li className={styles.UpdateRequest__item}>
              <BaseTextField
                className={styles.UpdateRequest__input}
                onChange={(e) => onStateHandler("name", e.target.value)}
                value={state.name}
                label="Название"
                name="name"
              />
              <BaseInputError
                message={errors["name"]?.message}
              />
            </li>
            <li className={styles.UpdateRequest__item}>
              <BaseTextarea
                className={styles.UpdateRequest__textarea}
                onChange={(e) => onStateHandler("description", e.target.value)}
                value={state.description}
                label="Описание"
                name="description"
              />
              <BaseInputError
                message={errors["description"]?.message}
              />
            </li>
            <li className={styles.UpdateRequest__item}>
              <Select
                onChange={(whoHelpTypes) => {
                  if (Array.isArray(whoHelpTypes)) {
                    onStateHandler('whoHelpTypes', whoHelpTypes);
                  }
                }}
                value={state.whoHelpTypes}
                label="Теги"
                multiple={true}
                variant="select"
              >
                <Select.OptionList>
                  {whoHelpTypes?.map((item) => (
                    (item.id !== 0) && (
                      <Select.OptionItem
                        selected={isSelectedOption(state.whoHelpTypes, item.id)}
                        key={item.id}
                        {...item}
                      />
                    )
                  ))}
                </Select.OptionList>
              </Select>
              <BaseInputError message={errors["whoHelpTypes"]?.message} />
            </li>
            <li className={styles.UpdateRequest__item}>
              <BaseTextField
                className={styles.UpdateRequest__input}
                onChange={(e) => onStateHandler('address', e.target.value)}
                value={state.address}
                label="Адрес для оказания помощи"
              />
              <BaseInputError message={errors["address"]?.message} />
            </li>
            <li className={styles.UpdateRequest__item}>
              <Preloader isLoading={isRequestLoading}>
                <BaseButton
                  className={styles.UpdateRequest__control}
                  fullWidth={true}
                  variant="main_contained_tangerine"
                  type="submit"
                >
                  Сохранить
                </BaseButton>
                <BaseButton
                  className={styles.UpdateRequest__control}
                  onClick={onDeleteHandler(id)}
                  fullWidth={true}
                  variant="main_text"
                >
                  Удалить
                </BaseButton>
              </Preloader>
            </li>
          </ul>
        </form>
      </BaseModal>
    </>
  )
}

export default UpdateRequest;

import React from "react";

import { useAvatarContext } from "common/context";

export function useUploadAvatar() {
  // Avatar context
  const { onHandleState, avatar } = useAvatarContext();

  const [uploadedAvatar, setUploadedAvatar] = React.useState<
    File | undefined
  >();

  const onUploadAvatar =
    (setToContext: boolean = false) =>
    (file: File) => {
      setUploadedAvatar(file);

      if (!setToContext) return;

      onHandleState(URL.createObjectURL(file));
    };

  return {
    avatar,
    uploadedAvatar,
    onUploadAvatar,
  };
}

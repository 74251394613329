import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Stack } from '@mui/material';

import { BasePagination, BaseTab, BaseTabs } from 'components/base';
import { RequestPreview, NoEntries, Preloader } from 'content';

import { Requests, RequestsControllerGetAllApiArg, useRequestsControllerGetAllQuery } from 'app/api/hooks/requests';
import { useEffectAfterMount, useFilters } from 'hooks';
import { RequestStatusEnum } from 'common/enum';
import { propsRequest } from 'common/funcs/props-request.func';
import { OptionType } from 'common/types/option.type';
import { selectUser } from 'app/authSlice';
import styles from './WantHelp.module.scss';

const WantHelp = () => {
  const location = useLocation();
  const user = useSelector(selectUser)
  const [limit] = React.useState(6);
  const [page] = React.useState(1);

  // Filters hook
  const { filters, onHandleFilters } = useFilters<RequestsControllerGetAllApiArg>({
    statusId: RequestStatusEnum["Опубликовано"],
    limit,
    page,
  });

  // Request to api
  const { data, isLoading, refetch } = useRequestsControllerGetAllQuery(filters, {
    refetchOnMountOrArgChange: true
  });

  useEffectAfterMount(() => {
    if (!location.pathname.includes("create_appeal")) {
      refetch();
    }
  }, [location.pathname]);

  // Tabs state
  const [tabIndex, setTabIndex] = React.useState(0);

  // Tabs handler
  const onHandleTabsChange = (tabIndex: number) => {
    setTabIndex(tabIndex);

    if (tabIndex === 0) {
      onHandleFilters({
        organizationHelpedId: undefined,
        statusId: RequestStatusEnum["Опубликовано"],
        limit,
        page,
      });
    } else {
      onHandleFilters({
        organizationHelpedId: user.organizationId,
        statusId: undefined,
        limit,
        page,
      });
    }
  }

  const isItemsMoreThenZero = data?.items?.length > 0;

  return (
    <>
      <div className={styles.WantHelp}>
        <BaseTabs
          handleChange={onHandleTabsChange}
          value={tabIndex}
          items={tabs}
        />
        {
          isItemsMoreThenZero
            ? tabs.map(({ id }, index) => (
              <BaseTab
                value={tabIndex}
                index={index}
                key={id}
              >
                <Preloader isLoading={isLoading}>
                  <TabContent items={data.items} />
                </Preloader>
              </BaseTab>
            ))
            : <NoEntries />
        }
        {
          isItemsMoreThenZero
            ? <BasePagination
              className={styles.WantHelp__pagination}
              onChange={(page) => onHandleFilters({ page })}
              count={data?.totalPages}
              page={filters.page}
            />
            : null
        }
      </div>
    </>
  );
};

const TabContent = ({ items = [] }: { items?: Requests[] }) => (
  <Stack rowGap="2.6rem">
    {items.map((item) => (
      <RequestPreview {...propsRequest(item)} />
    ))}
  </Stack>
);

const tabs: Array<OptionType> = [
  {
    id: 1,
    name: 'Запросы',
  },
  {
    id: 2,
    name: 'Оказанная помощь',
  }
];

export default WantHelp;

import { Navigate, Outlet, useLocation } from "react-router";
import { useSelector } from "react-redux";

import { selectUser } from "app/authSlice";
import { OrganizationTypeEnum } from "common/enum";

type PropsType = {
  allowedOrganizationsTypesIDs: Array<OrganizationTypeEnum>;
}

const RouteOrganization = ({ allowedOrganizationsTypesIDs }: PropsType) => {
  const location = useLocation();
  const user = useSelector(selectUser);

  const foundOrganizationTypeId = allowedOrganizationsTypesIDs.find(
    (organizationTypeId) => user?.organizationTypeId === organizationTypeId
  );

  if (foundOrganizationTypeId) {

    return (
      <>
        <Outlet />
      </>
    );
  } else {

    return (
      <>
        <Navigate
          to="/unauthorized"
          replace
          state={{
            path: location.pathname,
          }}
        />
      </>
    )
  }

};

export default RouteOrganization;

import { apiSlice as api } from "../apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    usersControllerGetMe: build.query<
      UsersControllerGetMeApiResponse,
      UsersControllerGetMeApiArg
    >({
      query: () => ({ url: `/api/v1/users/me` }),
    }),
    usersControllerRestorePassword: build.mutation<
      UsersControllerRestorePasswordApiResponse,
      UsersControllerRestorePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/password`,
        method: "PATCH",
        body: queryArg.restorePasswordRequestDto,
      }),
    }),
    usersControllerUpdateOne: build.mutation<
      UsersControllerUpdateOneApiResponse,
      UsersControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateOneUserRequestDto,
      }),
    }),
    usersControllerSetAvatar: build.mutation<
      UsersControllerSetAvatarApiResponse,
      UsersControllerSetAvatarApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.id}/avatar`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    usersControllerGetAvatar: build.query<
      UsersControllerGetAvatarApiResponse,
      UsersControllerGetAvatarApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/users/${queryArg.id}/avatar` }),
    }),
    usersControllerSendConfirmationCode: build.mutation<
      UsersControllerSendConfirmationCodeApiResponse,
      UsersControllerSendConfirmationCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/actions/send-confirmation-code`,
        method: "POST",
        body: queryArg.sendConfirmationCodeRequestDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UsersControllerGetMeApiResponse =
  /** status 200  */ GetMeResponseDto;
export type UsersControllerGetMeApiArg = void;
export type UsersControllerRestorePasswordApiResponse =
  /** status 200  */ RestorePasswordResponseDto;
export type UsersControllerRestorePasswordApiArg = {
  restorePasswordRequestDto: RestorePasswordRequestDto;
};
export type UsersControllerUpdateOneApiResponse =
  /** status 200  */ GetMeResponseDto;
export type UsersControllerUpdateOneApiArg = {
  id: number;
  updateOneUserRequestDto: UpdateOneUserRequestDto;
};
export type UsersControllerSetAvatarApiResponse =
  /** status 200  */ GetAvatarResponseDto;
export type UsersControllerSetAvatarApiArg = {
  id: number;
  body: {
    avatar?: Blob;
  };
};
export type UsersControllerGetAvatarApiResponse =
  /** status 200  */ GetAvatarResponseDto;
export type UsersControllerGetAvatarApiArg = {
  id: number;
};
export type UsersControllerSendConfirmationCodeApiResponse =
  /** status 200  */ SendConfirmationCodeResponseDto;
export type UsersControllerSendConfirmationCodeApiArg = {
  sendConfirmationCodeRequestDto: SendConfirmationCodeRequestDto;
};
export type OptionDto = {
  id: number;
  name: string;
};
export type GetMeResponseDto = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: OptionDto;
  multimedia: {
    id?: number;
    path?: string;
    fileName?: string;
    originalFileName?: string;
    fileSize?: number;
  };
};
export type RestorePasswordResponseDto = {
  success: boolean;
};
export type RestorePasswordRequestDto = {
  email: string;
  password: string;
  confirmationCode: string;
};
export type UpdateOneUserRequestDto = {
  firstName?: string;
  lastName?: string;
  vkLink?: string;
  okLink?: string;
  isAcceptedCashPolicy?: boolean;
  isAcceptedNoCashPolicy?: boolean;
};
export type GetAvatarResponseDto = {
  id: number;
  fileName: string;
  originalFileName: string;
  fileSize: number;
  path: string;
};
export type SendConfirmationCodeResponseDto = {
  success: boolean;
};
export type SendConfirmationCodeRequestDto = {
  email: string;
  isRestore: boolean;
};
export const {
  useUsersControllerGetMeQuery,
  useUsersControllerRestorePasswordMutation,
  useUsersControllerUpdateOneMutation,
  useUsersControllerSetAvatarMutation,
  useUsersControllerGetAvatarQuery,
  useUsersControllerSendConfirmationCodeMutation,
} = injectedRtkApi;

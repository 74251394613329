import { createTheme } from '@mui/material';

import {
  MuiButton,
  MuiCheckbox,
  MuiTextField,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTabs,
  MuiTab,
  MuiTypography,
  MuiPagination,
  MuiSelect,
} from './components';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 481,
      md: 769,
      lg: 1025,
      xl: 1441,
    },
  },
  palette: {
    primary: {
      light: '#ed5338',
      main: '#ed5338',
      dark: '#ed5338',
    },
  },
  components: {
    ...MuiButton,
    ...MuiCheckbox,
    ...MuiTextField,
    ...MuiOutlinedInput,
    ...MuiSvgIcon,
    ...MuiTabs,
    ...MuiTab,
    ...MuiTypography,
    ...MuiPagination,
    ...MuiSelect,
  },
});

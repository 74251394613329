import { ThemeOptions } from '@mui/material';

export const MuiOutlinedInput: ThemeOptions['components'] = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        font: '500 1.6rem Montserrat, sans-serif',
        lineHeight: '2.4rem',
        minWidth: '100%',
      },
      input: {
        '&::placeholder': {
          color: '#1A1A1A !important',
          opacity: 1,
        },
        padding: '1.15rem 1.6rem 1.15rem 1.6rem',
      },
      notchedOutline: {
        borderRadius: '.8rem',
        // border: '.1rem solid #B6B4BF !important',
      },
    },
  },
};

import React from 'react';

export type AuthContextType = {
  isAuthed: boolean;
  setIsAuthed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AuthContext = React.createContext<AuthContextType>(
  {} as AuthContextType,
);

import React from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router';

import {
  BaseButton,
  BaseImage,
  BaseLink,
  BaseTypography,
} from 'components/base';

import { useAvatarContext } from 'common/context';
import { useWindowSize } from 'hooks';
import styles from './OrganizationUser.module.scss';
import Preloader from 'content/Preloader/Preloader';

type PropsType = {
  isRequestLoading?: boolean;
  children: React.ReactNode;
  subtitle: string;
  city: string | undefined;
  isAccepted: boolean;
};

const OrganizationUser = ({
  isRequestLoading,
  children,
  city,
  subtitle,
  isAccepted,
}: PropsType) => {
  const { avatar } = useAvatarContext();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  return (
    <>
      <section className={styles.OrganizationUser}>
        <div className={styles.OrganizationUser__aside} hidden={width <= 767}>
          <BaseImage
            className={styles.OrganizationUser__avatar}
            src={avatar as string | undefined}
            alt="Аватар профиля"
            height="8.04"
            width="8.04"
          />
        </div>
        <div className={styles.OrganizationUser__content}>
          <div className={styles.OrganizationUser__header}>
            <BaseImage
              className={styles.OrganizationUser__avatar}
              hidden={width > 767}
              src={avatar}
              width="8.04"
              height="8.04"
              alt="Аватар профиля"
            />
            <div className={styles.OrganizationUser__info}>
              <div className={styles.OrganizationUser__info_header}>
                <BaseTypography
                  className={styles.OrganizationUser__title}
                  variant="h2"
                  type="pt"
                >
                  Профиль
                </BaseTypography>
                {isAccepted && (
                  <BaseLink
                    className={styles.OrganizationUser__requests_link}
                    variant="arrow"
                    to="actions"
                  >
                    Запросы
                    <ArrowForwardIosIcon
                      className={styles.OrganizationUser__requests_icon}
                    />
                  </BaseLink>
                )}
              </div>
              <div className={styles.OrganizationUser__address}>
                <LocationOnOutlinedIcon
                  className={styles.OrganizationUser__address_icon}
                />
                <p className={styles.OrganizationUser__address_name}>
                  {city ?? 'Город не указан'}
                </p>
              </div>
            </div>
          </div>
          <BaseTypography
            className={styles.OrganizationUser__subtitle}
            variant="h3"
            type="ps"
          >
            {subtitle}
          </BaseTypography>
          <div className={styles.OrganizationUser__data}>{children}</div>
          <div
            className={styles.OrganizationUser__buttons}
            style={isRequestLoading ? { gridTemplateColumns: '1fr' } : {}}
          >
            <Preloader isLoading={isRequestLoading}>
              <BaseButton
                className={styles.OrganizationUser__button}
                variant="main_contained_tangerine"
                type="submit"
              >
                Сохранить
              </BaseButton>
              <BaseButton
                className={styles.OrganizationUser__button}
                variant="main_outlined_tangerine"
                onClick={() => navigate('/')}
              >
                Отмена
              </BaseButton>
            </Preloader>
          </div>
        </div>
      </section>
    </>
  );
};

const MemoizedOrganizationUser = React.memo(OrganizationUser);

export default MemoizedOrganizationUser;

import { Button, ButtonProps, styled } from '@mui/material';
import { mcn } from 'common/funcs/mcn.func';

import styles from './BaseButton.module.scss';

type PropsType = {
  className?: string;
  children: React.ReactNode;
};

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.6rem',
  },
}));

const BaseButton = <C extends React.ElementType>({
  className = '',
  children,
  ...rest
}: ButtonProps<C, { component?: C }> & PropsType) => {
  return (
    <>
      <StyledButton className={mcn([styles.BaseButton, className])} {...rest}>
        {children}
      </StyledButton>
    </>
  );
};

export default BaseButton;

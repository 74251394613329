import {
  BasePage,
  BaseImage,
  BaseContent,
  BaseTypography,
} from 'components/base';

import logoKaliningrad from 'common/svg/svg-logo-kaliningrad.svg';
import logoPartner from 'common/svg/svg-logo-partner.svg';
import logoFond from 'common/svg/svg-brown-logo.svg';
import styles from './About.module.scss';

const AboutPage = () => {
  return (
    <>
      <BasePage className={styles.AboutPage}>
        <BaseContent style={{ margin: 0 }}>
          <BaseTypography
            className={styles.AboutPage__title}
            variant="h2"
            type="prt"
          >
            О проекте
          </BaseTypography>
          <div className={styles.AboutPage__brands}>
            <BaseImage
              className={styles.AboutPage__logo_my_buisnes}
              height="107"
              width="304"
              src={logoFond}
              alt="Логотип 'Мой бизнес'"
            />
            <BaseImage
              className={styles.AboutPage__logo_partner}
              height="107"
              width="107"
              src={logoPartner}
              alt="Логотип 'РЦ СО НКО'"
            />
            <div className={styles.AboutPage__logo_kaliningrad}>
              <BaseImage
                className={styles.AboutPage__logo_kaliningrad_image}
                height="107"
                width="107"
                src={logoKaliningrad}
                alt="Логотип 'Муниципального развития Калининграда'"
              />
              <p className={styles.AboutPage__logo_kaliningrad_text}>
                Министерство по муниципальному развитию и внутренней политике
                Калининградской области
              </p>
            </div>
            <p className={styles.AboutPage__hash_tag}>#МОЙБИЗНЕСПОМОГАЕТ</p>
          </div>
          <div className={styles.AboutPage__content}>
            <p className={styles.AboutPage__text}>
              Организаторами акции #МойбизнесПомогает выступают Министерство
              экономического развития Российской Федерации, центры «Мой бизнес»
              и некоммерческий сектор. Впервые инициатива была запущена летом
              2021 года и приурочена ко Дню защиты детей. По всей стране в
              региональных центрах устанавливали пункты для сбора вещей и
              подарков, а также проходили волонтёрские акции. В первой акции
              #МойбизнесПомогает приняло участия более 665 субъектов МСП от
              Москвы до Чукотского автономного округа. Вместе предприниматели,
              самозанятые и НКО помогли 1400 детям.
            </p>
            <p className={styles.AboutPage__text}>
              Региональный центр «Мой Бизнес» участвовал в сборе донорской
              крови, проводил «открытые уроки» для школьников и поздравлял
              жителей старшего возраста с Новым годом. Калининградская область
              решила пойти дальше, соединив акцию #МойбизнесПомогает с
              принципами прозрачной благотворительности, продвигаемыми
              Ассоциацией «Все вместе». Совместно с Министерством по
              муниципальному развитию и внутренней политике, Ресурсным центром
              СО НКО создан маркетплейс для некоммерческих организаций и бизнеса
              региона.
            </p>
            <p className={styles.AboutPage__text}>
              На маркетплейсе социально ориентированные НКО могут:
              <br />
              – запрашивать материальную, организационную, иную нефинансовую
              помощь для проектов, мероприятий, уставной деятельности;
              <br />
              – рассказывать о реализуемых инициативах;
              <br />– получать полезную информацию о преференциях, прозрачной
              благотворительности, новых возможностях для третьего сектора.
            </p>
            <p className={styles.AboutPage__text}>
              Индивидуальные предприниматели и бизнес могут:
              <br />
              – поддерживать запросы, оказывая помощь важным для региона и людей
              инициативам и СО НКО;
              <br />
              – реализовывать программы КСО;
              <br />
              – рассказывать об оказанной поддержке и формировать социально
              ответственный имидж;
              <br />– получать полезную информацию о налоговых вычетах на
              благотворительность для бизнеса и других возможностях. При этом на
              сайт попадут только проверенные и надежные организации. В
              маркетплейсе предусмотрен Личный кабинет, в котором вы сможете
              увидеть поданные и отработанные запросы.
            </p>
          </div>
        </BaseContent>
      </BasePage>
    </>
  );
};

export default AboutPage;

import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import { BaseButton, BaseModal } from "components/base";
import React from "react";

import { useDebounceEffect } from "./useDebounceEffect";
import { centerAspectCrop } from "./centerAspectCrop";
import { canvasPreview } from "./canvasPreview";
import { imgPreview } from "./imgPreview";
import { useToggle, useWindowSize } from "hooks";
import styles from "./Cropper.module.scss";
import "react-image-crop/src/ReactCrop.scss";

type PropsType = {
  file: File;
  onChange: (croppedImage: File) => void;
  onCancel: () => void;
};

const Cropper = ({ file, onChange, onCancel }: PropsType) => {
  // Options
  const [aspect] = React.useState<number | undefined>(2.25 / 1);
  const [rotate] = React.useState(0);
  const [scale] = React.useState(1);

  // Uploaded file
  const [uploadedFile, setUploadedFile] = React.useState(file);

  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();
  const [imgBlob, setImgBlob] = React.useState();
  const [imgSrc, setImgSrc] = React.useState("");
  const [crop, setCrop] = React.useState<Crop>();

  // Refs
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);

  // Set uploaded file to state
  React.useEffect(() => {
    if (file) {
      onSelectFile(file);
      setUploadedFile(file);
    }
  }, [file]);

  const { isActive, onToggle } = useToggle();

  function onSelectFile(file: File) {
    // Makes crop preview update between images.
    setCrop(undefined);

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result?.toString() || ""),
    );
    reader.readAsDataURL(file);

    onToggle();
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onSaveHandler(imgBlob: Blob | undefined, uploadedFile: File) {
    if (imgBlob && uploadedFile) {
      onChange(new File([imgBlob], uploadedFile.name));
      onToggle();
    }
  }

  function onCanclerHandler() {
    onCancel();
    onToggle();
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }

      // Get blob from preview
      const { blob } = (await imgPreview(
        imgRef.current as any,
        completedCrop as any,
        scale,
        rotate,
      )) as any;

      // Set blob to state
      setImgBlob(blob);
    },
    100,
    [completedCrop, scale, rotate],
  );
  return (
    <BaseModal
      classNameBody={styles.CropModalBody}
      title="Редактировать фото"
      isPage={false}
      isActive={isActive}
      onToggle={onCanclerHandler}
    >
      <div className={styles.CropModal}>
        <div className={styles.CropModal__header}>
          <h3 className={styles.CropModal__subtitle}>
            Настройте размер фотографии
          </h3>
        </div>
        <div className={styles.CropModal__content}>
          {!!imgSrc && (
            <ReactCrop
              className={styles.CropModal__image_wrapper}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              crop={crop}
            >
              <img
                className={styles.CropModal__image}
                onLoad={onImageLoad}
                ref={imgRef}
                src={imgSrc}
                alt="Обрезать фото"
              />
            </ReactCrop>
          )}
        </div>
        <div className={styles.CropModal__controls}>
          <BaseButton
            className={styles.CropModal__control}
            fullWidth={true}
            onClick={() => onSaveHandler(imgBlob, uploadedFile)}
            variant="main_contained_tangerine"
          >
            Сохранить
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};

const MemoizedCropper = React.memo(Cropper);

export default MemoizedCropper;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import { BaseContainer, BaseImage, BaseLink } from 'components/base';
import { HeaderNoAuthed } from './HeaderNoAuthed/HeaderNoAuthed';
import { HeaderAuthed } from './HeaderAuthed/HeaderAuthed';
import { MobileMenu } from 'content';

import { useAuth, useEffectAfterMount, useToggle } from 'hooks';
import { mainLinks } from 'common/links';
import { mcn } from 'common/funcs/mcn.func';
import logoFond from 'common/svg/svg-brown-logo.svg';
import styles from './Header.module.scss';

const Header = () => {
  const { isActive, onToggle } = useToggle();
  const { isAuthed } = useAuth();

  React.useEffect(() => {
    const root = document.querySelector('#root') as HTMLElement;

    if (isActive && root) {
      document.body.style.overflowY = 'hidden';
      // document.body.style.position = 'fixed';
      root.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
      // document.body.style.position = 'unset';
      root.style.overflowY = 'unset';
    }

    return () => {
      document.body.style.overflowY = 'unset';
      // document.body.style.position = 'unset';
      root.style.overflowY = 'unset';
    };
  }, [isActive]);

  const location = useLocation();

  useEffectAfterMount(() => {
    if (isActive) {
      onToggle();
    }
  }, [location.pathname]);

  return (
    <>
      <header className={mcn([styles.Header, isActive ? styles.Header_mobile : ""])}>
        <BaseContainer className={mcn([styles.Header__inner, isActive ? styles.Header__inner_mobile : ""])}>
          <div className={mcn([styles.Header__logo_wrapper, isActive ? styles.Header__logo_wrapper_mobile : ""])}>
            <Link to="/">
              <BaseImage
                className={styles.Header__logo}
                width="167.7"
                height="56"
                alt="Логотип"
                src={logoFond}
              />
            </Link>
            <div className={styles.Header__burger} onClick={onToggle}>
              {isActive ? <CloseIcon /> : <MenuIcon />}
            </div>
          </div>
          <ul className={styles.Header__navigation}>
            {mainLinks.map(({ to, text }, key) => (
              <li className={styles.Header__item} key={key}>
                <BaseLink
                  className={styles.Header__link}
                  variant="header"
                  to={to}
                >
                  {text}
                </BaseLink>
              </li>
            ))}
          </ul>
          <div className={styles.Header__account}>
            {isAuthed ? <HeaderAuthed /> : <HeaderNoAuthed />}
          </div>
          <MobileMenu
            isActive={isActive}
            onToggle={onToggle}
          />
        </BaseContainer>
      </header>
    </>
  );
};

export default Header;

import { Link } from "react-router-dom";

import { BaseButton, BaseLink, BasePageForm } from "components/base";
import { FormCard } from "content";

import { useWindowSize } from "hooks";
import styles from "./Signup.module.scss";

const SignupPage = () => {
  const { isMobile } = useWindowSize();
  return (
    <>
      <BasePageForm className={styles.SignupPage}>
        <FormCard>
          <FormCard.Row>
            <FormCard.Title>Регистрация</FormCard.Title>
          </FormCard.Row>
          <FormCard.Row
            classsName={styles.SignupPage__row}
            marginTop={isMobile ? "3rem" : "5rem"}
          >
            <Link to="want-help">
              <BaseButton variant="main_contained_tangerine" fullWidth={true}>
                Хочу помочь
              </BaseButton>
            </Link>
          </FormCard.Row>
          <FormCard.Row marginTop="2rem">
            <Link to="need-help">
              <BaseButton
                variant="main_contained_tangerine"
                fullWidth={true}
                type="submit"
              >
                Нужна помощь
              </BaseButton>
            </Link>
          </FormCard.Row>
          <FormCard.Row marginTop="3rem">
            <BaseLink variant="form" to="/signon">
              Войти
            </BaseLink>
          </FormCard.Row>
        </FormCard>
      </BasePageForm>
    </>
  );
};

export default SignupPage;

import React from 'react';

import { BaseImage } from 'components/base';

import { mcn } from 'common/funcs/mcn.func';
import svgUploadIcon from 'common/svg/svg-upload-icon.svg';
import styles from './ImageUploader.module.scss';
import toast from 'react-hot-toast/headless';

type PropsType = {
  onChange: (file: File) => void;
  value: string | File;
  disabled?: boolean;
};

const ImageUploader = ({ onChange, value, ...rest }: PropsType) => {
  return (
    <>
      <ul className={styles.ImageUploader}>
        <li className={styles.ImageUploader__item}>
          <UploadImageWrapper
            onChange={(data) => onChange(data)}
            variant={value ? 'uploaded' : 'preview'}
            value={value}
            {...rest}
          />
        </li>
        <li className={styles.ImageUploader__item}>
          <p className={styles.ImageUploader__text}>
            Загрузите логотип или фото. Минимальный размер: 180x180 px.
          </p>
        </li>
      </ul>
    </>
  );
};

const MemoizedImageUploader = React.memo(ImageUploader)

export default MemoizedImageUploader;

const MAX_FILE_SIZE = 10;

const UploadImageWrapper = React.memo(({
  onChange,
  variant,
  value,
  disabled,
}: {
  onChange: (data: File) => void;
  variant: 'preview' | 'uploaded';
  value: string | File;
  disabled?: boolean;
}) => {

  const onUploadHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0].size > MAX_FILE_SIZE * 1024 * 1024) {
      toast.error(`Файл не должен превышать ${MAX_FILE_SIZE}mb`);
      return;
    }

    if (e.target.files && e.target.files[0]) {
      onChange(e.target.files[0]);
    }
  }

  return (
    <>
      <div
        className={mcn([
          styles.UploadImageWrapper,
          styles['UploadImageWrapper_' + variant],
        ])}
      >
        <div
          className={mcn([
            styles.UploadImageWrapper__inner,
            styles['UploadImageWrapper__inner_' + variant],
          ])}
        >
          <BaseImage
            className={mcn([
              styles.UploadImageWrapper__image,
              styles['UploadImageWrapper__image_' + variant],
            ])}
            height={112}
            width={112}
            src={value ? (value as any) : svgUploadIcon}
            alt="Загруженное фото"
          />
          <input
            className={styles.UploadImageWrapper__input}
            onChange={onUploadHandler}
            disabled={disabled}
            accept=".png,.jpg,.jpeg"
            type="file"
          />
        </div>
      </div>
    </>
  );
});

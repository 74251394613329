import React from 'react';
import { useSelector } from 'react-redux';

import { selectAccessToken, selectUser } from 'app/authSlice';
import { AuthContext } from 'common/context';

type PropsType = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: PropsType) => {
  const accessToken = useSelector(selectAccessToken);
  const user = useSelector(selectUser);

  const [isAuthed, setIsAuthed] = React.useState<boolean>(
    () => !!accessToken && !!user,
  );

  return (
    <AuthContext.Provider value={{ isAuthed, setIsAuthed }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

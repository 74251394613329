import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import React from "react";
import toast from 'react-hot-toast/headless';

import { MailingForm } from "../_MailingForm";
import { BaseModal } from "components/base";
import { Preloader } from "content";

import { Mailing, UpdateOneMailingRequestDto, useMailingControllerGetOneQuery, useMailingControllerUpdateOneMutation } from "app/api/hooks/mailing";
import { MailingScheme } from "common/validation";
import { useLoading } from "hooks";
import { OptionType } from "common/types/option.type";
import { em } from "common/funcs/em";

const UpdateMailing = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isRequestLoading, onRequestLoadingHandler } = useLoading();
  const mailingId = params?.id ? +params.id : 0;

  const [updateOneMailing] = useMailingControllerUpdateOneMutation();

  // Form initialization
  const form = useForm<UpdateOneMailingRequestDto>({
    resolver: yupResolver(MailingScheme),
    defaultValues: {
      mailingWhom: undefined,
      isSendToEmail: false,
      isSendToPhone: false,
      text: "",
    },
  });

  // Form state handler function
  const onFormStateHandler = React.useCallback((key: keyof UpdateOneMailingRequestDto, value: OptionType | boolean | string) => {
    form.setValue(key, value, {
      shouldValidate: true,
    });
  }, [form]);

  // Form state submit function
  const onFormSubmitHandler = async (updateOneMailingRequestDto: UpdateOneMailingRequestDto) => {
    try {
      onRequestLoadingHandler(true);
      await updateOneMailing({
        id: mailingId,
        updateOneMailingRequestDto
      }).unwrap();
      toast.success("Рассылка успешно обновлена");
      navigate(-1);
    } catch (e) {
      toast.error(em(e));
    } finally {
      onRequestLoadingHandler(false);
    }
  }

  const { data: mailing, isLoading } = useMailingControllerGetOneQuery({ id: mailingId }, {
    refetchOnMountOrArgChange: true
  });

  React.useEffect(() => {
    if (!mailing) return;

    for (const key in state) {
      form.setValue(key as keyof UpdateOneMailingRequestDto, (mailing as Mailing)[key as keyof UpdateOneMailingRequestDto]);
    }
  }, [mailing]);

  // Form state
  const errors = form.formState.errors;
  const state = form.watch();

  return (
    <>
      <BaseModal title="Обновить рассылку">
        <Preloader isLoading={isLoading}>
          <form onSubmit={form.handleSubmit(onFormSubmitHandler)}>
            <MailingForm
              onFormStateHandler={onFormStateHandler}
              isLoading={isRequestLoading}
              errors={errors}
              state={state}
            />
          </form>
        </Preloader>
      </BaseModal>
    </>
  )
}

export default UpdateMailing;

import { mcn } from 'common/funcs/mcn.func';
import styles from './BasePageForm.module.scss';

type PropsType = {
  className?: string;
  children: React.ReactNode;
};

const BasePageForm = ({ className = "", children }: PropsType) => {
  return (
    <>
      <main className={mcn([styles.BasePageForm, className])}>
        {children}
      </main>
    </>
  );
};

export default BasePageForm;

import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useToggle } from 'hooks';
import styles from './BaseOutlinedSelect.module.scss';

type OptionType = { name: string, value: string };

type PropsType = {
  onChange: (selected: OptionType) => void;
  defaultValue: OptionType | undefined;
  options: Array<OptionType>;
};

const BaseOutlinedSelect = ({ onChange, defaultValue, options }: PropsType) => {

  const { isActive, onToggle, onClose } = useToggle();

  // Select state
  const [selected, setSelected] = React.useState<undefined | OptionType>(defaultValue);

  // Select handler
  const onHandleSelect = ({ name, value }: OptionType) => {
    setSelected({ name, value });
    onChange({ name, value });
    onToggle();
  }

  const ref = React.useRef<HTMLDivElement | null>(null);

  // Close menu by clcik outside select
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!ref?.current?.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <div className={styles.BaseOutlinedSelect} onClick={onToggle} ref={ref}>
        <div className={styles.BaseOutlinedSelect__input}>
          <div className={styles.BaseOutlinedSelect__input_value}>
            {selected?.name ?? "Не выбрано"}
          </div>
          <div className={styles.BaseOutlinedSelect__input_arrow}>
            <KeyboardArrowDownIcon />
          </div>
        </div>
        <ul className={styles.BaseOutlinedSelect__list} onClick={(e) => e.stopPropagation()} hidden={!isActive}>
          {options.map(({ name, value }, key) => (
            <li
              className={styles.BaseOutlinedSelect__item}
              onClick={() => onHandleSelect({ name, value })}
              value={value}
              key={key}
            >
              {name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const MemoizedBaseOutlinedSelect = React.memo(BaseOutlinedSelect);

export default MemoizedBaseOutlinedSelect;

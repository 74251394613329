import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import React from "react";
import toast from 'react-hot-toast/headless';

import { MailingForm } from "../_MailingForm";
import { BaseModal } from "components/base";

import { CreateOneMailingRequestDto, useMailingControllerCreateOneMutation } from "app/api/hooks/mailing";
import { MailingScheme } from "common/validation";
import { useLoading } from "hooks";
import { OptionType } from "common/types/option.type";
import { em } from "common/funcs/em";

const CreateMailing = () => {
  const mavigate = useNavigate();
  const { isRequestLoading, onRequestLoadingHandler } = useLoading();

  // Request to Api
  const [createOneMailing] = useMailingControllerCreateOneMutation();

  // Form initialization
  const form = useForm<CreateOneMailingRequestDto>({
    resolver: yupResolver(MailingScheme),
    defaultValues: {
      mailingWhom: undefined,
      isSendToEmail: false,
      isSendToPhone: false,
      text: "",
    },
  });

  // Form state handler function
  const onFormStateHandler = React.useCallback((key: keyof CreateOneMailingRequestDto, value: string | boolean | OptionType) => {
    form.setValue(key, value, {
      shouldValidate: true,
    });
  }, [form]);

  // Form state submit function
  const onFormSubmitHandler = async (createOneMailingRequestDto: CreateOneMailingRequestDto) => {
    try {
      onRequestLoadingHandler(true);
      await createOneMailing({ createOneMailingRequestDto }).unwrap();
      toast.success("Рассылка успешно создана");
      mavigate(-1);
    } catch (e) {
      toast.error(em(e));
    } finally {
      onRequestLoadingHandler(false);
    }
  }

  // Form state
  const errors = form.formState.errors;
  const state = form.watch();

  return (
    <>
      <BaseModal title="Создать рассылку">
        <form onSubmit={form.handleSubmit(onFormSubmitHandler)}>
          <MailingForm
            onFormStateHandler={onFormStateHandler}
            isLoading={isRequestLoading}
            errors={errors}
            state={state}
          />
        </form>
      </BaseModal>
    </>
  );
};

export default CreateMailing;

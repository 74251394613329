import { Requests } from "app/api/hooks/requests";

export const propsRequest = (item: Requests) => ({
  key: item.id,
  id: item.id,
  phoneNumber1: item.organization.phoneNumber1,
  email: item.user.email,
  address: item.organization.address,
  description: item.description,
  organizationName: item.organization.name,
  publishedAt: item.publishedAt,
  updatedAt: item.updatedAt,
  status: item.status,
  organizationWhoHelpTypes: item.organization.whoHelpTypes,
  requestWhoHelpTypes: item.whoHelpTypes,
  organizationHelpedId: item?.organizationHelpedId,
})

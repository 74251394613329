import { Pagination, styled } from '@mui/material';
import { mcn } from 'common/funcs/mcn.func';
import styles from './BasePagination.module.scss';

type PropsType = {
  className?: string;
  onChange: (page: number) => void;
  count: number;
  page: number;
};

const StyledPagination = styled(Pagination)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& .MuiPagination-ul li button, & .MuiPagination-ul li button svg': {
      fontSize: '1.2rem',
      maxHeight: '2.4rem',
      minHeight: '2.4rem',
      maxWidth: '2.4rem',
      minWidth: '2.4rem',
    },
    '& .MuiPagination-ul li:nth-of-type(1), & .MuiPagination-ul li:nth-last-of-type(1)':
      {
        maxHeight: '3.6rem',
        minHeight: '3.6rem',
        maxWidth: '3.6rem',
        minWidth: '3.6rem',
        display: 'flex',
        alignItems: 'center',
      },
  },
}));

const BasePagination = ({
  className = '',
  onChange,
  count = 1,
  ...rest
}: PropsType) => {
  const onChangeHandler = (event: React.ChangeEvent<unknown>, page: number) => {
    onChange(page);
  };

  return (
    <>
      <StyledPagination
        className={mcn([styles.BasePagination, className])}
        onChange={onChangeHandler}
        count={count === 0 ? 1 : count}
        {...rest}
      />
    </>
  );
};

export default BasePagination;

import React from 'react';
import { Typography } from '@mui/material';

import { csw } from 'common/funcs/csw.func';

type PropsType = {
  message: string | undefined;
};

const BaseInputError = ({ message }: PropsType) => {
  return !!message ? (
    <>
      <Typography
        marginTop=".6rem"
        fontSize={csw('1.2rem', '1.2rem', '1.2rem', '1.2rem')}
        fontFamily="Montserrat, sans-serif"
        variant="body1"
        color="#FF0000"
      >
        {message}
      </Typography>
    </>
  ) : null;
};

const MemoizedBaseInputError = React.memo(BaseInputError)

export default MemoizedBaseInputError;

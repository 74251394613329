import MuiPhoneNumber, { MuiPhoneNumberProps } from "material-ui-phone-number";

type PropsType = MuiPhoneNumberProps;

const BasePhoneInput = (props: PropsType) => {
  return (
    <>
      <MuiPhoneNumber
        countryCodeEditable={true}
        preferredCountries={["ru"]}
        disableDropdown={true}
        onlyCountries={["ru"]}
        defaultCountry={"ru"}
        variant="outlined"
        autoFormat={false}
        autoComplete="off"
        {...props}
      />
    </>
  );
};

export default BasePhoneInput;

import { Typography } from "@mui/material";

import { BaseContent, BasePage } from "components/base";

import { csw } from "common/funcs/csw.func";

const Unauthorized = () => {
  return (
    <>
      <BasePage>
        <BaseContent>
          <Typography
            textAlign={'center'}
            fontSize={csw('5rem', '5rem', '3.5rem', '2rem')}
          >
            Нет доступа
          </Typography>
        </BaseContent>
      </BasePage>
    </>
  );
};

export default Unauthorized;

import { ThemeOptions } from '@mui/material';

export const MuiSelect: ThemeOptions['components'] = {
  MuiSelect: {
    styleOverrides: {
      outlined: {
        // paddingTop: "1rem",
        // paddingBottom: "1rem",
      },
    }
  },
}

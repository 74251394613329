import { apiSlice as api } from "../apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    citiesControllerGetAll: build.query<
      CitiesControllerGetAllApiResponse,
      CitiesControllerGetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/cities`,
        params: { page: queryArg.page, limit: queryArg.limit },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type CitiesControllerGetAllApiResponse = /** status 200  */ any & {
  items?: Cities[];
  totalPages?: number;
  count?: number;
};
export type CitiesControllerGetAllApiArg = {
  page: number;
  limit: number;
};
export type OrganizationStatuses = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type OrganizationTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type WhatHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type UserSessions = {
  id: number;
  refreshToken?: string;
  fingerPrint?: string;
  userId: number;
  user: Users;
};
export type RequestStatuses = {
  id: number;
  name: string;
  requests: Requests[];
};
export type Requests = {
  id: number;
  name: string;
  description: string;
  address: string;
  organizationHelpedId?: number;
  publishedAt?: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  statusId: number;
  status: RequestStatuses;
  organizationId: number;
  organization: Organizations;
  userId: number;
  user: Users;
};
export type UserRoles = {
  id: number;
  name: string;
  users: Users[];
};
export type Users = {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  vkLink?: string;
  okLink?: string;
  isAcceptedCashPolicy: boolean;
  isAcceptedNoCashPolicy: boolean;
  createdAt: string;
  updatedAt: string;
  sessions?: UserSessions;
  origanization?: Organizations;
  requests: Requests[];
  avatar?: Multimedia;
  roleId: number;
  role: UserRoles;
};
export type Multimedia = {
  id: number;
  fileSize: number;
  fileName: string;
  originalFileName: string;
  mimeType: string;
  path: string;
  device?: string;
  createdAt: string;
  updatedAt: string;
  userId?: number;
  user?: Users;
  organizationId?: number;
  organization?: Organizations;
  materialsId?: number;
  materials?: Materials;
};
export type MaterialAuthors = {
  id: number;
  name: string;
  Materials: Materials[];
};
export type Materials = {
  id: number;
  title: string;
  sign: string;
  text: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  multimedia: Multimedia[];
  authorId: number;
  author: MaterialAuthors;
};
export type WhoHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
  materials: Materials[];
  requests: Requests[];
};
export type Organizations = {
  id: number;
  name?: string;
  siteLink?: string;
  INN?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  address?: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  organizationStatusId: number;
  organizationStatus: OrganizationStatuses;
  organizationTypeId: number;
  organizationType: OrganizationTypes;
  whatHelpTypes: WhatHelpTypes[];
  whoHelpTypes: WhoHelpTypes[];
  userId: number;
  user: Users;
  documents: Multimedia[];
  requests: Requests[];
  cityId?: number;
  city?: Cities;
};
export type Cities = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export const { useCitiesControllerGetAllQuery } = injectedRtkApi;

import { BaseLoader } from "components/base"

type PropsType = {
  isLoading?: boolean;
  children: React.ReactNode;
}

const Preloader = ({ isLoading, children }: PropsType) => {
  return isLoading ? <BaseLoader /> : <>{children}</>
}

export default Preloader;

import React from "react";

import { useSelectContext } from "../_context";
import styles from "./CheckboxList.module.scss";

const CheckboxList = ({ children }: { children: React.ReactNode }) => {

  const { isActive } = useSelectContext();

  return (
    <>
      <div className={styles.CheckboxList} hidden={!isActive}>
        <ul className={styles.CheckboxList__list}>
          {children}
        </ul>
      </div>
    </>
  )
}

export default CheckboxList;

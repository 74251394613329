import React from "react";
import { useNavigate } from "react-router";

import { BaseButton, BaseCheckbox, BaseInputError, BaseLabel, BaseTextarea } from "components/base";
import { Preloader, Select } from "content";

import { useMailingWhomControllerGetAllQuery } from "app/api/hooks/mailing";
import { MailingPropsType } from ".";
import { OptionType } from "common/types/option.type";
import styles from "./MailingForm.module.scss";

const MailingForm = ({ onFormStateHandler, errors, state, isLoading }: MailingPropsType) => {
  const navigate = useNavigate();
  const { data: mailingWhom } = useMailingWhomControllerGetAllQuery();
  return (
    <>
      <ul className={styles.MailingForm}>
        <li className={styles.MailingForm__item}>
          <BaseTextarea
            className={styles.MailingForm__textarea}
            onChange={(e) => onFormStateHandler("text", e.target.value)}
            value={state["text"]}
            label="Текст"
          />
          <BaseInputError message={errors["text"]?.message} />
        </li>
        <li className={styles.MailingForm__item}>
          <Select
            onChange={(whom) => {
              if (!Array.isArray(whom)) {
                onFormStateHandler('mailingWhom', whom)
              }
            }}
            value={state.mailingWhom}
            label="Кому"
            multiple={false}
            variant="select"
          >
            <Select.OptionList>
              {mailingWhom?.map((item) => (
                <Select.OptionItem
                  selected={state?.mailingWhom?.id === item.id}
                  key={item.id}
                  {...item}
                />
              ))}
            </Select.OptionList>
          </Select>
          <BaseInputError message={errors["mailingWhom"]?.message} />
        </li>
        <li className={styles.MailingForm__item}>
          <div>
            <BaseLabel label="На почту">
              <BaseCheckbox
                onChange={(e) => onFormStateHandler("isSendToEmail", e.target.checked)}
                checked={state.isSendToEmail}
                value="isSendToEmail"
              />
            </BaseLabel>
            <BaseInputError message={errors["isSendToEmail"]?.message} />
          </div>
          <div>
            <BaseLabel label="СМС">
              <BaseCheckbox
                onChange={(e) => onFormStateHandler("isSendToPhone", e.target.checked)}
                checked={state.isSendToPhone}
                value="isSendToPhone"
              />
            </BaseLabel>
            <BaseInputError message={errors["isSendToPhone"]?.message} />
          </div>
        </li>
        <li className={styles.MailingForm__item}>
          <Preloader isLoading={isLoading}>
            <BaseButton
              fullWidth={true}
              variant="main_contained_tangerine"
              type="submit"
            >
              Отправить
            </BaseButton>
            <BaseButton
              fullWidth={true}
              variant="main_outlined_tangerine"
              onClick={() => navigate(-1)}
            >
              Отмена
            </BaseButton>
          </Preloader>
        </li>
      </ul>
    </>
  )
}

const MemoizedMailingForm = React.memo(MailingForm) as typeof MailingForm;

export const mailingWhom: Array<OptionType> = [
  {
    id: 1,
    name: "Всем"
  },
  {
    id: 2,
    name: "Фондам",
  },
  {
    id: 3,
    name: "Бизнесу",
  },
  {
    id: 4,
    name: "Гостям",
  },
]


export default MemoizedMailingForm;

import React from "react";

import { OptionType } from "common/types/option.type";

export type Select = {
  onChangeHandler: (item: OptionType) => void;
  isScrollable?: boolean;
  isActive?: boolean;
  multiple: boolean;
  value?: OptionType | Array<OptionType> | null | undefined
}

export const SelectContext = React.createContext<Select>({} as Select);

export const useSelectContext = () => {
  return React.useContext(SelectContext);
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';

import { BaseLoader, BasePage } from 'components/base';
import { NeedHelp } from './NeedHelp';
import { WantHelp } from './WantHelp';

import { OrganizationStatusEnum, OrganizationTypeEnum } from 'common/enum';
import { useOrganizationsControllerGetMyQuery } from 'app/api/hooks/organizations';
import { selectUser } from 'app/authSlice';
import styles from './ProfileActions.module.scss';

const ProfileActionsPage = () => {
  const user = useSelector(selectUser);

  // Request to organization API
  const { data, isLoading } = useOrganizationsControllerGetMyQuery();

  if (isLoading) {
    return (
      <>
        <BasePage className={styles.ProfileActions}>
          <BaseLoader />
        </BasePage>
      </>
    );
  }

  return (
    <>
      <BasePage className={styles.ProfileActions}>
        <ProfileRoute organizationStatusId={data?.organizationStatus.id}>
          <>
            <Outlet />
            <ProfileActionsSwitch organizationTypeId={user.organizationTypeId} />
          </>
        </ProfileRoute>
      </BasePage>
    </>
  );
};

const ProfileRoute = React.memo((props: { children: JSX.Element, organizationStatusId?: number }) => {
  switch (props.organizationStatusId) {
    case OrganizationStatusEnum["Новый пользователь"]:
      return <Navigate to="/account" />
    case OrganizationStatusEnum["Отказ"]:
      return <Navigate to="/" />
    case OrganizationStatusEnum["Кандидат"]:
      return <Navigate to="/account/waiting" />
    default:
      return props.children;
  }
});

type ProfileActionsSwitchType = {
  organizationTypeId: number | undefined | null;
}

const ProfileActionsSwitch = React.memo(
  ({ organizationTypeId }: ProfileActionsSwitchType) => {
    switch (organizationTypeId) {
      case OrganizationTypeEnum['ЮРЛИЦО']:
      case OrganizationTypeEnum['ФИЗЛИЦО']:
      case OrganizationTypeEnum['ИП']:
        return <WantHelp />;
      case OrganizationTypeEnum['НКО']:
        return <NeedHelp />;
      default:
        return <>Что-то пошло не такю...</>;
    }
  },
  (prevProps, nextProps) => {

    if (prevProps.organizationTypeId !== nextProps.organizationTypeId) {
      return true;
    }

    return false;
  }
);

export default ProfileActionsPage;

import React from 'react';

import { mcn } from 'common/funcs/mcn.func';
import styles from './BaseTag.module.scss';

type PropsType = {
  className?: string;
  variant: 'common' | 'preview';
  name: string;
  id: string | number;
};

const BaseTag = React.memo(
  ({
    className = '',
    variant,
    name,
    id,
    ...rest
  }: PropsType) => {
    const classNameVariant = styles['BaseTag_' + variant];
    return (
      <>
        <span
          className={mcn([
            styles.BaseTag,
            classNameVariant,
            className,
          ])}
          data-value={id}
          {...rest}
        >
          {name}
        </span>
      </>
    );
  },
);

export default BaseTag;

import React from "react";

import {
  BaseInputError,
  BasePhoneInput,
  BaseTextarea,
  BaseTextField,
} from "components/base";
import { ImageUploader, Select } from "content";

import { UpdateOneOrganizationRequestDto } from "app/api/hooks/organizations";
import { ProfileNestedPropsType } from "../Profile.types";
import { isSelectedOption } from "common/funcs/is-selected-option.func";
import { OptionType } from "common/types/option.type";
import styles from "./ProfilePerson.module.scss";

const ProfilePerson = (
  props: ProfileNestedPropsType<UpdateOneOrganizationRequestDto>,
) => {
  return (
    <>
      <ul className={styles.ProfilePerson}>
        <li className={styles.ProfilePerson__item}>
          <BaseTextField
            value={props.data.lastName}
            label="Фамилия"
            name="lastName"
            onChange={(e) =>
              props.onFormStateHandler("lastName", e.target.value)
            }
          />
          <BaseInputError message={props.errors["lastName"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <BaseTextField
            value={props.data.firstName}
            label="Имя"
            name="firstName"
            onChange={(e) =>
              props.onFormStateHandler("firstName", e.target.value)
            }
          />
          <BaseInputError message={props.errors["firstName"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <BaseTextField
            value={props.data.INN}
            label="ИНН"
            name="INN"
            onChange={(e) => props.onFormStateHandler("INN", e.target.value)}
          />
          <BaseInputError message={props.errors["INN"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <BaseTextField
            value={props.data.email}
            label="Эл. почта"
            disabled={true}
            name="email"
            onChange={(e) => props.onFormStateHandler("email", e.target.value)}
          />
          <BaseInputError message={props.errors["email"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <BaseTextField
            value={props.data.organizationType?.name}
            label="Тип организации"
            name="organizationType"
            disabled={true}
          />
          <BaseInputError message={props.errors["organizationType"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <BasePhoneInput
            value={props.data.phoneNumber1}
            label="Телефон 1"
            name="phoneNumber1"
            onChange={(value) =>
              typeof value === "string" &&
              props.onFormStateHandler("phoneNumber1", value)
            }
            type="tel"
          />
          <BaseInputError message={props.errors["phoneNumber1"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <Select
            onChange={(selected) => {
              if (Array.isArray(selected)) return;

              props.onFormStateHandler("city", selected);
            }}
            multiple={false}
            value={props.data.city}
            variant="select"
            label="Город"
          >
            <Select.OptionList>
              {props.citiesList.map(
                (item: OptionType) =>
                  item.id !== 0 && (
                    <Select.OptionItem
                      selected={props.data?.city?.id === item.id}
                      key={item.id}
                      {...item}
                    />
                  ),
              )}
            </Select.OptionList>
          </Select>
          <BaseInputError message={props.errors["city"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <BaseTextField
            value={props.data.address}
            label="Адрес"
            name="address"
            onChange={(e) =>
              props.onFormStateHandler("address", e.target.value)
            }
          />
          <BaseInputError message={props.errors["address"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <ImageUploader
            onChange={(file) => props.onUploadAvatar(file)}
            value={props.avatar as File | string}
          />
        </li>
        <li className={styles.ProfilePerson__item}>
          <Select
            onChange={(selected) => {
              props.onFormStateHandler("whatHelpTypes", selected);
            }}
            multiple={true}
            value={props.data.whatHelpTypes}
            label="Чем занимается"
            variant="checkbox"
            noClose={true}
          >
            <Select.CheckboxList>
              {props.whatHelpTypesList.map(
                (item) =>
                  item.id !== 0 && (
                    <Select.CheckboxItem
                      selected={isSelectedOption(
                        props.data.whatHelpTypes,
                        item.id,
                      )}
                      key={item.id}
                      {...item}
                    />
                  ),
              )}
            </Select.CheckboxList>
          </Select>
          <BaseInputError message={props.errors["whatHelpTypes"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <Select
            onChange={(selected) => {
              props.onFormStateHandler("whoHelpTypes", selected);
            }}
            value={props.data.whoHelpTypes}
            label="Сфера деятельности"
            multiple={true}
            variant="select"
          >
            <Select.OptionList>
              {props.whoHelpTypesList?.map(
                (item) =>
                  item.id !== 0 && (
                    <Select.OptionItem
                      selected={isSelectedOption(
                        props.data.whoHelpTypes,
                        item.id,
                      )}
                      key={item.id}
                      {...item}
                    />
                  ),
              )}
            </Select.OptionList>
          </Select>
          <BaseInputError message={props.errors["whoHelpTypes"]?.message} />
        </li>
        <li className={styles.ProfilePerson__item}>
          <BaseTextarea
            value={props.data.description}
            onChange={(e) =>
              props.onFormStateHandler("description", e.target.value)
            }
            label="О себе"
          />
          <BaseInputError message={props.errors["description"]?.message} />
        </li>
      </ul>
    </>
  );
};

const MemoizedProfilePerson = React.memo(ProfilePerson);

export default MemoizedProfilePerson;

import React from "react";

import { useOrganizationsWhatHelpControllerGetAllQuery } from "app/api/hooks/organizations";
import { OptionType } from "common/types/option.type";

export function useWhatHelpTypes() {
  // What help types catergories
  const { data: whatHelpTypes } =
    useOrganizationsWhatHelpControllerGetAllQuery();

  const [whatHelpTypesList, setWhatHelpTypesList] = React.useState<
    OptionType[]
  >([]);

  React.useEffect(() => {
    if (!whatHelpTypes) return;

    setWhatHelpTypesList(whatHelpTypes);
  }, [whatHelpTypes]);

  return {
    whatHelpTypesList,
  };
}

import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

import { mcn } from 'common/funcs/mcn.func';
import styles from './BaseCheckbox.module.scss';

type PropsType = {
  className?: string;
};

const BaseCheckbox = ({
  className = '',
  ...rest
}: CheckboxProps & PropsType) => {
  return (
    <>
      <Checkbox className={mcn([styles.BaseCheckbox, className])} {...rest} />
    </>
  );
};

const MemoizedBaseCheckbox = React.memo(BaseCheckbox);

export default MemoizedBaseCheckbox;

import React from "react";

import { useCitiesControllerGetAllQuery } from "app/api/hooks/cities";
import { OptionType } from "common/types/option.type";

export function useCities() {
  // Cities list
  const { data: cities } = useCitiesControllerGetAllQuery({
    limit: 322,
    page: 1,
  });

  const [citiesList, setCitiesList] = React.useState<OptionType[]>([]);

  React.useEffect(() => {
    if (!cities) return;

    setCitiesList(cities.items);
  }, [cities]);

  return {
    citiesList,
  };
}

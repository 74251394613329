import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast/headless';

import { BaseButton, BaseModal, BaseTypography } from 'components/base';
import { Preloader } from 'content';

import { useRequestsControllerSendHelpByRequestMutation } from 'app/api/hooks/requests';
import { useLoading } from 'hooks';
import { em } from "common/funcs/em";
import styles from './CreateAppeal.module.scss';

const CreateAppeal = () => {
  const { isRequestLoading, onRequestLoadingHandler } = useLoading();
  const navigation = useNavigate();
  const params = useParams();
  const id = params?.id ? +params.id : null;

  // Form initialization
  const form = useForm();

  // Send help
  const [sendHelpByRequestId] = useRequestsControllerSendHelpByRequestMutation();

  // Form submit handler
  const onSubmitHandler = async () => {
    try {

      if (!id) return;

      onRequestLoadingHandler(true);
      await sendHelpByRequestId({ id });
      toast.success("Обращение успешно отправлено");
      navigation(-1);
    } catch (e) {
      toast.error(em(e));
    } finally {
      onRequestLoadingHandler(false);
    }
  }

  return (
    <>
      <BaseModal title="Обращение">
        <form className={styles.CreateAppeal} onSubmit={form.handleSubmit(onSubmitHandler)}>
          <ul className={styles.CreateAppeal__list}>
            <li className={styles.CreateAppeal__item}>
              <BaseTypography
                className={styles.CreateAppeal__subtitle}
                variant="p"
                type="ms"
              >
                Спасибо, что решили помочь. С вами свяжется наш сотрудник, который поможет оформить документы и передать вашу помощь по закону и правильно.

              </BaseTypography>
            </li>
            <li className={styles.CreateAppeal__item}>
              <Preloader isLoading={isRequestLoading}>
                <BaseButton
                  fullWidth={true}
                  variant="main_contained_tangerine"
                  type="submit"
                >
                  Хорошо
                </BaseButton>
                <BaseButton
                  fullWidth={true}
                  variant="main_outlined_tangerine"
                  onClick={() => navigation(-1)}
                >
                  Отмена
                </BaseButton>
              </Preloader>
            </li>
          </ul>
        </form>
      </BaseModal>
    </>
  );
};

export default CreateAppeal;

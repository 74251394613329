import React from 'react';

type PropsType = {
  className?: string;
  children?: React.ReactNode;
  index: number;
  value: number;
};

const BaseTab =
  ({ children, value, index, ...other }: PropsType) => {
    return (
      <div
        aria-labelledby={`simple-tab-${index}`}
        hidden={value !== index}
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

const MemoizedBaseTab = React.memo(BaseTab)

export default MemoizedBaseTab;

import { mcn } from "common/funcs/mcn.func";
import styles from "./AdminFooter.module.scss";

type PropsType = {
  children: React.ReactNode;
  className?: string;
}

const AdminFooter = ({ children, className = "" }: PropsType) => {
  return (
    <>
      <div className={mcn([styles.AdminFooter, className])}>
        {children}
      </div>
    </>
  );
};

export default AdminFooter;

import { BaseImage } from "components/base";
import React from "react";

import styles from "./ImagePreview.module.scss";

type PropsType = {
  onUpload: (file: File) => void;
  onDelete: () => void;
  value: File;
  disabled?: boolean;
}

const ImagePreview = ({ value, onUpload, onDelete, ...rest }: PropsType) => {
  return (
    <>
      <div className={styles.ImagePreview}>
        <div className={styles.ImagePreview__inner}>
          <BaseImage
            className={styles.ImagePreview__image}
            src={value}
            height="130"
            width="219"
          />
          <input
            className={styles.ImagePreview__input}
            onChange={(e) => {
              if (e.target.files) {
                onUpload(e.target.files[0]);
              }
            }}
            accept=".png,.jpg,.jpeg"
            type="file"
            {...rest}
          />
        </div>
        <div className={styles.ImagePreview_button_delete}>
          <p onClick={onDelete}>
            Удалить фото
          </p>
        </div>
      </div>
    </>
  )
};

const MemoizedImagePreview = React.memo(ImagePreview);

export default MemoizedImagePreview;

import svgClipAndPencel from "common/svg/svg-clip-and-pencel-icon.svg";
import svgOpenMailIcon from "common/svg/svg-open-mail-icon.svg";
import svgUserAddIcon from "common/svg/svg-user-add-icon.svg";
import svgSpeakStufIcon from "common/svg/svg-speak-stuf-icon.svg";

import { BaseImage } from "components/base";

export const mainLinks = [
  {
    to: "/requests",
    text: "Запросы",
  },
  {
    to: "/",
    text: "О проекте",
  },
  {
    to: "/materials",
    text: "Полезные материалы",
  },
];

export const adminLinks = [
  {
    to: "/admin/material",
    text: "Статьи",
    icon: (
      <BaseImage src={svgClipAndPencel} alt="Статьи" height="20" width="20" />
    ),
  },
  {
    to: "/admin/requests",
    text: "Организации",
    icon: (
      <BaseImage
        src={svgUserAddIcon}
        alt="Организации"
        height="20"
        width="20"
      />
    ),
  },
  {
    to: "/admin/appeals",
    text: "Обращения",
    icon: <BaseImage src={svgSpeakStufIcon} alt="НКО" height="20" width="20" />,
  },
  {
    to: "/admin/mailing",
    text: "Рассылка",
    icon: (
      <BaseImage src={svgOpenMailIcon} alt="Рассылка" height="20" width="20" />
    ),
  },
];

export const accountLinks = [
  {
    to: "/account",
    text: "Статьи",
    icon: (
      <BaseImage src={svgClipAndPencel} alt="Статьи" height="20" width="20" />
    ),
  },
  {
    to: "/account/actions",
    text: "Статьи",
    icon: (
      <BaseImage src={svgOpenMailIcon} alt="Статьи" height="20" width="20" />
    ),
  },
];

export const headerAuthedLinks = (isAdmin: boolean) => [
  {
    to: isAdmin ? "/admin/requests" : "/account",
    text: "Профиль",
  },
  {
    to: "/actions/signout",
    text: "Выход",
  },
];

export const headerNoAuthedLinks = [
  {
    to: "/signon",
    text: "Вход",
  },
  {
    to: "/signup",
    text: "Регистрация",
  },
];

import React from "react";
import { useSelector } from "react-redux";

import { BaseLink } from "components/base";

import { mainLinks, accountLinks, adminLinks, headerAuthedLinks, headerNoAuthedLinks } from "common/links";
import { selectUser } from "app/authSlice";
import { RoleEnum } from "common/enum";
import { useAuth } from "hooks";
import styles from "./MobileMenu.module.scss";

type PropsType = {
  isActive: boolean;
  onToggle: () => void;
}

const MobileMenu = ({ isActive }: PropsType) => {
  const { isAuthed } = useAuth();
  const user = useSelector(selectUser);

  const isAdmin = user?.role === RoleEnum["ADMIN"];

  const midLinks = isAdmin && isAuthed
    ? adminLinks
    : accountLinks;

  const botLinks = isAuthed
    ? headerAuthedLinks(isAdmin)
    : headerNoAuthedLinks;

  return (
    <>
      <div className={styles.MobileMenu} hidden={!isActive}>
        <ul className={styles.MobileMenu__list}>
          {mainLinks.map(({ to, text }, key) => (
            <li className={styles.MobileMenu__item} key={key}>
              <BaseLink
                className={styles.MobileMenu__link}
                variant="mobile_menu"
                to={to}
              >
                {text}
              </BaseLink>
            </li>
          ))}
        </ul>
        <ul className={styles.MobileMenu__list} hidden={!isAuthed || (!isAdmin && isAuthed)}>
          {
            midLinks.map(({ to, text, icon }, key) => (
              <li className={styles.MobileMenu__item} key={key}>
                <BaseLink
                  className={styles.MobileMenu__link}
                  variant="mobile_menu"
                  to={to}
                >
                  {text}
                  {icon}
                </BaseLink>
              </li>
            ))
          }
        </ul>
        <ul className={styles.MobileMenu__list}>
          {
            botLinks.map(({ to, text }, key) => (
              <li className={styles.MobileMenu__item} key={key}>
                <BaseLink
                  className={styles.MobileMenu__link}
                  variant="mobile_menu"
                  to={to}
                >
                  {text}
                </BaseLink>
              </li>
            ))
          }
        </ul>
      </div>
    </>
  );
};

const MemoizedMobuleMenu = React.memo(MobileMenu);

export default MemoizedMobuleMenu;

import { Navigate, Outlet, useLocation } from 'react-router';

import { useAuth } from 'hooks/useAuth';

const RoutePublic = () => {
  const { isAuthed } = useAuth();
  const location = useLocation();

  if (isAuthed) {
    return (
      <>
        <Navigate
          to="/account"
          replace
          state={{
            path: location.pathname,
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <Outlet />
      </>
    );
  }
};

export default RoutePublic;

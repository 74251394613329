import React from "react";

import { Category } from "../_components";

import { useSelectContext } from "../_context";
import { OptionType } from "common/types/option.type";
import styles from "./OptionList.module.scss";

const OptionList = ({ children }: { children: React.ReactNode }) => {
  const { multiple, value, isActive, onChangeHandler } = useSelectContext();

  return React.useMemo(() => (
    <>
      <div className={styles.OptionList}>
        <div className={styles.OptionList__input}>
          {
            value
              ? Array.isArray(value) && multiple
                ? value.map(item => (
                  <Category
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      onChangeHandler(item);
                    }}
                    name={item.name}
                    key={item.id}
                  />
                ))
                : <span className={styles.OptionList__single_value}>
                  {(value as OptionType)?.name}
                </span>
              : null
          }
        </div>
        <div className={styles.OptionList__list_wrapper} hidden={!isActive} onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
          <ul className={styles.OptionList__list}>
            {children}
          </ul>
        </div>
      </div>
    </>
  ), [value, isActive])
}

const MemoizedOptionList = React.memo(OptionList);

export default MemoizedOptionList;

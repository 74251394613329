import styles from './NoEntries.module.scss';

type PropsType = {
  align?: 'start' | 'center' | 'end';
};

const NoEntries = ({ align = 'center' }: PropsType) => {
  return (
    <>
      <p className={styles.NoEntries} style={{ textAlign: align }}>
        Записей пока что нет
      </p>
    </>
  );
};

export default NoEntries;

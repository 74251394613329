import React from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { BaseImage } from 'components/base';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Multimedia } from 'app/api/hooks/materials';
import './Carousel.scss';
import '@splidejs/react-splide/css/core';

type PropsType = {
  images?: Array<Multimedia>;
  sign?: string;
};

const Carousel = ({ images = [], sign = "" }: PropsType) => {
  const isMoreThenOne = images?.length > 1;
  return (
    <div className="splide">
      <Splide
        options={{ pagination: false, drag: false, type: 'loop' }}
        hasTrack={false}
      >
        <SplideTrack>
          {images?.map(({ id, path }) => (
            <SplideSlide key={id}>
              <div className="splide__slide">
                <BaseImage
                  className="splide__image"
                  lazyLoading={true}
                  src={path}
                  height="497"
                  width="1120"
                  alt="Фото статьи"
                />
                <p className="splide__sign">
                  {sign}
                </p>
              </div>
            </SplideSlide>
          ))}
        </SplideTrack>
        <div className="splide__arrows" hidden={!isMoreThenOne}>
          <div className="splide__arrow splide__arrow--prev">
            <ArrowBackIcon />
          </div>
          <div className="splide__arrow splide__arrow--next">
            <ArrowForwardIcon />
          </div>
        </div>
      </Splide>
    </div>
  );
};

const MemoizedCarousel = React.memo(Carousel);

export default MemoizedCarousel;

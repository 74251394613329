import { ThemeOptions } from '@mui/material/styles/createTheme';

export const MuiButton: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      style: {
        lineHeight: '2.2rem',
        textTransform: 'unset',
      },
    },
    variants: [
      // CONTAINED
      {
        props: { variant: 'main_contained_complaint' },
        style: {
          backgroundColor: '#D7DDE6',
          color: '#394C6B',
          font: "600 1.2rem 'Open Sans', sans-serif",
          textTransform: 'capitalize',
          borderRadius: '.6rem',
        },
      },
      {
        props: { variant: 'main_contained_orange' },
        style: {
          backgroundColor: '#F2994A',
          color: '#FFFFFF',
          textTransform: 'capitalize',
          font: "600 1.2rem 'Open Sans', sans-serif",
          borderRadius: '.6rem',
        },
      },
      {
        props: { variant: 'main_contained_blue' },
        style: {
          backgroundColor: '#109CF1',
          borderRadius: '.3rem',
          color: '#FFFFFF',
          font: "600 1.6rem 'Open Sans', sans-serif",
        },
      },
      {
        props: { variant: 'main_contained_tangerine' },
        style: {
          backgroundColor: '#ED5338',
          borderRadius: '1rem',
          color: '#FFFFFF !important',
          textTransform: 'unset',
          font: '500 1.8rem Montserrat, sans-serif',
          paddingTop: '1.2rem',
          paddingBottom: '1.2rem',
          whiteSpace: "nowrap",
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), #ED5338 !important',
            color: '#FFFFFF !important',
          },
        },
      },
      {
        props: { variant: 'main_contained_green' },
        style: {
          backgroundColor: '#22BD69',
          borderRadius: '1rem',
          color: '#FFFFFF !important',
          textTransform: 'unset',
          font: '500 1.8rem Montserrat, sans-serif',
          paddingTop: '1.2rem',
          paddingBottom: '1.2rem',
          whiteSpace: "nowrap",
        },
      },
      {
        props: { variant: 'main_contained_blue_dk' },
        style: {
          backgroundColor: '#2F80ED',
          borderRadius: '.3rem',
          color: '#FFFFFF',
          font: '500 1.5rem Montserrat, sans-serif',
          textTransform: 'unset',
        },
      },
      {
        props: { variant: 'main_contained_published' },
        style: {
          cursor: "unset",
          backgroundColor: 'rgba(26, 26, 26, 0.1)',
          padding: '.8rem 1.6rem',
          borderRadius: '.8rem',
          color: '#FFFFFF',
          font: '500 1.5rem Montserrat, sans-serif',
          textTransform: 'unset',
        },
      },
      // OUTLINED
      {
        props: { variant: 'main_outlined_blue' },
        style: {
          border: '.1rem solid #109CF1',
          borderRadius: '.3rem',
          color: '#109CF1',
          font: "600 1.6rem 'Open Sans', sans-serif",
          padding: '1.4rem 2.4rem',
          textTransform: 'unset',
        },
      },
      {
        props: { variant: 'main_outlined_red' },
        style: {
          border: '.1rem solid #F7685B',
          borderRadius: '.3rem',
          color: '#F7685B',
          font: "600 1.6rem 'Open Sans', sans-serif",
        },
      },
      {
        props: { variant: 'main_outlined_tangerine' },
        style: {
          borderRadius: '1rem',
          border: '.1rem solid #ED5338',
          color: '#ED5338',
          font: '500 1.8rem Montserrat, sans-serif',
          paddingTop: '1.2rem',
          paddingBottom: '1.2rem',
          textTransform: 'unset',
          '&:hover': {
            borderColor: '#ED5338 !important',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), #ED5338 !important',
          },
        },
      },
      // TEXT
      {
        props: { variant: 'main_text' },
        style: {
          borderRadius: '.3rem',
          color: '#1A1A1A',
          font: '600 1.8rem Lato, sans-serif',
          '&:hover': {
            backgroundColor: 'unset !important',
            borderColor: 'unset !important',
            color: '#ED5338 !important',
          },
        },
      },
    ],
  },
};

import { Route, Routes } from 'react-router';

import { SignonPage } from 'pages/public';
import { Default } from 'layouts';

const SignonRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Default subscription={false} />}>
          <Route path="" element={<SignonPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default SignonRoutes;

import { Multimedia } from "app/api/hooks/materials";

export const useImagesFilters = (width: number, images?: Array<Multimedia>): Array<Multimedia> | undefined => {
  return images && images?.length > 0
    ? images.filter(({ device }) => (
      width > 480
        ? device === "laptop"
        : device === "mobile"
    ))
    : undefined
}

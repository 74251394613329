import { Typography } from '@mui/material';

import { BaseContent, BasePage } from 'components/base';
import { csw } from 'common/funcs/csw.func';

const NotFoundPage = () => {
  return (
    <>
      <BasePage>
        <BaseContent>
          <Typography
            textAlign={'center'}
            fontSize={csw('5rem', '5rem', '3.5rem', '2rem')}
          >
            404 Страница не найдена =(
          </Typography>
        </BaseContent>
      </BasePage>
    </>
  );
};

export default NotFoundPage;

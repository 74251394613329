import { ThemeOptions } from '@mui/material';

const hover =
  '' +
  '& .MuiPagination-ul li:nth-of-type(1):hover, ' +
  '& .MuiPagination-ul li:nth-last-of-type(1):hover, ' +
  '& .MuiPagination-ul li:nth-of-type(1):hover > button, ' +
  '& .MuiPagination-ul li:nth-last-of-type(1):hover > button';
export const MuiPagination: ThemeOptions['components'] = {
  MuiPagination: {
    styleOverrides: {
      root: {
        '& .MuiPagination-ul li div': {
          maxWidth: '2rem',
          minWidth: '2rem',
        },
        '& .MuiPagination-ul': {
          flexWrap: 'nowrap',
        },
        '& .MuiPagination-ul li button': {
          color: 'rgba(26, 26, 26, 0.5);',
        },
        '& .MuiPagination-ul li .Mui-selected': {
          backgroundColor: '#ED5338',
          color: '#FFFFFF',
        },
        '& .MuiPagination-ul li button:hover': {
          backgroundColor: '#ED5338',
          color: '#FFFFFF',
        },
        '& .MuiPagination-ul li:nth-of-type(1)': {
          marginRight: '1rem',
        },
        '& .MuiPagination-ul li:nth-last-of-type(1)': {
          marginLeft: '1rem',
        },
        '& .MuiPagination-ul li:nth-of-type(1), & .MuiPagination-ul li:nth-last-of-type(1)':
          {
            border: '.1rem solid #F4F4F5',
            borderRadius: '.8rem',
            backgroundColor: '#FDFDFD',
          },
        '& .MuiPagination-ul li:nth-of-type(1) button, & .MuiPagination-ul li:nth-last-of-type(1) button':
          {
            color: '#000000',
          },
        [hover]: {
          backgroundColor: 'unset',
          color: '#ED5338',
        },
        '& .MuiPagination-ul li button, & .MuiPagination-ul li button svg': {
          font: '600 1.4rem Montserrat, sans-serif',
          lineHeight: '2rem',
        },
      },
    },
  },
};

import { LabelHTMLAttributes } from "react";

import { mcn } from "common/funcs/mcn.func";
import styles from "./BaseLabel.module.scss";

type PropsType = {
  children: React.ReactNode;
  label: string;
  isDisabled?: boolean;
} & LabelHTMLAttributes<HTMLLabelElement>;

const BaseLabel = ({
  children,
  label,
  className = "",
  isDisabled,
  ...rest
}: PropsType) => {
  return (
    <>
      <label
        className={mcn([
          styles.BaseLabel,
          className,
          isDisabled ? styles.BaseLabel_disabled : "",
        ])}
        {...rest}
      >
        {children}
        <span className={styles.BaseLabel__label}>{label}</span>
      </label>
    </>
  );
};

export default BaseLabel;

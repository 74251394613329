import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'app/authSlice';

import { apiSlice } from './api/apiSlice';

export function makeStore() {
  return configureStore({
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
      auth: authReducer,
    },
    middleware: (gDM) => gDM().concat(apiSlice.middleware),
  });
}

const store = makeStore();

export type AppStore = ReturnType<typeof makeStore>;

export type AppState = ReturnType<typeof store.getState>;

export default store;

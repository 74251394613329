import React from "react";

import { BaseCheckbox, BaseLabel } from "../../../components/base";

import { useSelectContext } from "../_context";
import { OptionType } from "common/types/option.type";
import styles from "./CheckboxItem.module.scss";

type PropsType = OptionType & { selected: boolean };

const CheckboxItem = ({ id, name, selected }: PropsType) => {

  const { onChangeHandler } = useSelectContext();

  return (
    <>
      <li className={styles.CheckboxItem} onClick={(e) => e.stopPropagation()}>
        <BaseLabel className={styles.CheckboxItem__label} label={name}>
          <BaseCheckbox
            className={styles.CheckboxItem__checkbox}
            onChange={() => onChangeHandler({ id, name })}
            checked={selected}
            value={id}
            name={name}
            id={`${id}`}
          />
        </BaseLabel>
      </li>
    </>
  )
}

const MemoizedCheckboxItem = React.memo(CheckboxItem);

export default MemoizedCheckboxItem;

import { apiSlice as api } from "../apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    materialsControllerGetAll: build.query<
      MaterialsControllerGetAllApiResponse,
      MaterialsControllerGetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials`,
        params: {
          createdAt: queryArg.createdAt,
          authorId: queryArg.authorId,
          categoryId: queryArg.categoryId,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    materialsControllerCreateOne: build.mutation<
      MaterialsControllerCreateOneApiResponse,
      MaterialsControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials`,
        method: "POST",
        body: queryArg.createOneMaterialRequestDto,
      }),
    }),
    materialsControllerGetAuthors: build.query<
      MaterialsControllerGetAuthorsApiResponse,
      MaterialsControllerGetAuthorsApiArg
    >({
      query: () => ({ url: `/api/v1/materials/authors` }),
    }),
    materialsControllerGetOne: build.query<
      MaterialsControllerGetOneApiResponse,
      MaterialsControllerGetOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/${queryArg.id}`,
        params: {
          createdAt: queryArg.createdAt,
          authorId: queryArg.authorId,
          categoryId: queryArg.categoryId,
        },
      }),
    }),
    materialsControllerDeleteOne: build.mutation<
      MaterialsControllerDeleteOneApiResponse,
      MaterialsControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    materialsControllerUploadImages: build.mutation<
      MaterialsControllerUploadImagesApiResponse,
      MaterialsControllerUploadImagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/${queryArg.id}/images`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type MaterialsControllerGetAllApiResponse = /** status 200  */ any & {
  items?: Materials[];
  totalPages?: number;
  count?: number;
};
export type MaterialsControllerGetAllApiArg = {
  /** Allow to sort entries by createdAt field, takes two values ASC and DESC */
  createdAt?: string;
  /** Allow to get entries by aithor */
  authorId?: string;
  /** Allow to get entries by category id */
  categoryId?: string;
  page: number;
  limit: number;
};
export type MaterialsControllerCreateOneApiResponse =
  /** status 200  */ Materials;
export type MaterialsControllerCreateOneApiArg = {
  createOneMaterialRequestDto: CreateOneMaterialRequestDto;
};
export type MaterialsControllerGetAuthorsApiResponse =
  /** status 200  */ MaterialAuthors[];
export type MaterialsControllerGetAuthorsApiArg = void;
export type MaterialsControllerGetOneApiResponse = /** status 200  */ {
  result?: Materials;
  pages?: object;
};
export type MaterialsControllerGetOneApiArg = {
  id: number;
  /** Allow to sort entries by createdAt field, takes two values ASC and DESC  */
  createdAt?: string;
  authorId?: string;
  /** Allow to get entries by category id */
  categoryId?: string;
};
export type MaterialsControllerDeleteOneApiResponse = unknown;
export type MaterialsControllerDeleteOneApiArg = {
  id: number;
};
export type MaterialsControllerUploadImagesApiResponse =
  /** status 200  */ Multimedia[];
export type MaterialsControllerUploadImagesApiArg = {
  id: number;
  body: {
    multimedia?: Blob;
  };
};
export type OrganizationStatuses = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type OrganizationTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type WhatHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type UserSessions = {
  id: number;
  refreshToken?: string;
  fingerPrint?: string;
  userId: number;
  user: Users;
};
export type RequestStatuses = {
  id: number;
  name: string;
  requests: Requests[];
};
export type Requests = {
  id: number;
  name: string;
  description: string;
  address: string;
  organizationHelpedId?: number;
  publishedAt?: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  statusId: number;
  status: RequestStatuses;
  organizationId: number;
  organization: Organizations;
  userId: number;
  user: Users;
};
export type Multimedia = {
  id: number;
  fileSize: number;
  fileName: string;
  originalFileName: string;
  mimeType: string;
  path: string;
  device?: string;
  createdAt: string;
  updatedAt: string;
  userId?: number;
  user?: Users;
  organizationId?: number;
  organization?: Organizations;
  materialsId?: number;
  materials?: Materials;
};
export type UserRoles = {
  id: number;
  name: string;
  users: Users[];
};
export type Users = {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  vkLink?: string;
  okLink?: string;
  isAcceptedCashPolicy: boolean;
  isAcceptedNoCashPolicy: boolean;
  createdAt: string;
  updatedAt: string;
  sessions?: UserSessions;
  origanization?: Organizations;
  requests: Requests[];
  avatar?: Multimedia;
  roleId: number;
  role: UserRoles;
};
export type Cities = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type Organizations = {
  id: number;
  name?: string;
  siteLink?: string;
  INN?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  address?: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  organizationStatusId: number;
  organizationStatus: OrganizationStatuses;
  organizationTypeId: number;
  organizationType: OrganizationTypes;
  whatHelpTypes: WhatHelpTypes[];
  whoHelpTypes: WhoHelpTypes[];
  userId: number;
  user: Users;
  documents: Multimedia[];
  requests: Requests[];
  cityId?: number;
  city?: Cities;
};
export type WhoHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
  materials: Materials[];
  requests: Requests[];
};
export type MaterialAuthors = {
  id: number;
  name: string;
  Materials: Materials[];
};
export type Materials = {
  id: number;
  title: string;
  sign: string;
  text: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  multimedia: Multimedia[];
  authorId: number;
  author: MaterialAuthors;
};
export type OptionDto = {
  id: number;
  name: string;
};
export type CreateOneMaterialRequestDto = {
  title: string;
  sign: string;
  text: string;
  whoHelpTypes: OptionDto[];
  author: OptionDto;
};
export const {
  useMaterialsControllerGetAllQuery,
  useMaterialsControllerCreateOneMutation,
  useMaterialsControllerGetAuthorsQuery,
  useMaterialsControllerGetOneQuery,
  useMaterialsControllerDeleteOneMutation,
  useMaterialsControllerUploadImagesMutation,
} = injectedRtkApi;

import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast/headless';

import { Preloader, Select } from 'content';
import {
  BaseButton,
  BaseInputError,
  BaseModal,
  BaseTextarea,
  BaseTextField,
} from 'components/base';

import { CreateOneRequestDto, useRequestsControllerCreateOneMutation } from 'app/api/hooks/requests';
import { useOrganizationsWhoHelpControllerGetAllQuery } from 'app/api/hooks/organizations';
import { CreateRequestScheme } from 'common/validation';
import { isSelectedOption } from 'common/funcs/is-selected-option.func';
import { OptionType } from 'common/types/option.type';
import { useLoading } from 'hooks';
import { em } from "common/funcs/em";
import styles from './CreateRequest.module.scss';

const CreateRequest = () => {
  const navigation = useNavigate();
  const { isRequestLoading, onRequestLoadingHandler } = useLoading();

  // Requests to api
  const { data: whoHelpTypes } = useOrganizationsWhoHelpControllerGetAllQuery();
  const [createRequest] = useRequestsControllerCreateOneMutation({ fixedCacheKey: "request-one-mutation" });

  // Form hook initialization
  const form = useForm<CreateOneRequestDto>({
    resolver: yupResolver(CreateRequestScheme),
    defaultValues: {
      name: '',
      description: '',
      address: '',
      whoHelpTypes: [],
    },
  });

  // Form state handler function
  const onStateHandler = (key: keyof CreateOneRequestDto, value: string | Array<OptionType>) => {
    form.setValue(key, value, {
      shouldValidate: true
    });
  };

  // Form state submit function
  const onSubmitHandler = async (createOneRequestDto: CreateOneRequestDto) => {
    try {
      onRequestLoadingHandler(true);
      await createRequest({ createOneRequestDto }).unwrap();
      toast.success("Запрос успешно создан");
      navigation(-1);
    } catch (e) {
      toast.error(em(e));
    } finally {
      onRequestLoadingHandler(false);
    }
  };

  // Form state
  const errors = form.formState.errors;
  const state = form.watch();

  return (
    <>
      <BaseModal title="Создать запрос">
        <form className={styles.CreateRequest} onSubmit={form.handleSubmit(onSubmitHandler)}>
          <ul className={styles.CreateRequest__list}>
            <li className={styles.CreateRequest__item}>
              <h3 className={styles.CreateRequest__subtitle}>
                Заполните поле запроса
              </h3>
            </li>
            <li className={styles.CreateRequest__item}>
              <BaseTextField
                className={styles.CreateRequest__input}
                onChange={(e) => onStateHandler('name', e.target.value)}
                value={state.name}
                label="Тема"
              />
              <BaseInputError message={errors["name"]?.message} />
            </li>
            <li className={styles.CreateRequest__item}>
              <BaseTextarea
                className={styles.CreateRequest__textarea}
                onChange={(e) => onStateHandler('description', e.target.value)}
                value={state.description}
                label="Описание"
              />
              <BaseInputError message={errors["description"]?.message} />
            </li>
            <li className={styles.CreateRequest__item}>
              <Select
                onChange={(whoHelpTypes) => {
                  if (Array.isArray(whoHelpTypes)) {
                    onStateHandler('whoHelpTypes', whoHelpTypes);
                  }
                }}
                value={state.whoHelpTypes}
                label="Теги"
                multiple={true}
                variant="select"
              >
                <Select.OptionList>
                  {whoHelpTypes?.map((item) => (
                    (item.id !== 0) && (
                      <Select.OptionItem
                        selected={isSelectedOption(state.whoHelpTypes, item.id)}
                        key={item.id}
                        {...item}
                      />
                    )
                  ))}
                </Select.OptionList>
              </Select>
              <BaseInputError message={errors["whoHelpTypes"]?.message} />
            </li>
            <li className={styles.CreateRequest__item}>
              <BaseTextField
                className={styles.CreateRequest__input}
                onChange={(e) => onStateHandler('address', e.target.value)}
                value={state.address}
                label="Адрес для оказания помощи"
              />
              <BaseInputError message={errors["address"]?.message} />
            </li>
            <li className={styles.CreateRequest__item}>
              <Preloader isLoading={isRequestLoading}>
                <BaseButton
                  variant="main_contained_tangerine"
                  fullWidth={true}
                  type="submit"
                >
                  Отправить
                </BaseButton>
                <BaseButton
                  variant="main_text"
                  fullWidth={true}
                  onClick={() => navigation(-1)}
                >
                  Отмена
                </BaseButton>
              </Preloader>
            </li>
          </ul>
        </form>
      </BaseModal>
    </>
  );
};

export default CreateRequest;

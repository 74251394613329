import styles from './FormCardSubtitle.module.scss';

export const FormCardSubtitle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <>
      <h3 className={styles.FormCardSubtitle}>{children}</h3>
    </>
  );
};

import React, { NamedExoticComponent } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";

import { OptionList } from "./OptionList";
import { OptionItem } from "./OptionItem";
import { CheckboxList } from "./CheckboxList";
import { CheckboxItem } from "./CheckboxItem";

import { SelectContext } from "./_context";
import { OptionType } from "common/types/option.type";
import { useToggle } from "hooks";
import { mcn } from "common/funcs/mcn.func";
import styles from "./Select.module.scss";

export type SelectedType = { id: number };

export type PropsType = {
  className?: string;
  children: React.ReactNode;
  onChange: (
    selectedOptions: OptionType | Array<OptionType>,
    selectedIDs: number | Array<number>,
  ) => void;
  value?: OptionType | Array<OptionType> | null | undefined;
  multiple: boolean;
  label: string;
  isOpen?: boolean;
  variant: "select" | "checkbox";
  noClose?: boolean;
};

export type MemoizedSelectComposeType = {
  OptionList: typeof OptionList;
  OptionItem: typeof OptionItem;
  CheckboxList: typeof CheckboxList;
  CheckboxItem: typeof CheckboxItem;
};

const Select = ({
  className = "",
  children,
  onChange,
  variant,
  value,
  multiple,
  label,
  isOpen = false,
  noClose = false,
}: PropsType) => {
  const { isActive, onToggle, onClose } = useToggle(isOpen);

  // Check value from props
  const isNotEmptyValue =
    multiple && Array.isArray(value)
      ? value.length !== 0
      : value && "id" in value;

  // Select variants check
  const isCheckbox = variant === "checkbox";
  const isSelect =
    (variant !== "checkbox" && isActive) ||
    (variant !== "checkbox" && isNotEmptyValue);

  // Value state handler
  const onChangeHandler = ({ id, name }: OptionType) => {
    if (multiple) {
      if (value && Array.isArray(value)) {
        const valueCopy = [...value];

        const foundIndex = valueCopy.findIndex((option) => option.id === id);

        foundIndex !== -1
          ? valueCopy.splice(foundIndex, 1)
          : valueCopy.push({ id, name });

        const newValue = valueCopy.map(({ id }) => +id);

        onChange(valueCopy, newValue);
      } else {
        onChange([{ id: +id, name }], +id);
      }
    } else {
      onChange({ id: +id, name }, +id);
      onClose();
    }
  };
  const ref = React.useRef<HTMLDivElement | null>(null);

  // Close menu by clcik outside select
  React.useEffect(() => {
    if (noClose) return;

    const handleClickOutside = (event: any) => {
      if (!ref?.current?.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <SelectContext.Provider
        value={{
          onChangeHandler,
          isActive,
          multiple,
          value,
        }}
      >
        <div
          className={mcn([styles.Select, className])}
          onClick={onToggle}
          ref={ref}
        >
          <div
            className={mcn([
              styles.Select__label,
              isCheckbox && isActive
                ? styles.Select__label_active
                : isSelect
                ? styles.Select__label_active
                : "",
            ])}
          >
            {label}
          </div>
          <div
            className={mcn([
              styles.Select__arrow,
              isActive ? styles.Select__arrow_active : "",
            ])}
          >
            {isActive ? (
              isCheckbox ? null : (
                <CloseIcon />
              )
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </div>
          <div className={styles.Select__content}>{children}</div>
        </div>
      </SelectContext.Provider>
    </>
  );
};

const MemoizedSelect = React.memo(
  Select,
) as unknown as NamedExoticComponent<PropsType> & MemoizedSelectComposeType;

MemoizedSelect.CheckboxList = CheckboxList;
MemoizedSelect.CheckboxItem = CheckboxItem;
MemoizedSelect.OptionList = OptionList;
MemoizedSelect.OptionItem = OptionItem;

export default MemoizedSelect;

import { ThemeOptions } from '@mui/material';

export const MuiTab: ThemeOptions['components'] = {
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        color: 'rgba(26, 26, 26, 0.7)',
        textTransform: 'unset',
        font: '500 1.6rem Montserrat, sans-serif',
      },
    },
  },
};

import { CircularProgress } from '@mui/material';

type PropsType = {
  className?: string;
  style?: React.CSSProperties
};

const BaseLoader = ({ className = '', style = {} }: PropsType) => {
  return (
    <>
      <CircularProgress
        color="primary"
        style={{
          display: "grid",
          width: "7rem",
          height: "7rem",
          margin: "0 auto",
          ...style
        }}
        className={className}
      />
    </>
  );
};

export default BaseLoader;

import { apiSlice as api } from "../apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerSignup: build.mutation<
      AuthControllerSignupApiResponse,
      AuthControllerSignupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/signup`,
        method: "POST",
        body: queryArg.signupRequestDto,
      }),
    }),
    authControllerSignon: build.mutation<
      AuthControllerSignonApiResponse,
      AuthControllerSignonApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/signon`,
        method: "POST",
        body: queryArg.signonRequestDto,
      }),
    }),
    authControllerSignout: build.mutation<
      AuthControllerSignoutApiResponse,
      AuthControllerSignoutApiArg
    >({
      query: () => ({ url: `/api/v1/auth/signout`, method: "POST" }),
    }),
    authControllerRefresh: build.mutation<
      AuthControllerRefreshApiResponse,
      AuthControllerRefreshApiArg
    >({
      query: () => ({ url: `/api/v1/auth/refresh`, method: "POST" }),
    }),
    materialsControllerGetAuthors: build.query<
      MaterialsControllerGetAuthorsApiResponse,
      MaterialsControllerGetAuthorsApiArg
    >({
      query: () => ({ url: `/api/v1/materials/authors` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AuthControllerSignupApiResponse =
  /** status 200  */ SignupResponseDto;
export type AuthControllerSignupApiArg = {
  signupRequestDto: SignupRequestDto;
};
export type AuthControllerSignonApiResponse =
  /** status 200  */ SignonResponseDto;
export type AuthControllerSignonApiArg = {
  signonRequestDto: SignonRequestDto;
};
export type AuthControllerSignoutApiResponse =
  /** status 200  */ SignoutResponseDto;
export type AuthControllerSignoutApiArg = void;
export type AuthControllerRefreshApiResponse =
  /** status 200  */ RefreshResponseDto;
export type AuthControllerRefreshApiArg = void;
export type MaterialsControllerGetAuthorsApiResponse =
  /** status 200  */ MaterialAuthors[];
export type MaterialsControllerGetAuthorsApiArg = void;
export type SignupResponseDto = {};
export type SignupRequestDto = {
  email: string;
  password: string;
  organizationTypeId: number;
  confirmationCode: string;
};
export type SignonUserResponseDto = {
  organizationTypeId: number;
  organizationId: number;
  role: string;
  userId: number;
};
export type SignonResponseDto = {
  accessToken: string;
  user: SignonUserResponseDto;
};
export type SignonRequestDto = {
  email: string;
  password: string;
};
export type SignoutResponseDto = {};
export type RefreshResponseDto = {
  accessToken: string;
};
export type OrganizationStatuses = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type OrganizationTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type WhatHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type UserSessions = {
  id: number;
  refreshToken?: string;
  fingerPrint?: string;
  userId: number;
  user: Users;
};
export type RequestStatuses = {
  id: number;
  name: string;
  requests: Requests[];
};
export type Requests = {
  id: number;
  name: string;
  description: string;
  address: string;
  organizationHelpedId?: number;
  publishedAt?: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  statusId: number;
  status: RequestStatuses;
  organizationId: number;
  organization: Organizations;
  userId: number;
  user: Users;
};
export type Multimedia = {
  id: number;
  fileSize: number;
  fileName: string;
  originalFileName: string;
  mimeType: string;
  path: string;
  device?: string;
  createdAt: string;
  updatedAt: string;
  userId?: number;
  user?: Users;
  organizationId?: number;
  organization?: Organizations;
  materialsId?: number;
  materials?: Materials;
};
export type UserRoles = {
  id: number;
  name: string;
  users: Users[];
};
export type Users = {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  vkLink?: string;
  okLink?: string;
  isAcceptedCashPolicy: boolean;
  isAcceptedNoCashPolicy: boolean;
  createdAt: string;
  updatedAt: string;
  sessions?: UserSessions;
  origanization?: Organizations;
  requests: Requests[];
  avatar?: Multimedia;
  roleId: number;
  role: UserRoles;
};
export type Cities = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type Organizations = {
  id: number;
  name?: string;
  siteLink?: string;
  INN?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  address?: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  organizationStatusId: number;
  organizationStatus: OrganizationStatuses;
  organizationTypeId: number;
  organizationType: OrganizationTypes;
  whatHelpTypes: WhatHelpTypes[];
  whoHelpTypes: WhoHelpTypes[];
  userId: number;
  user: Users;
  documents: Multimedia[];
  requests: Requests[];
  cityId?: number;
  city?: Cities;
};
export type WhoHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
  materials: Materials[];
  requests: Requests[];
};
export type Materials = {
  id: number;
  title: string;
  sign: string;
  text: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  multimedia: Multimedia[];
  authorId: number;
  author: MaterialAuthors;
};
export type MaterialAuthors = {
  id: number;
  name: string;
  Materials: Materials[];
};
export const {
  useAuthControllerSignupMutation,
  useAuthControllerSignonMutation,
  useAuthControllerSignoutMutation,
  useAuthControllerRefreshMutation,
  useMaterialsControllerGetAuthorsQuery,
} = injectedRtkApi;

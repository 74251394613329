import { createSlice } from '@reduxjs/toolkit';
import { AppState } from './store';
import { RoleEnum } from 'common/enum';
import {
  delFromStorage,
  getFromStorage,
  setIntoStorage,
} from 'common/config/storage.config';

type AccessTokenType = string;

type UserType = {
  userId: number;
  role: keyof typeof RoleEnum;
  organizationTypeId: number;
  organizationId: number;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: getFromStorage('ACCESS_TOKEN') ?? null,
    user: getFromStorage('USER') ?? null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, user } = action.payload;

      setIntoStorage('ACCESS_TOKEN', accessToken);
      setIntoStorage('USER', user);

      state.accessToken = accessToken;
      state.user = user;
    },
    delCredentials: (state, _) => {
      delFromStorage('ACCESS_TOKEN');
      delFromStorage('USER');

      state.accessToken = null;
      state.user = null;
    },
  },
});

export const { setCredentials, delCredentials } = authSlice.actions;

export const selectAccessToken = (state: AppState): AccessTokenType => state.auth.accessToken;

export const selectUser = (state: AppState): UserType => state.auth.user;

export default authSlice.reducer;

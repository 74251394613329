import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { BaseButton, BaseDate } from "components/base";
import RequestStatus from "content/RequestStatus/RequestStatus";

import { OrganizationTypeEnum, RequestStatusEnum } from "common/enum";
import { OptionType } from "common/types/option.type";
import { selectUser } from "app/authSlice";
import { mcn } from "common/funcs/mcn.func";
import styles from "./RequestPreview.module.scss";

type PropsType = {
  className?: string;
  complited?: boolean;
  phoneNumber1?: string;
  email?: string;
  address?: string;
  description: string;
  organizationName?: string;
  publishedAt?: string;
  status?: OptionType;
  organizationWhoHelpTypes?: Array<OptionType>;
  requestWhoHelpTypes?: Array<OptionType>;
  organizationHelpedId?: number;
  updatedAt?: string;
  organizationTypeId?: number;
  id: number;

  variant?: "common" | "admin";

  onPublish?: () => void;
  onCancel?: () => void;
  onNavigate?: () => void;
};

const RequestPreview = ({
  className = "",
  variant = "common",
  ...rest
}: PropsType) => {
  const navigation = useNavigate();
  const emptyField = "Не указано";
  const isAdmin = variant === "admin";

  const isPublished = rest.status?.id === RequestStatusEnum["Опубликовано"];
  const isResolved = rest.status?.id === RequestStatusEnum["Одобрено"];
  const isRejected = rest.status?.id === RequestStatusEnum["Отменено"];
  const isHelped = rest.status?.id === RequestStatusEnum["Отправлено"];
  const isNGO = rest?.organizationTypeId === OrganizationTypeEnum["НКО"];

  return (
    <>
      <div className={mcn([styles.RequestPreview, className])}>
        <div className={styles.RequestPreview__inner}>
          <div className={styles.RequestPreview__header}>
            <div className={styles.RequestPreview__info}>
              <h3 className={styles.RequestPreview__title}>
                {rest.organizationName ?? emptyField}
              </h3>

              {/* <-- HIDDEN WHEN "COMMON" VARIANT START --> */}
              <>
                {isPublished ? (
                  <div
                    className={styles.RequestPreview__published}
                    style={!isAdmin ? { display: "none" } : {}}
                  >
                    <BaseButton
                      disabled={true}
                      variant="main_contained_published"
                    >
                      Опубликовано
                    </BaseButton>
                  </div>
                ) : (
                  <div
                    className={styles.RequestPreview__buttons}
                    style={
                      !isAdmin || isResolved || isRejected || isHelped
                        ? { display: "none" }
                        : {}
                    }
                  >
                    <BaseButton
                      className={styles.RequestPreview__button}
                      variant="main_contained_tangerine"
                      onClick={rest.onPublish}
                    >
                      Опубликовать
                    </BaseButton>
                    <BaseButton
                      className={styles.RequestPreview__button}
                      variant="main_outlined_tangerine"
                      onClick={rest.onCancel}
                    >
                      Отклонить
                    </BaseButton>
                  </div>
                )}
              </>
              {/* <-- HIDDEN WHEN "COMMON" VARIANT END --> */}
            </div>

            {/* <-- HIDDEN WHEN "COMMON" VARIANT START --> */}
            <RequestStatus
              className={styles.RequestPreview__status}
              hidden={isPublished || !isAdmin}
              name={rest?.status?.name}
            />
            {/* <-- HIDDEN WHEN "COMMON" VARIANT END --> */}

            <ul className={styles.RequestPreview__contacts}>
              <li className={styles.RequestPreview__contact}>
                <PhoneIcon
                  className={styles.RequestPreview__icon}
                  htmlColor="#1A1A1A"
                />
                <p className={styles.RequestPreview__item}>
                  {rest?.phoneNumber1 ?? emptyField}
                </p>
              </li>
              <li className={styles.RequestPreview__contact}>
                <MailOutlineIcon
                  className={styles.RequestPreview__icon}
                  htmlColor="#1A1A1A"
                />
                <p className={styles.RequestPreview__item}>
                  {rest?.email ?? emptyField}
                </p>
              </li>
              <li className={styles.RequestPreview__contact}>
                <LocationOnIcon
                  className={styles.RequestPreview__icon}
                  htmlColor="#1A1A1A"
                />
                <p className={styles.RequestPreview__item}>
                  {rest?.address ?? emptyField}
                </p>
              </li>
              <li
                className={mcn([
                  styles.RequestPreview__contact,
                  styles.RequestPreview__details,
                ])}
                onClick={rest.onNavigate}
                style={
                  isAdmin && rest?.organizationHelpedId
                    ? {}
                    : { display: "none" }
                }
              >
                <HelpOutlineIcon
                  className={styles.RequestPreview__icon}
                  htmlColor="#109cf1"
                />
                <p className={styles.RequestPreview__item}>Откликнулся</p>
              </li>
            </ul>
          </div>
          <div className={styles.RequestPreview__body}>
            <OverflowText text={rest?.description} />
          </div>
          <div
            className={styles.RequestPreview__footer}
            style={isResolved || isRejected ? { display: "none" } : {}}
          >
            {/* <-- HIDDEN WHEN "ADMIN" VARIANT START --> */}
            <div
              className={styles.RequestPreview__controls}
              style={isAdmin ? { display: "none" } : {}}
            >
              <div className={styles.RequestPreview__controls_inner}>
                {isHelped || isResolved ? (
                  <>
                    <BaseButton
                      className={styles.RequestPreview__control}
                      disabled={isHelped || isResolved}
                      variant="main_contained_green"
                    >
                      {isResolved ? "Одобрено" : "Помощь оказана"}
                    </BaseButton>
                  </>
                ) : (
                  <BaseButton
                    className={mcn([
                      styles.RequestPreview__control,
                      isNGO ? styles.RequestPreview__control_hide : "",
                    ])}
                    disabled={isNGO}
                    variant="main_contained_tangerine"
                    onClick={() => {
                      navigation(`/account/actions/create_appeal/${rest.id}`);
                    }}
                  >
                    Помочь
                  </BaseButton>
                )}
              </div>

              <BaseDate
                variant="request"
                value={rest?.publishedAt ?? new Date().toISOString()}
              />
            </div>
            {/* <-- HIDDEN WHEN "ADMIN" VARIANT END --> */}

            {/* <-- HIDDEN WHEN "COMMON" VARIANT START --> */}
            <ul className={styles.RequestPreview__tags} hidden={!isAdmin}>
              {/* WHO HELP TYPES */}
              {rest?.requestWhoHelpTypes &&
                rest.requestWhoHelpTypes.map(({ id, name }) => (
                  <li
                    className={mcn([
                      styles.RequestPreview__tag,
                      styles.RequestPreview__tag_purple,
                    ])}
                    key={id}
                  >
                    {name}
                  </li>
                ))}

              {rest?.organizationWhoHelpTypes &&
                rest?.organizationWhoHelpTypes.map(({ id, name }) => (
                  <li
                    className={mcn([
                      styles.RequestPreview__tag,
                      styles.RequestPreview__tag_tangerine,
                    ])}
                    key={id}
                  >
                    {name}
                  </li>
                ))}
            </ul>
            {/* <-- HIDDEN WHEN "COMMON" VARIANT END --> */}
          </div>
        </div>
      </div>
    </>
  );
};

const OverflowText = ({ text }: { text: string }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onClickHandler = () => {
    setIsOpen((prev) => !prev);
  };

  const textIsMorenThen = text.length >= 197;

  const description = isOpen
    ? text
    : textIsMorenThen
    ? text.slice(0, 197) + "..." // till 197 symbols
    : text;

  return (
    <p
      className={styles.OverflowText}
      onClick={onClickHandler}
      data-label={textIsMorenThen ? (isOpen ? "Свернуть" : "Развернуть") : ""}
    >
      {description}
    </p>
  );
};

const MemoizedRequestPreview = React.memo(RequestPreview);

export default MemoizedRequestPreview;

import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material';

import { OptionType } from 'common/types/option.type';

type PropsType = {
  items?: Array<OptionType>;
  value: number;
  handleChange: (index: number, option: OptionType) => void;
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    '& button': {}
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "1.4rem",
    marginRight: "1.6rem !important",
    '&:nth-last-child(1)': {
      margin: "0 !important",
    }
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
    marginRight: "1.2rem !important",
    '&:nth-last-child(1)': {
      margin: "0 !important",
    }
  },
}));

const BaseTabs = ({ items = [], value, handleChange }: PropsType) => {
  return items
    ? (
      <>
        <StyledTabs
          value={value}
          onChange={(_: React.SyntheticEvent, index: number) => handleChange(index, items[index])}
          aria-label="basic tabs example"
        >
          {items.map(({ name }, key) => (
            <StyledTab label={name} key={key} {...attributes(key)} />
          ))}
        </StyledTabs>
      </>
    )
    : null;
};

function attributes(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default BaseTabs;

import React from "react";
import toast from "react-hot-toast/headless";
import { Outlet, useNavigate } from "react-router";

import { BasePagination, BaseTabs } from "components/base";
import { NoEntries, Preloader, RequestPreview, Select } from "content";
import { AdminFooter, AdminHeader } from "content/admin";

import {
  Requests,
  RequestsControllerGetAllApiArg,
  useRequestsControllerGetAllQuery,
  useRequestsControllerUpdateStatusMutation,
  useRequestStatusesControllerGetAllQuery,
} from "app/api/hooks/requests";
import { useOrganizationsWhoHelpControllerGetAllQuery } from "app/api/hooks/organizations";
import { useCitiesControllerGetAllQuery } from "app/api/hooks/cities";
import { RequestStatusEnum } from "common/enum";
import { propsRequest } from "common/funcs/props-request.func";
import { useFilters } from "hooks";
import { OptionType } from "common/types/option.type";
import { em } from "common/funcs/em";
import styles from "./AdminAppeals.module.scss";

const AdminAppealsPage = () => {
  const navigate = useNavigate();
  const [limit] = React.useState(6);
  const [page] = React.useState(1);

  // Filters of the request
  const { filters, onHandleFilters } =
    useFilters<RequestsControllerGetAllApiArg>({
      statusId: RequestStatusEnum["На рассмотрении"],
      cityId: undefined,
      limit,
      page,
    });

  React.useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, [filters.page]);

  const { data, isLoading, refetch } = useRequestsControllerGetAllQuery(
    filters,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
      pollingInterval: 25000,
    },
  );

  // Request to cities
  const { data: cities } = useCitiesControllerGetAllQuery({
    limit: 322,
    page: 1,
  });

  const [city, setCity] = React.useState<OptionType | undefined>();

  React.useEffect(() => {
    if (!cities && !cities?.items) return;
    setCity(cities.items[0]);
  }, [cities]);

  // Request to whoHelpType
  const { data: directions } = useOrganizationsWhoHelpControllerGetAllQuery();

  const [direction, setDirection] = React.useState<OptionType | undefined>();

  React.useEffect(() => {
    if (!directions) return;
    setDirection(directions[0]); // Все
  }, [directions]);

  // Request to statuses
  const { data: requestStatuses } =
    useRequestStatusesControllerGetAllQuery(undefined);

  const [status, setStatus] = React.useState<OptionType | undefined>();

  React.useEffect(() => {
    if (!requestStatuses) return;
    setStatus(requestStatuses[1]); // На рассмотрении
  }, [requestStatuses]);

  // Tabs switch state
  const [value, setValue] = React.useState(0);

  // The function handle tabs
  const onHandleTabsChange = (index: number, option: OptionType) => {
    onHandleFilters({
      statusId: option.id,
      cityId: undefined,
      limit,
      page,
    });

    if (index === 0 && requestStatuses && cities?.items) {
      setStatus(requestStatuses[1]);
      setCity(cities.items[0]);
    }

    setValue(index);
  };

  const [updateRequestStatusById] = useRequestsControllerUpdateStatusMutation();

  // The function changes status to "Опубликовано"
  const onPublishHandler = async (id: number) => {
    try {
      await toast.promise(
        updateRequestStatusById({
          id,
          updateStatusRequestDto: {
            statusId: RequestStatusEnum["Опубликовано"],
          },
        }).unwrap(),
        {
          loading: "Запрос обрабатывается",
          success: "Заявка успешно опубликована",
          error: "Не удалось выполнить запрос",
        },
      );
      refetch();
    } catch (e) {
      toast.error(em(e));
    }
  };

  // The function changes status to "Отменено"
  const onCancelHandler = async (id: number) => {
    try {
      await toast.promise(
        updateRequestStatusById({
          id,
          updateStatusRequestDto: {
            statusId: RequestStatusEnum["Отменено"],
          },
        }).unwrap(),
        {
          loading: "Запрос обрабатывается",
          success: "Заявка успешно отменена",
          error: "Не удалось выполнить запрос",
        },
      );
      refetch();
    } catch (e) {
      toast.error(em(e));
    }
  };

  // The function navigate handler
  const onNavigateHandler = (id: number | undefined) => {
    if (!id) return toast.success("Пока что никто не откликнулся на обращение");
    navigate(`${id}`);
  };

  const isEntriesExists = data?.items?.length > 0;

  return (
    <>
      <div className={styles.AdminAppealsPage}>
        <AdminHeader
          className={styles.AdminAppealsPage__header}
          variant="filters"
          title="Обращения"
        >
          <div
            className={styles.AdminAppealsPage__filters}
            hidden={value === 1}
          >
            {/* CITY */}
            <Select
              className={styles.AdminAppealsPage__filter}
              onChange={(selected) => {
                if (Array.isArray(selected)) return;

                setCity(selected);
                onHandleFilters({ cityId: selected.id });
              }}
              multiple={false}
              value={city}
              variant="select"
              label="Город"
            >
              <Select.OptionList>
                {cities?.items &&
                  cities.items.map((item: OptionType, i: number) => (
                    <Select.OptionItem
                      selected={city?.id === item.id}
                      key={item.id}
                      {...item}
                    />
                  ))}
              </Select.OptionList>
            </Select>

            {/* DIRRECTION */}
            <Select
              className={styles.AdminAppealsPage__filter}
              onChange={(selected) => {
                if (!Array.isArray(selected)) {
                  onHandleFilters({ directionId: selected.id });
                  setDirection(selected);
                }
              }}
              value={direction}
              label="Направление"
              multiple={false}
              variant="select"
            >
              <Select.OptionList>
                {directions &&
                  directions.map((item) => (
                    <Select.OptionItem
                      selected={direction?.id === item.id}
                      key={item.id}
                      {...item}
                    />
                  ))}
              </Select.OptionList>
            </Select>

            {/* STATUS */}
            <Select
              className={styles.AdminAppealsPage__filter}
              onChange={(selected) => {
                if (Array.isArray(selected)) return;

                setStatus(selected);
                onHandleFilters({ statusId: selected.id });
              }}
              multiple={false}
              variant="select"
              value={status}
              label="Статус"
            >
              <Select.OptionList>
                {requestStatuses &&
                  requestStatuses.map((item: OptionType) => (
                    <Select.OptionItem
                      selected={status?.id === item.id}
                      key={item.id}
                      {...item}
                    />
                  ))}
              </Select.OptionList>
            </Select>
          </div>
        </AdminHeader>
        <div className={styles.AdminAppealsPage__tabs}>
          <BaseTabs
            handleChange={onHandleTabsChange}
            value={value}
            items={tabs}
          />
        </div>
        <div className={styles.AdminAppealsPage__body}>
          <Preloader isLoading={isLoading}>
            {isEntriesExists ? (
              data.items.map((item: Requests) => (
                <RequestPreview
                  onPublish={async () => await onPublishHandler(item.id)}
                  onCancel={async () => await onCancelHandler(item.id)}
                  onNavigate={() =>
                    onNavigateHandler(item.organizationHelpedId)
                  }
                  variant="admin"
                  {...propsRequest(item)}
                />
              ))
            ) : (
              <NoEntries />
            )}
          </Preloader>
        </div>
        <AdminFooter className={styles.AdminAppealsPage__footer}>
          {isEntriesExists ? (
            <BasePagination
              onChange={(page) => onHandleFilters({ page })}
              count={data?.totalPages}
              page={filters.page}
            />
          ) : null}
        </AdminFooter>
      </div>
      <Outlet />
    </>
  );
};

const tabs = [
  {
    id: RequestStatusEnum["На рассмотрении"],
    name: "Запросы",
  },
  {
    id: RequestStatusEnum["Опубликовано"],
    name: "Опубликовано",
  },
];

export default AdminAppealsPage;

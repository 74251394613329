import React from 'react';

export const useEffectAfterMount = <T,>(cb: () => void, dependencies: T[]) => {
  const justMounted = React.useRef(true);
  React.useEffect(() => {
    if (!justMounted.current) {
      return cb();
    }
    justMounted.current = false;
  }, dependencies);
};

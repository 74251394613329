import React from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import { BaseContent, BaseLoader, BasePage } from "components/base";
import { OrganizationAdmin } from "content";
import { ProfileNonprofit } from "pages/private/profile/ProfileNonprofit";

import { useUsersControllerSetAvatarMutation } from "app/api/hooks/users";
import { ProfileUpdateNonprofiScheme } from "common/validation";
import { OnFormStateHandlerValue } from "pages/private/profile/Profile.types";
import { useOrganizationFields } from "hooks";
import {
  useOrganizationsControllerUploadDocumentsMutation,
  useOrganizationsControllerUpdateOneMutation,
  useOrganizationsControllerGetOneQuery,
  UpdateOneOrganizationRequestDto,
} from "app/api/hooks/organizations";
import { useWhatHelpTypes } from "hooks/useWhatHelpTypes";
import { useWhoHelpTypes } from "hooks/useWhoHelpTypes";
import { useCities } from "hooks/useCities";
import { useUploadDocuments } from "hooks/useUploadDocuments";
import { useUploadAvatar } from "hooks/useUploadAvatar";
import { em } from "common/funcs/em";

const UpdateOrganizationPage = () => {
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams();

  const organizationId = params?.id ? +params.id : 0;
  const onlyView = searchParams.get("mode") === "view";
  const title = onlyView ? "Просмотр организации" : "Обновить организацию";

  // State mutations
  const [updateOrganization] = useOrganizationsControllerUpdateOneMutation();
  const [uploadDocuments] = useOrganizationsControllerUploadDocumentsMutation();
  const [uploadAvatar] = useUsersControllerSetAvatarMutation();

  // Form initialize
  const form = useForm<UpdateOneOrganizationRequestDto>({
    resolver: yupResolver(ProfileUpdateNonprofiScheme),
    defaultValues,
  });

  // Form state
  const errors = form.formState.errors;
  const data = form.watch();

  // Form state handler
  const onFormStateHandler = React.useCallback(
    (
      key: keyof UpdateOneOrganizationRequestDto,
      value: OnFormStateHandlerValue,
    ) => {
      form.setValue(key, value);
    },
    [],
  );

  const {
    requestedDocuments,
    uploadedDocuments,
    onDeleteRequestedDocument,
    onSetRequestedDocuments,
    onDeleteUploadedDocument,
    onUploadDocument,
  } = useUploadDocuments({ organizationId });
  const { onUploadAvatar, uploadedAvatar } = useUploadAvatar();
  const { whatHelpTypesList } = useWhatHelpTypes();
  const { whoHelpTypesList } = useWhoHelpTypes();
  const { citiesList } = useCities();

  // Form state submit
  const onFormSubmitHandler = React.useCallback(
    async (
      updateOneOrganizationRequestDto: UpdateOneOrganizationRequestDto,
    ) => {
      if (onlyView || !organization) return;

      const formData = new FormData();

      try {
        setIsRequestLoading(true);

        const { userId } = await updateOrganization({
          id: organizationId,
          updateOneOrganizationRequestDto,
        }).unwrap();

        // If avatar was uploaded
        if (uploadedAvatar) {
          formData.set("avatar", uploadedAvatar as Blob, uploadedAvatar.name);

          await uploadAvatar({ id: userId, body: formData as any }).unwrap();

          formData.delete("avatar");
        }

        form.reset();

        toast.success("Информация об организации успешно обновлена");

        navigate(-1);
      } catch (e) {
        toast.error(em(e));
      } finally {
        setIsRequestLoading(false);
      }
    },
    [uploadedAvatar, uploadedDocuments],
  );

  // Organization info
  const { data: organization, isLoading } =
    useOrganizationsControllerGetOneQuery(
      { id: organizationId },
      { refetchOnMountOrArgChange: true },
    );

  // Hook set organization info to form state
  useOrganizationFields<UpdateOneOrganizationRequestDto>({
    organization,
    data,
    cbFv: (key, value) => {
      form.setValue(key, value);
    },
    cbDc: (documents) => {
      onSetRequestedDocuments(documents);
    },
  });

  const [requestedAvatar, setRequestedAvatar] = React.useState<
    string | undefined
  >();

  React.useMemo(() => {
    if (!organization) return;

    setRequestedAvatar(organization?.user?.avatar?.path);
  }, [organization]);

  if (isLoading) {
    return (
      <>
        <BasePage>
          <BaseLoader />
        </BasePage>
      </>
    );
  }

  return (
    <>
      <BaseContent>
        <form onSubmit={form.handleSubmit(onFormSubmitHandler)}>
          <OrganizationAdmin
            title={title}
            onlyView={onlyView}
            isLoading={isRequestLoading}
          >
            <ProfileNonprofit
              whatHelpTypesList={whatHelpTypesList}
              whoHelpTypesList={whoHelpTypesList}
              citiesList={citiesList}
              onDeleteRequestedDocument={onDeleteRequestedDocument(
                organizationId,
              )}
              onFormStateHandler={onFormStateHandler}
              onUploadAvatar={onUploadAvatar(false)}
              avatar={uploadedAvatar ?? requestedAvatar}
              errors={errors}
              data={data}
              isDisableFileds={onlyView}
              requestedDocuments={requestedDocuments}
              onUploadDocument={onUploadDocument}
              onDeleteUploadedDocument={onDeleteUploadedDocument}
              uploadedDocuments={uploadedDocuments}
            />
          </OrganizationAdmin>
        </form>
      </BaseContent>
    </>
  );
};

const defaultValues: UpdateOneOrganizationRequestDto = {
  lastName: "",
  firstName: "",
  INN: "",
  name: "",
  siteLink: "",
  email: "",
  phoneNumber1: "",
  phoneNumber2: "",
  organizationType: undefined,
  city: undefined,
  address: "",
  description: "",
  whatHelpTypes: [],
  whoHelpTypes: [],
  vkLink: undefined,
  okLink: undefined,
  isAcceptedCashPolicy: false,
  isAcceptedNoCashPolicy: false,
};

export default UpdateOrganizationPage;

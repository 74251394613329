import { apiSlice as api } from "../apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    mailingSubscriptionsControllerCreateOne: build.mutation<
      MailingSubscriptionsControllerCreateOneApiResponse,
      MailingSubscriptionsControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mailing-subscriptions`,
        method: "POST",
        body: queryArg.createOneSubscriptionDto,
      }),
    }),
    mailingWhomControllerGetAll: build.query<
      MailingWhomControllerGetAllApiResponse,
      MailingWhomControllerGetAllApiArg
    >({
      query: () => ({ url: `/api/v1/mailing-whom` }),
    }),
    mailingControllerGetAll: build.query<
      MailingControllerGetAllApiResponse,
      MailingControllerGetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mailing`,
        params: { page: queryArg.page, limit: queryArg.limit },
      }),
    }),
    mailingControllerCreateOne: build.mutation<
      MailingControllerCreateOneApiResponse,
      MailingControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mailing`,
        method: "POST",
        body: queryArg.createOneMailingRequestDto,
      }),
    }),
    mailingControllerGetOne: build.query<
      MailingControllerGetOneApiResponse,
      MailingControllerGetOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/mailing/${queryArg.id}` }),
    }),
    mailingControllerUpdateOne: build.mutation<
      MailingControllerUpdateOneApiResponse,
      MailingControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mailing/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateOneMailingRequestDto,
      }),
    }),
    mailingControllerDeleteOne: build.mutation<
      MailingControllerDeleteOneApiResponse,
      MailingControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mailing/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    mailingControllerResend: build.mutation<
      MailingControllerResendApiResponse,
      MailingControllerResendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mailing/${queryArg.id}/actions/resend`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type MailingSubscriptionsControllerCreateOneApiResponse =
  /** status 200  */ Subscriptions;
export type MailingSubscriptionsControllerCreateOneApiArg = {
  createOneSubscriptionDto: CreateOneSubscriptionDto;
};
export type MailingWhomControllerGetAllApiResponse =
  /** status 200  */ GetAllMailingWhomResponseDto[];
export type MailingWhomControllerGetAllApiArg = void;
export type MailingControllerGetAllApiResponse = /** status 200  */ any & {
  items?: Mailing[];
  totalPages?: number;
  count?: number;
};
export type MailingControllerGetAllApiArg = {
  page: number;
  limit: number;
};
export type MailingControllerCreateOneApiResponse =
  /** status 200  */ CreateOneMailingResponseDto;
export type MailingControllerCreateOneApiArg = {
  createOneMailingRequestDto: CreateOneMailingRequestDto;
};
export type MailingControllerGetOneApiResponse =
  /** status 200  */ GetOneMailingResponseDto;
export type MailingControllerGetOneApiArg = {
  id: number;
};
export type MailingControllerUpdateOneApiResponse =
  /** status 200  */ UpdateOneMailingResponseDto;
export type MailingControllerUpdateOneApiArg = {
  id: number;
  updateOneMailingRequestDto: UpdateOneMailingRequestDto;
};
export type MailingControllerDeleteOneApiResponse =
  /** status 200  */ DeleteOneMailingResponseDto;
export type MailingControllerDeleteOneApiArg = {
  id: number;
};
export type MailingControllerResendApiResponse = unknown;
export type MailingControllerResendApiArg = {
  id: number;
};
export type Mailing = {
  id: number;
  text: string;
  isSendToEmail: boolean;
  isSendToPhone: boolean;
  createdAt: string;
  updatedAt: string;
  mailingWhomId: number;
  mailingWhom: MailingWhom;
};
export type MailingWhom = {
  id: number;
  name: string;
  mailing: Mailing[];
};
export type Subscriptions = {
  id: number;
  email: string;
  createdAt: string;
  updatedAt: string;
  mailingWhomId: number;
  mailingWhom: MailingWhom;
};
export type CreateOneSubscriptionDto = {
  email: string;
};
export type GetAllMailingWhomResponseDto = {
  id: number;
  name: string;
  mailing: Mailing[];
};
export type CreateOneMailingResponseDto = {
  id: number;
  text: string;
  isSendToEmail: boolean;
  isSendToPhone: boolean;
  createdAt: string;
  updatedAt: string;
  mailingWhomId: number;
  mailingWhom: MailingWhom;
};
export type OptionDto = {
  id: number;
  name: string;
};
export type CreateOneMailingRequestDto = {
  text: string;
  mailingWhom: OptionDto;
  isSendToEmail: boolean;
  isSendToPhone: boolean;
};
export type GetOneMailingResponseDto = {
  id: number;
  text: string;
  isSendToEmail: boolean;
  isSendToPhone: boolean;
  createdAt: string;
  updatedAt: string;
  mailingWhomId: number;
  mailingWhom: MailingWhom;
};
export type UpdateOneMailingResponseDto = {
  id: number;
  text: string;
  isSendToEmail: boolean;
  isSendToPhone: boolean;
  createdAt: string;
  updatedAt: string;
  mailingWhomId: number;
  mailingWhom: MailingWhom;
};
export type UpdateOneMailingRequestDto = {
  text?: string;
  mailingWhom: OptionDto;
  isSendToEmail?: boolean;
  isSendToPhone?: boolean;
};
export type DeleteOneMailingResponseDto = {
  id: number;
  text: string;
  isSendToEmail: boolean;
  isSendToPhone: boolean;
  createdAt: string;
  updatedAt: string;
  mailingWhomId: number;
  mailingWhom: MailingWhom;
};
export const {
  useMailingSubscriptionsControllerCreateOneMutation,
  useMailingWhomControllerGetAllQuery,
  useMailingControllerGetAllQuery,
  useMailingControllerCreateOneMutation,
  useMailingControllerGetOneQuery,
  useMailingControllerUpdateOneMutation,
  useMailingControllerDeleteOneMutation,
  useMailingControllerResendMutation,
} = injectedRtkApi;

import { mcn } from 'common/funcs/mcn.func';

import styles from './BasePage.module.scss';

type PropsType = {
  className?: string;
  children: React.ReactNode;
};

const BasePage = ({ className = '', children }: PropsType) => {
  return (
    <>
      <main className={mcn([styles.BasePage, className])}>
        {children}
      </main>;
    </>
  )
};

export default BasePage;

import styles from "./ProfileWaiting.module.scss";

const ProfileWaitingPage = () => {
  return (
    <>
      <div className={styles.ProfileWaitingPage}>
        <p className={styles.ProfileWaitingPage__text}>
          Ваша заявка рассматривается, оповещение с решением придёт на указаный при регистрации почтовый адрес.
        </p>
      </div>
    </>
  )
}

export default ProfileWaitingPage;

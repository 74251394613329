import styles from "./ProfileReject.module.scss";

const ProfileRejectPage = () => {
  return (
    <>
      <div className={styles.ProfileRejectPage}>
        <p className={styles.ProfileRejectPage__text}>
          Ваша заявка была отклонена, вы можете подать новую, пройдя повторную регистрацию
        </p>
      </div>
    </>
  )
}

export default ProfileRejectPage;

import { SvgIcon, ThemeOptions } from '@mui/material';

export const MuiCheckbox: ThemeOptions['components'] = {
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
      icon: (
        <>
          <SvgIcon width="20" height="18" viewBox="0 0 20 18" fill="none">
            <rect
              x="1.65271"
              y="0.75"
              width="17.1018"
              height="16.5"
              rx="1.25"
              fill="white"
              stroke="#B6B4BF"
              strokeWidth="1.5"
            />
          </SvgIcon>
        </>
      ),
      checkedIcon: (
        <>
          <SvgIcon width="20" height="18" viewBox="0 0 20 18" fill="none">
            <rect
              x="1.65271"
              y="0.75"
              width="17.1018"
              height="16.5"
              rx="1.25"
              fill="white"
              stroke="#B6B4BF"
              strokeWidth="1.5"
            />
            <path
              d="M14.0952 5.29289C14.5087 4.90237 15.1791 4.90237 15.5926 5.29289C16.0061 5.68342 16.0061 6.31658 15.5926 6.70711L9.23965 12.7071C8.82615 13.0976 8.15574 13.0976 7.74224 12.7071L4.21283 9.37377C3.79934 8.98325 3.79934 8.35008 4.21283 7.95956C4.62633 7.56904 5.29674 7.56904 5.71024 7.95956L8.49095 10.5858L14.0952 5.29289Z"
              fill="#ED5338"
            />
          </SvgIcon>
        </>
      ),
    },
    styleOverrides: {
      root: {
        '& .checked': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
  },
};

import { ThemeProvider as Theme } from '@mui/material/styles';
import { theme } from 'common/config/mui-theme.config';

type PropsType = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: PropsType) => {
  return <Theme theme={theme}>{children}</Theme>;
};

export default ThemeProvider;

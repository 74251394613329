import { Route, Routes } from 'react-router';

import { SignoutPage } from 'pages/private';
import { Default } from 'layouts';

const SignoutRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Default />}>
          <Route path="signout" element={<SignoutPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default SignoutRoutes;

import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { delCredentials, setCredentials } from 'app/authSlice';
import { AppState } from '../store';

export const baseUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_BASE_URL_DEV
  : process.env.REACT_APP_BASE_URL;

const baseQuery = fetchBaseQuery({
  /**
   * Base url settings section
   */
  baseUrl,

  /**
   * Headers settings section
   */
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as AppState).auth.accessToken;

    // If we have a accessToken set in state, let's assume that we should be passing it.
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },

  /**
   * Include credentials, that cookies returns
   */
  credentials: 'include',
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    // Send refresh token to get new access token
    const refreshResult = await baseQuery('/refresh', api, extraOptions);

    if (refreshResult?.data) {
      const user = (api.getState() as AppState).auth.user;

      // Store the new token
      api.dispatch(setCredentials({ ...refreshResult.data, user }));
      // Retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(delCredentials({}));
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api/v1',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

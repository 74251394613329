import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import React from "react";
import toast from "react-hot-toast/headless";

import {
  BaseOutlinedInput,
  BaseButton,
  BaseContainer,
  BaseInputError,
} from "components/base";

import { useMailingSubscriptionsControllerCreateOneMutation } from "app/api/hooks/mailing-subscriptions";
import { MailingSubscriptionScheme } from "common/validation";
import { CreateOneSubscriptionDto } from "app/api/hooks/mailing";
import { em } from "common/funcs/em";
import styles from "./MailingSubscription.module.scss";

const MailingSubscription = () => {
  const form = useForm<CreateOneSubscriptionDto>({
    resolver: yupResolver(MailingSubscriptionScheme),
    defaultValues: {
      email: "",
    },
  });

  const [createSubscription] =
    useMailingSubscriptionsControllerCreateOneMutation();

  const onFormStateSubmit = async (
    createOneSubscriptionDto: CreateOneSubscriptionDto,
  ) => {
    try {
      await createSubscription({ createOneSubscriptionDto }).unwrap();
      toast.success("Вы успешно подписались на рассылку");
      form.reset();
    } catch (e) {
      toast.error(em(e));
    }
  };

  const errors = form.formState.errors;
  const data = form.watch();

  return (
    <>
      <section className={styles.MailingSubscription}>
        <BaseContainer className={styles.MailingSubscription__inner}>
          <h3 className={styles.MailingSubscription__title}>
            Подписывайтесь на нашу рассылку!
          </h3>
          <div className={styles.MailingSubscription__description}>
            <p className={styles.MailingSubscription__text}>
              Будьте в курсе последних новостей!
            </p>
            <p className={styles.MailingSubscription__text}>
              Получайте уведомления о выгодных предложениях банков
            </p>
          </div>
          <form
            className={styles.MailingSubscription__mailing}
            onSubmit={form.handleSubmit(onFormStateSubmit)}
          >
            <MemoizedMailingSubscriptionInput
              onChange={(text) => {
                form.setValue("email", text.trim(), {
                  shouldValidate: true,
                });
              }}
              value={data.email}
            />
            <BaseInputError message={errors["email"]?.message} />
          </form>
        </BaseContainer>
      </section>
    </>
  );
};

type PropsType = {
  onChange: (text: string) => void;
  value: string;
};

const MailingSubscriptionInput = ({ onChange, value }: PropsType) => {
  return (
    <>
      <div className={styles.MailingSubscriptionInput}>
        <BaseOutlinedInput
          className={styles.MailingSubscriptionInput__field}
          onChange={onChange}
          placeholder="Электронная почта"
          variant="subscription"
          value={value}
          type="text"
        />
        <BaseButton
          className={styles.MailingSubscriptionInput__button}
          variant="main_contained_tangerine"
          type="submit"
        >
          Подписаться
        </BaseButton>
      </div>
    </>
  );
};

const MemoizedMailingSubscriptionInput = React.memo(MailingSubscriptionInput);

export default MailingSubscription;

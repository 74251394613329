import React from "react";

import {
  useOrganizationsControllerDeleteDocumentMutation,
  useOrganizationsControllerUploadDocumentMutation,
  Multimedia,
} from "app/api/hooks/organizations";

type PropsType = {
  organizationId?: number;
};

export function useUploadDocuments({ organizationId }: PropsType) {
  // Mutations of the state
  const [uploadDocument] = useOrganizationsControllerUploadDocumentMutation();
  const [deleteDocument] = useOrganizationsControllerDeleteDocumentMutation();

  // Files state
  const [requestedDocuments, setRequestedDocuments] = React.useState<
    Array<Multimedia>
  >([]);
  const [uploadedDocuments, setUploadedDocuments] = React.useState<Array<File>>(
    [],
  );

  console.log("REQUESTED DOCS: ", requestedDocuments);
  console.log("UPLOADED DOCS: ", uploadedDocuments);

  // Files hadnlers
  const onDeleteRequestedDocument =
    (organizationId?: number) => async (multimediaId: number) => {
      setRequestedDocuments((multimedia) =>
        [...multimedia].filter((m) => m.id !== multimediaId),
      );

      if (!organizationId) return;

      await deleteDocument({
        id: organizationId,
        deleteOrganizationDocumentRequestDto: {
          multimediaId,
        },
      }).unwrap();
    };

  const onSetRequestedDocuments = (newMultimedia: Array<Multimedia>) => {
    setRequestedDocuments(newMultimedia);
  };

  const onDeleteUploadedDocument = (index: number) => {
    setUploadedDocuments((files) => [...files].filter((_, i) => i !== index));
  };

  const onUploadDocument = async (file: File) => {
    setUploadedDocuments((files) => [...files, file]);

    if (organizationId) {
      const formData = new FormData();

      try {
        formData.set("document", file as Blob, encodeURI(file.name));

        await uploadDocument({
          id: organizationId,
          body: formData as any,
        }).unwrap();

        formData.delete("document");
      } catch (e) {
        console.error(e);
      }
    }
  };

  return {
    requestedDocuments,
    uploadedDocuments,
    onDeleteRequestedDocument,
    onSetRequestedDocuments,
    onDeleteUploadedDocument,
    onUploadDocument,
  };
}

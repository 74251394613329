import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";

import { BaseLoader } from "..";

import styles from "./BaseModal.module.scss";
import { mcn } from "common/funcs/mcn.func";

type PropsType = {
  classNameBody?: string;
  children: React.ReactNode;
  title: string;
  isLoading?: boolean;
  isActive?: boolean;
  isPage?: boolean;
  onToggle?: () => void;
};

const BaseModal = ({
  classNameBody = "",
  children,
  title,
  isLoading,
  isActive = false,
  isPage = true,
  onToggle = () => {},
}: PropsType) => {
  const navigation = useNavigate();
  return (
    <>
      <Modal
        aria-describedby="modal-modal-description"
        aria-labelledby="modal-modal-title"
        open={isPage ? true : isActive}
      >
        <Box className={styles.BaseModal}>
          <Box className={styles.BaseModal__header}>
            <h2 className={styles.BaseModal__title}>{title}</h2>
            <CloseIcon
              className={styles.BaseModal__icon}
              onClick={() => (isPage ? navigation(-1) : onToggle())}
            />
          </Box>
          <Box
            className={mcn([
              styles.BaseModal__body,
              isLoading ? styles.BaseModal__loader : "",
              classNameBody,
            ])}
          >
            {isLoading ? <BaseLoader /> : children}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BaseModal;

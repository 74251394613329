import React from "react";

import {
  BaseCheckbox,
  BaseInputError,
  BaseLabel,
  BasePhoneInput,
  BaseTextarea,
  BaseTextField,
  BaseTypography,
} from "components/base";
import { Documents, ImageUploader, Select } from "content";

import { CreateOneOrganizationRequestDto } from "app/api/hooks/organizations";
import { ProfileNestedPropsType } from "../Profile.types";
import { isSelectedOption } from "common/funcs/is-selected-option.func";
import { OptionType } from "common/types/option.type";
import styles from "./ProfileNonprofit.module.scss";

const ProfileNonprofit = (
  props: ProfileNestedPropsType<CreateOneOrganizationRequestDto>,
) => {
  return (
    <>
      <ul className={styles.ProfileNonprofit}>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.name}
            label="Название"
            name="name"
            onChange={(e) => props.onFormStateHandler("name", e.target.value)}
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["name"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.siteLink}
            label="Сайт"
            name="siteLink"
            onChange={(e) =>
              props.onFormStateHandler("siteLink", e.target.value)
            }
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["siteLink"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.INN}
            label="ИНН"
            name="INN"
            onChange={(e) => props.onFormStateHandler("INN", e.target.value)}
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["INN"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.email}
            label="Эл. почта"
            name="email"
            onChange={(e) => props.onFormStateHandler("email", e.target.value)}
            disabled={props.isDisableEmail ?? true}
          />
          <BaseInputError message={props.errors["email"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.organizationType?.name}
            label="Тип организации"
            name="organizationType"
            disabled={true}
          />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BasePhoneInput
            value={props.data.phoneNumber1}
            label="Телефон 1"
            name="phoneNumber1"
            onChange={(value) =>
              typeof value === "string" &&
              props.onFormStateHandler("phoneNumber1", value)
            }
            type="tel"
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["phoneNumber1"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <Select
            onChange={(selected) => {
              if (props.isDisableFileds) return;
              if (Array.isArray(selected)) return;

              props.onFormStateHandler("city", selected);
            }}
            multiple={false}
            value={props.data.city}
            variant="select"
            label="Город"
          >
            <Select.OptionList>
              {props.citiesList.map(
                (item: OptionType) =>
                  item.id !== 0 && (
                    <Select.OptionItem
                      selected={props.data?.city?.id === item.id}
                      key={item.id}
                      {...item}
                    />
                  ),
              )}
            </Select.OptionList>
          </Select>
          <BaseInputError message={props.errors["city"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BasePhoneInput
            value={props.data.phoneNumber2}
            label="Телефон 2"
            name="phoneNumber2"
            onChange={(value) =>
              typeof value === "string" &&
              props.onFormStateHandler("phoneNumber2", value)
            }
            type="tel"
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["phoneNumber2"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.vkLink}
            label="Ссылка Вконтакте"
            name="vkLink"
            onChange={(e) => props.onFormStateHandler("vkLink", e.target.value)}
            type="text"
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["vkLink"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.okLink}
            label="Ссылка Одноклассники"
            name="okLink"
            onChange={(e) => props.onFormStateHandler("okLink", e.target.value)}
            type="text"
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["okLink"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.address}
            label="Адрес"
            name="address"
            onChange={(e) =>
              props.onFormStateHandler("address", e.target.value)
            }
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["address"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <ImageUploader
            onChange={(file) => props.onUploadAvatar(file)}
            value={props.avatar as File | string}
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["avatar"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTypography variant="h2" type="ps">
            Контактное лицо
          </BaseTypography>
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.lastName}
            label="Фамилия"
            name="lastName"
            onChange={(e) =>
              props.onFormStateHandler("lastName", e.target.value)
            }
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["lastName"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextField
            value={props.data.firstName}
            label="Имя"
            name="firstName"
            onChange={(e) =>
              props.onFormStateHandler("firstName", e.target.value)
            }
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["firstName"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <Select
            onChange={(selected) => {
              if (props.isDisableFileds) return;
              props.onFormStateHandler("whatHelpTypes", selected);
            }}
            multiple={true}
            value={props.data.whatHelpTypes}
            label="Чем занимается"
            variant="checkbox"
            noClose={true}
          >
            <Select.CheckboxList>
              {props.whatHelpTypesList?.map(
                (item) =>
                  item.id !== 0 && (
                    <Select.CheckboxItem
                      selected={isSelectedOption(
                        props.data.whatHelpTypes,
                        item.id,
                      )}
                      key={item.id}
                      {...item}
                    />
                  ),
              )}
            </Select.CheckboxList>
          </Select>
          <BaseInputError message={props.errors["whatHelpTypes"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <Select
            onChange={(selected) => {
              if (props.isDisableFileds) return;
              props.onFormStateHandler("whoHelpTypes", selected);
            }}
            value={props.data.whoHelpTypes}
            label="Сфера деятельности"
            multiple={true}
            variant="select"
          >
            <Select.OptionList>
              {props.whoHelpTypesList?.map(
                (item) =>
                  item.id !== 0 && (
                    <Select.OptionItem
                      selected={isSelectedOption(
                        props.data.whoHelpTypes,
                        item.id,
                      )}
                      key={item.id}
                      {...item}
                    />
                  ),
              )}
            </Select.OptionList>
          </Select>
          <BaseInputError message={props.errors["whoHelpTypes"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseTextarea
            value={props.data.description}
            onChange={(e) =>
              props.onFormStateHandler("description", e.target.value)
            }
            label="Миссия и опыт деятельности (ограничение 3000 символов)"
            disabled={props.isDisableFileds}
          />
          <BaseInputError message={props.errors["description"]?.message} />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <Documents
            onDeleteRequestedDocument={props.onDeleteRequestedDocument}
            onDeleteUploadedDocument={props.onDeleteUploadedDocument}
            onUploadDocument={props.onUploadDocument}
            requestedDocuments={props.requestedDocuments}
            uploadedDocuments={props.uploadedDocuments}
            disabled={props.isDisableFileds}
          />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseLabel
            className={styles.ProfileNonprofit__policy_label}
            isDisabled={props.isAccepted}
            label="Подтверждаю, что НКО осуществляет сбор безналичных средств только на официальный банковский счет организации."
          >
            <BaseCheckbox
              onChange={(e) =>
                props.onFormStateHandler(
                  "isAcceptedNoCashPolicy",
                  e.target.checked,
                )
              }
              checked={props.data.isAcceptedNoCashPolicy}
              value="isAcceptedNoCashPolicy"
              disabled={props.isAccepted || props.isDisableFileds}
            />
          </BaseLabel>
          <BaseInputError
            message={props.errors["isAcceptedNoCashPolicy"]?.message}
          />
        </li>
        <li className={styles.ProfileNonprofit__item}>
          <BaseLabel
            className={styles.ProfileNonprofit__policy_label}
            isDisabled={props.isAccepted}
            label="Подтверждаю, что НКО собирает наличные средства только в оформленные по закону и опломбированные ящики-копилки."
          >
            <BaseCheckbox
              onChange={(e) =>
                props.onFormStateHandler(
                  "isAcceptedCashPolicy",
                  e.target.checked,
                )
              }
              checked={props.data.isAcceptedCashPolicy}
              value="isAcceptedCashPolicy"
              disabled={props.isAccepted || props.isDisableFileds}
            />
          </BaseLabel>
          <BaseInputError
            message={props.errors["isAcceptedCashPolicy"]?.message}
          />
        </li>
      </ul>
    </>
  );
};

const MemoizedProfileNonprofit = React.memo(
  ProfileNonprofit,
) as typeof ProfileNonprofit;

export default MemoizedProfileNonprofit;

import React from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import ClearIcon from "@mui/icons-material/Clear";

import {
  OnDeleteRequestedDocumentType,
  OnDeleteUploadedDocumentType,
  OnUploadDocumentType,
} from "pages/private/profile/Profile.types";
import { Multimedia } from "app/api/hooks/organizations";
import { baseUrl } from "app/api/apiSlice";
import { mcn } from "common/funcs/mcn.func";
import styles from "./Documents.module.scss";

type PropsType = {
  className?: string;
  disabled?: boolean;
  onDeleteRequestedDocument: OnDeleteRequestedDocumentType;
  onDeleteUploadedDocument: OnDeleteUploadedDocumentType;
  onUploadDocument: OnUploadDocumentType;
  requestedDocuments: Array<Multimedia>;
  uploadedDocuments: Array<File>;
};

const Documents = ({ className = "", ...rest }: PropsType) => {
  const isDev = process.env.NODE_ENV === "development";
  return (
    <>
      <div className={mcn([styles.Documents, className])}>
        <div className={styles.Documents__inner}>
          <div className={styles.Documents__label}>
            Список документов ( в формате PDF, не более 10 мб)
          </div>
          <ul className={styles.Documents__list}>
            {/* LIST DOCUMENTS THAT YOU NEED TO UPLOAD */}
            {documentList?.map((documentItem, key) => (
              <li className={styles.Documents__item} key={key}>
                {documentItem}
              </li>
            ))}

            {/* LIST REQUESTED DOCUMENTS FROM DB */}
            {rest?.requestedDocuments?.map(
              ({ id, fileName, originalFileName }) => (
                <li className={styles.Documents__upload} key={id}>
                  <a
                    className={styles.Documents__name}
                    href={
                      isDev
                        ? baseUrl + `/files/${fileName}`
                        : `/files/${fileName}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {originalFileName}
                  </a>
                  <a
                    href={
                      isDev
                        ? baseUrl + `/api/v1/downloads/${fileName}`
                        : `/api/v1/downloads/${fileName}`
                    }
                    download={true}
                  >
                    <GetAppIcon className={styles.Documents__icon} />
                  </a>
                  <ClearIcon
                    className={styles.Documents__icon}
                    onClick={async () =>
                      !rest.disabled &&
                      (await rest.onDeleteRequestedDocument(id))
                    }
                  />
                </li>
              ),
            )}

            {/* LIST UPLOADED DOCUMENTS FROM STATE */}
            {rest?.uploadedDocuments?.map((file, key) => (
              <li className={styles.Documents__upload} key={key}>
                <span className={styles.Documents__name}>{file?.name}</span>
                <ClearIcon
                  className={styles.Documents__icon}
                  onClick={async () =>
                    !rest.disabled && (await rest.onDeleteUploadedDocument(key))
                  }
                />
              </li>
            ))}

            {/* ADD BUTTON */}
            <li className={styles.Documents__item}>
              <label className={styles.Documents__button_upload}>
                <input
                  className={styles.Documents__input_upload}
                  onChange={async (e) => {
                    if (e.target.files && e.target.files[0]) {
                      await rest.onUploadDocument(e.target.files[0]);
                    }
                  }}
                  disabled={rest.disabled}
                  accept="application/pdf"
                  type="file"
                />
                + Добавить документ
              </label>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const documentList = [
  "Устав организации",
  "Обязательная отчетность в Минюст РФ",
  "Публичная годовая отчетность ",
  "Свидетельство о государственной регистрации некоммерческой организации",
  "Свидетельство о постановке на учет в налоговом органе",
];

const MemoizedDocuments = React.memo(Documents);

export default MemoizedDocuments;

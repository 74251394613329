import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material';
import React from 'react';

const StyledTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    '& > label': {
      fontSize: '1.4rem !important',
    },
    '& > .MuiOutlinedInput-root > fieldset > legend': {
      fontSize: '1.1 !important',
    },
  },
}));

const BaseTextField = (props: TextFieldProps) => {
  return (
    <>
      <StyledTextField {...props} autoComplete={"off"} />
    </>
  );
};

const MemoizedBaseTextField = React.memo(BaseTextField, (prevProps, nextProps) => prevProps?.value === nextProps?.value)

export default MemoizedBaseTextField;

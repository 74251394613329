import { Route, Routes } from 'react-router';

import { Default } from 'layouts';
import { NotFoundPage, Unauthorized } from 'pages/public';
import {
  AboutPage,
  MaterialPage,
  MaterialsPage,
  RequestsPage,
} from 'pages/public';

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Default subscription={true} />}>
          <Route path="" element={<AboutPage />} />
          <Route path="requests" element={<RequestsPage />} />
          <Route path="materials" element={<MaterialsPage />} />
          <Route path="materials/:id" element={<MaterialPage />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default PublicRoutes;

import { mcn } from 'common/funcs/mcn.func';
import { useFormTabsContext } from '../_hooks';

import styles from './FormTab.module.scss';

type PropsType = {
  value: string | number;
  label: string;
};

export const FormTab = ({ label, value }: PropsType) => {
  const { name, selectedValue, onChangeHandler } = useFormTabsContext();
  const isChecked = selectedValue === label;
  return (
    <li className={styles.FormTab}>
      <label
        className={mcn([
          styles.FormTab__label,
          isChecked ? styles.FormTab_active : '',
        ])}
      >
        <span className={styles.FormTab__name}>{label}</span>
        <input
          className={styles.FormTab__input}
          onChange={onChangeHandler}
          checked={selectedValue === label}
          value={value}
          name={name}
          type="radio"
          data-label={label}
        />
      </label>
    </li>
  );
};

import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import { BaseButton, BaseLink, BasePageForm } from "components/base";
import { FormCard, FormCode, FormInput } from "content";

import {
  RestorePasswordRequestDto,
  useUsersControllerRestorePasswordMutation,
  useUsersControllerSendConfirmationCodeMutation,
} from "app/api/hooks/users";
import { ForgotPasswordScheme } from "common/validation";
import { em } from "common/funcs/em";
import styles from "./RestorePassword.module.scss";

const RestorePasswordPage = () => {
  // Form initialization
  const form = useForm<RestorePasswordRequestDto>({
    defaultValues: {
      password: "",
      email: "",
      confirmationCode: "",
    },
    resolver: yupResolver(ForgotPasswordScheme),
  });

  // Form state
  const formState = form.watch();
  const errors = form.formState.errors;

  // Form handler
  const onHandleState = React.useCallback(
    (key: keyof RestorePasswordRequestDto, value: string) => {
      form.setValue(key, value.trim(), {
        shouldValidate: true,
      });
    },
    [],
  );

  // Hooks
  const [sendConfirmationCode] =
    useUsersControllerSendConfirmationCodeMutation();
  const [restorePassword] = useUsersControllerRestorePasswordMutation();
  const [isEmailConfirmed, setIsEmailConfirmed] = React.useState(false);
  const navigation = useNavigate();

  // Form submit handler
  const onSubmitHandler = async (
    restorePasswordRequestDto: RestorePasswordRequestDto,
  ) => {
    try {
      await restorePassword({ restorePasswordRequestDto }).unwrap();

      navigation("/signon");
    } catch (e: any) {
      toast.error(em(e));
    }
  };

  // Confirmation code submit handler
  const onSubmitConfirmationCode = async () => {
    try {
      if (formState.email.trim().length === 0) {
        form.setError("email", {
          message: "Заполните email",
        });

        return;
      }

      await sendConfirmationCode({
        sendConfirmationCodeRequestDto: {
          email: formState.email,
          isRestore: true,
        },
      }).unwrap();

      setIsEmailConfirmed(true);
    } catch (e: any) {
      toast.error(em(e));
    }
  };

  return (
    <>
      <BasePageForm>
        <FormCard<RestorePasswordRequestDto>>
          <form onSubmit={form.handleSubmit(onSubmitHandler)}>
            <FormCard.Row>
              <FormCard.Title>Забыли пароль?</FormCard.Title>
            </FormCard.Row>
            <FormCard.Row marginTop="1.1rem">
              <FormCard.Subtitle>
                Укажите электронную почту указанную при регистрации
              </FormCard.Subtitle>
            </FormCard.Row>
            <FormCard.Row marginTop="2.3rem">
              <FormInput
                placeholder="Email"
                errorMessage={errors["email"]?.message}
                onChange={(text) => {
                  onHandleState("email", text);
                }}
                value={formState.email?.trim()}
                type="text"
              />
            </FormCard.Row>
            <FormCard.Row marginTop="2rem">
              <FormCode
                placeholder="Код"
                errorMessage={errors["confirmationCode"]?.message}
                onChange={(text) => {
                  onHandleState("confirmationCode", text);
                }}
                onClick={() => {
                  onSubmitConfirmationCode();
                }}
                value={formState.confirmationCode}
              />
            </FormCard.Row>
            {isEmailConfirmed ? (
              <>
                <FormCard.Row marginTop="2rem">
                  <FormInput
                    placeholder="Пароль"
                    errorMessage={errors["password"]?.message}
                    onChange={(text) => {
                      onHandleState("password", text);
                    }}
                    value={formState.password}
                    type="password"
                  />
                </FormCard.Row>
              </>
            ) : null}
            <FormCard.Row marginTop="4rem">
              <BaseButton
                fullWidth={true}
                variant="main_contained_tangerine"
                type="submit"
              >
                Сохранить пароль
              </BaseButton>
            </FormCard.Row>
            <FormCard.Row marginTop="3.2rem">
              <BaseLink
                className={styles.RestorePasswordPage__go_back}
                variant="form"
                to="/signon"
              >
                <ArrowBackIcon />
                Вернуться к авторизации
              </BaseLink>
            </FormCard.Row>
          </form>
        </FormCard>
      </BasePageForm>
    </>
  );
};

export default RestorePasswordPage;

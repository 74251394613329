import { Route, Routes } from 'react-router';

import { Default } from 'layouts';
import {
  SignupPage,
  SignupNeedHelpPage,
  SignupWantHelpPage,
} from 'pages/public';

const SignupRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Default subscription={false} />}>
          <Route path="" element={<SignupPage />} />
          <Route path="need-help" element={<SignupNeedHelpPage />} />
          <Route path="want-help" element={<SignupWantHelpPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default SignupRoutes;

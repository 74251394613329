import React from 'react';

import { useSelectContext } from '../_context';
import { OptionType } from 'common/types/option.type';
import { mcn } from 'common/funcs/mcn.func';
import styles from './OptionItem.module.scss';

type PropsType = OptionType & { selected: boolean };

const OptionItem = ({ id, name, selected }: PropsType) => {

  const { onChangeHandler } = useSelectContext();

  return (
    <>
      <li
        className={mcn([styles.OptionItem, selected ? styles.OptionItem_active : ""])}
        onClick={(e) => onChangeHandler({ id, name })}
      >
        {name}
      </li>
    </>
  )
}

const MemoizedOptionItemn = React.memo(OptionItem);

export default MemoizedOptionItemn;

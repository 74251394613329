import React from "react";
import { useNavigate } from "react-router";

import {
  BaseTypography,
  BaseButton,
  BaseGoBack,
  BaseLoader,
} from "components/base";

import styles from "./OrganizationAdmin.module.scss";

type PropsType = {
  children: React.ReactNode;
  onlyView: boolean;
  title: string;
  isLoading?: boolean;
}

const OrganizationAdmin = ({ children, onlyView, title, isLoading = false }: PropsType) => {
  const navigate = useNavigate();
  return (
    <>
      <section className={styles.OrganizationAdmin}>
        <ul className={styles.OrganizationAdmin__header}>
          <li className={styles.OrganizationAdmin__button}>
            <BaseGoBack text="Назад к списку НКО" to="/admin/requests" />
          </li>
          <li className={styles.OrganizationAdmin__title}>
            <BaseTypography variant="h2" type="at">
              {title}
            </BaseTypography>
          </li>
        </ul>
        <div className={styles.OrganizationAdmin__content}>
          {children}
        </div>
        <div className={styles.OrganizationAdmin__buttons} hidden={isLoading || onlyView}>
          <BaseButton
            className={styles.OrganizationAdmin__button}
            variant="main_contained_tangerine"
            type="submit"
          >
            Сохранить
          </BaseButton>
          <BaseButton
            className={styles.OrganizationAdmin__button}
            variant="main_outlined_tangerine"
            onClick={() => navigate("/admin/requests")}
          >
            Отмена
          </BaseButton>
        </div>
        <div className={styles.OrganizationAdmin__loader}>
          {isLoading && <BaseLoader />}
        </div>
      </section>
    </>
  );
};

const MemoizedOrganizationAdmin = React.memo(OrganizationAdmin);

export default MemoizedOrganizationAdmin;

import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSearchParams } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";

import { Carousel, Tags } from "content";
import {
  BaseButton,
  BaseContent,
  BaseDate,
  BaseGoBack,
  BaseImage,
  BaseLink,
  BaseLoader,
  BasePage,
} from "components/base";

import {
  useMaterialsControllerDeleteOneMutation,
  useMaterialsControllerGetOneQuery,
} from "app/api/hooks/materials";
import { useImagesFilters, useWindowSize } from "hooks";
import { selectUser } from "app/authSlice";
import { mcn } from "common/funcs/mcn.func";
import imageAuthor from "common/images/w1440/image-material-author-w1440.webp";
import styles from "./Material.module.scss";

const MaterialPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const query = useSearchParams();
  const user = useSelector(selectUser);

  // Material id from params
  const materialId = params?.id ? +params.id : 0;

  // Order of sort
  const categoryId = query[0].get("categoryId") ?? undefined;
  const createdAt = query[0].get("createdAt") ?? "desc";
  const authorId = query[0].get("authorId") ?? undefined;

  // Request to material
  const { data, isLoading } = useMaterialsControllerGetOneQuery({
    id: materialId,
    categoryId,
    createdAt,
    authorId,
  });

  const prevPage = (data?.pages as PagesType)?.prevPage;
  const nextPage = (data?.pages as PagesType)?.nextPage;

  const { width } = useWindowSize();
  const images = useImagesFilters(width, data?.result?.multimedia);

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, [location.pathname]);

  const isAdmin = user?.role === "ADMIN";

  const [deleteMaterial] = useMaterialsControllerDeleteOneMutation();

  const handleDeleteMaterial = async (id: number) => {
    try {
      await deleteMaterial({ id }).unwrap();
      navigate("/materials");
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoading) {
    return (
      <BasePage className={styles.MaterialPage}>
        <BaseContent className={styles.MaterialPage__inner}>
          <BaseLoader />
        </BaseContent>
      </BasePage>
    );
  }

  return (
    <>
      <BasePage>
        <BaseContent>
          <article className={styles.MaterialPage}>
            <div className={styles.MaterialPage__inner}>
              <div className={styles.MaterialPage__header}>
                <div className={styles.MaterialPage__header_top}>
                  <div className={styles.MaterialPage__header_go_back}>
                    <BaseGoBack
                      to={`/materials?createdAt=${createdAt}&authorId=${authorId}&categoryId=${categoryId}`}
                    />
                  </div>
                  <ul className={styles.MaterialPage__information}>
                    <li className={styles.MaterialPage__information_item}>
                      <BaseDate
                        value={
                          data?.result?.createdAt ?? new Date().toISOString()
                        }
                        variant="material"
                      />
                    </li>
                    <li className={styles.MaterialPage__information_item}>
                      <Tags
                        items={data?.result?.whoHelpTypes}
                        variant={"preview"}
                      />
                    </li>
                  </ul>
                </div>
                <div className={styles.MaterialPage__header_bot}>
                  <h2 className={styles.MaterialPage__title}>
                    {data?.result?.title}
                  </h2>
                  <ul className={styles.MaterialPage__list}>
                    <li className={styles.MaterialPage__item}>
                      <BaseImage
                        className={styles.MaterialPage__author_avatar}
                        src={imageAuthor}
                        height="40"
                        width="40"
                        alt="автор"
                      />
                      <p className={styles.MaterialPage__author_name}>
                        {data?.result?.author?.name}
                      </p>
                    </li>
                    <li className={styles.MaterialPage__item}>
                      <BaseLink
                        className={styles.MaterialPage__author_all_article_link}
                        variant="material"
                        to={`/materials?createdAt=${createdAt}&authorId=${data?.result?.authorId}`}
                      >
                        Все статьи автора
                      </BaseLink>
                    </li>
                  </ul>
                </div>
              </div>

              <ul className={styles.MaterialPage__body}>
                <li className={styles.MaterialPage__body_item}>
                  <Carousel images={images} sign={data?.result?.sign} />
                </li>
                <li className={styles.MaterialPage__body_item}>
                  <p className={styles.MaterialPage__body_description}>
                    {data?.result?.text}
                  </p>
                </li>
              </ul>

              <ul className={styles.MaterialPage__footer}>
                <li
                  className={styles.MaterialPage__footer_item}
                  hidden={!prevPage}
                >
                  <BaseLink
                    className={styles.MaterialPage__footer_arrow}
                    to={`/materials/${prevPage}?createdAt=${createdAt}&authorId=${authorId}&categoryId=${categoryId}`}
                    variant="arrow"
                  >
                    <ArrowBackIcon
                      className={styles.MaterialPage__footer_arrow_icon}
                      htmlColor="#109CF1"
                    />
                    <p className={styles.MaterialPage__footer_arrow_text}>
                      Предыдущая
                    </p>
                  </BaseLink>
                </li>
                <li
                  className={mcn([
                    styles.MaterialPage__footer_item,
                    !isAdmin ? styles.MaterialPage__footer_item_hide : "",
                  ])}
                >
                  <BaseButton
                    className={styles.MaterialPage__footer_button}
                    variant="main_contained_tangerine"
                    disabled={!isAdmin}
                    onClick={async () => handleDeleteMaterial(materialId)}
                  >
                    Удалить
                  </BaseButton>
                </li>
                <li
                  className={styles.MaterialPage__footer_item}
                  hidden={!nextPage}
                >
                  <BaseLink
                    className={styles.MaterialPage__footer_arrow}
                    to={`/materials/${nextPage}?createdAt=${createdAt}&authorId=${authorId}&categoryId=${categoryId}`}
                    variant="arrow"
                  >
                    <p className={styles.MaterialPage__footer_arrow_text}>
                      Следующая
                    </p>
                    <ArrowForwardIcon
                      className={styles.MaterialPage__footer_arrow_icon}
                      htmlColor="#109CF1"
                    />
                  </BaseLink>
                </li>
              </ul>
            </div>
          </article>
        </BaseContent>
      </BasePage>
    </>
  );
};

type PagesType = {
  nextPage: number;
  prevPage: number;
};

export default MaterialPage;

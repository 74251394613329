import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import styles from "./Category.module.scss";

type PropsType = {
  name: string;
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const Category = ({ name, onClick }: PropsType) => {
  return (
    <>
      <div className={styles.Category}>
        <div className={styles.Category__text}>
          {name}
        </div>
        <CloseIcon
          className={styles.Category__icon}
          onClick={onClick}
        />
      </div>
    </>
  );
};

const MemoizedCategory = React.memo(Category);

export default MemoizedCategory;

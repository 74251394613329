import { ThemeOptions } from '@mui/material';

export const MuiTextField: ThemeOptions['components'] = {
  MuiTextField: {
    styleOverrides: {
      root: {
        minWidth: '100%',
        borderColor: '#868686',
        '& > label': {
          color: '#868686 !important',
          font: '500 1.6rem Montserrat, sans-serif !important',
          top: '-.3rem',
          transformOrigin: 'bottom left',
        },
        '&:hover > label': {
          color: '#1A1A1A !important',
        },
        '& > .MuiOutlinedInput-root > fieldset > legend': {
          font: '500 1.9rem Montserrat, sans-serif !important',
        },
        '& > .MuiOutlinedInput-root:hover > fieldset': {
          borderColor: '#ed5338 !important',
        },
        '& > .MuiOutlinedInput-root': {
          color: '#1A1A1A !important',
        },
      },
    },
  },
};

import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import toast from "react-hot-toast/headless";

import { BaseTableColumns } from "content/Table/Table.types";
import { BaseButton, BaseDate, BasePagination } from "components/base";
import { Table, RequestStatus } from "content";

import { useEffectAfterMount, useFilters } from "hooks";
import { RequestsControllerGetAllApiArg, useRequestsControllerGetAllQuery } from "app/api/hooks/requests";
import { RequestStatusEnum } from "common/enum";
import { selectUser } from "app/authSlice";
import { Requests } from "app/api/hooks/organizations";
import styles from "./NeedHelp.module.scss";

const NeedHelp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);

  // useFilter is a custom hook for filter of state
  const { filters, onHandleFilters } = useFilters<RequestsControllerGetAllApiArg>({
    organizationId: user.organizationId,
    limit: 10,
    page: 1,
  });

  // Get all requests
  const { data, isLoading, refetch } = useRequestsControllerGetAllQuery(filters, {
    refetchOnMountOrArgChange: true
  });

  // Refetch page when we change routing, after mount component
  useEffectAfterMount(() => {
    if (!location.pathname.includes("request")) {
      refetch();
    }
  }, [location.pathname]);

  const onAppealHandler = (id: number) => {
    if (!data && !data?.items) return;

    const foundAppeal = data?.items?.find((appeal: Requests) => appeal?.id === id);

    if (foundAppeal?.status.id !== RequestStatusEnum["На рассмотрении"]) {
      toast.error("Невозможно обновить запрос, заявка уже прошла модерацию.");
      return;
    }

    navigate(`update_request/${id}`);
  }

  return (
    <>
      <div className={styles.NeedHelp}>
        <div className={styles.NeedHelp__header}>
          <BaseButton
            className={styles.NeedHelp__button}
            variant="main_contained_tangerine"
            onClick={() => navigate("create_request")}
          >
            Создать запрос
          </BaseButton>
        </div>
        <div className={styles.NeedHelp__body}>
          <Table<Requests>
            columns={columns}
            data={data?.items}
            isLoading={isLoading}
            onClick={onAppealHandler}
          />
        </div>
        <div className={styles.NeedHelp__footer}>
          <BasePagination
            onChange={(page) => onHandleFilters({ page })}
            count={data?.totalPages}
            page={filters.page}
          />
        </div>
      </div>
    </>
  );
};

const columns: BaseTableColumns<Requests> = [
  {
    name: "Заголовок",
    selector: (row) => row.name,
    style: {
      minWidth: "23.7rem"
    }
  },
  {
    name: "Статус",
    selector: (row) => <RequestStatus name={row.status.name} />,
    style: {
      whiteSpace: "nowrap"
    }
  },
  {
    name: "Дата публикации",
    selector: (row) =>
      row.publishedAt ? (
        <BaseDate value={row.publishedAt} variant="material" />
      ) : (
        <>Не опубликован</>
      ),
    style: {
      whiteSpace: "nowrap"
    }
  },
  {
    name: "",
    selector: (row) => row.statusId === RequestStatusEnum["Отправлено"]
      ? <BaseButton variant="main_contained_tangerine" disabled={true}>
        Запрос выполнен
      </BaseButton>
      : null,
  }
];

export default NeedHelp;

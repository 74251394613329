import React from 'react';
import { DeepPartial, Path, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormCardContext, State } from './_context';
import { useEffectAfterMount } from 'hooks/useEffectAfterMount';
import { FormCardSubtitle } from './FormCardSubtitle/FormCardSubtitle';
import { FormCardTitle } from './FormCardTitle/FormCardTitle';
import { FormCardRow } from './FormCardRow/FormCardRow';
import styles from './FormCard.module.scss';

type PropsType<T> = {
  children: React.ReactNode;
  defaultValues?: State<T>;
  schema?: any;
  onChange?: (state: State<T>) => void;
  onSubmit?: (state: State<T>) => void;

  Subtitle?: typeof FormCardSubtitle;
  Title?: typeof FormCardTitle;
  Row?: typeof FormCardRow;
};

const FormCard = <T,>({
  children,
  onChange = () => {},
  onSubmit = () => {},
  defaultValues,
  schema,
}: PropsType<T>) => {
  const form = useForm<State<T>>({
    defaultValues: defaultValues as DeepPartial<State<T>> | undefined,
    resolver: schema ? yupResolver(schema) : undefined,
  });

  const state = form.watch();
  const errors = form.formState.errors;

  const onHandleState = (key: keyof State<T>, value: string | number) => {
    form.setValue(key as unknown as Path<State<T>>, value as any);
    form.trigger(key as unknown as Path<State<T>>);
  };

  useEffectAfterMount(() => {
    onChange(state);
  }, [state]);

  return (
    <>
      <FormCardContext.Provider value={{ state, errors, onHandleState }}>
        <div className={styles.FormCard}>{children}</div>
      </FormCardContext.Provider>
    </>
  );
};

FormCard.Subtitle = FormCardSubtitle;
FormCard.Title = FormCardTitle;
FormCard.Row = FormCardRow;

export default FormCard;

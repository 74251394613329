import React from 'react';
import { Box } from '@mui/material';

import { BaseOutlinedInput, BaseInputError } from 'components/base';

type PropsType = {
  placeholder?: string;
  type: 'text' | 'password';
  value: string;
  onChange: (text: string) => void;
  errorMessage?: string;
};

const FormInput = React.memo(({ errorMessage = '', ...rest }: PropsType) => {
  return (
    <>
      <Box>
        <BaseOutlinedInput {...rest}>
          <BaseOutlinedInput.VisibilityOff />
          <BaseOutlinedInput.Visibility />
        </BaseOutlinedInput>
      </Box>
      <Box sx={{ textAlign: 'left' }}>
        <BaseInputError message={errorMessage} />
      </Box>
    </>
  );
});

export default FormInput;

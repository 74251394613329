import { mcn } from 'common/funcs/mcn.func';
import styles from './BaseTypography.module.scss';

// pt = profile title
// ps = profile subtitle
// prt = public route title
// at = admin title
// ms = modal subtitle

type TypographyType = 'pt' | 'ps' | 'prt' | 'at' | 'ms';

type PropsType = {
  className?: string;
  children: React.ReactNode;
  variant: 'h1' | 'h2' | 'h3' | 'p';
  type: TypographyType;
};

const BaseTypography = ({
  className = '',
  children,
  variant,
  type,
}: PropsType) => {
  const baseClassName = styles.BaseTypography;
  const typeClassName = styles['BaseTypography_' + type];
  const commonClassName = mcn([baseClassName, typeClassName, className]);

  switch (variant) {
    case 'h1':
      return (
        <>
          <h1 className={commonClassName}>{children}</h1>
        </>
      );
    case 'h2':
      return (
        <>
          <h2 className={commonClassName}>{children}</h2>
        </>
      );
    case 'h3':
      return (
        <>
          <h3 className={commonClassName}>{children}</h3>
        </>
      );
    case 'p':
      return (
        <>
          <p className={commonClassName}>{children}</p>
        </>
      );
  }
};

export default BaseTypography;

import { Outlet, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import React from "react";
import toast from 'react-hot-toast/headless';

import { BaseButton, BaseDate, BaseImage, BasePagination } from "components/base";
import { AdminHeader, AdminFooter } from "content/admin";
import { BaseTableColumns } from "content/Table/Table.types";
import { Table } from "content";

import {
  useMailingControllerDeleteOneMutation,
  useMailingControllerResendMutation,
  useMailingControllerGetAllQuery,
  MailingControllerGetAllApiArg,
  Mailing,
} from "app/api/hooks/mailing";
import { useEffectAfterMount } from "hooks";
import svgRecycleIcon from "common/svg/svg-recycle-icon.svg";
import svgEditIcon from "common/svg/svg-edit-icon.svg";
import svgBinIcon from "common/svg/svg-bin-icon.svg";
import styles from "./AdminMailing.module.scss";
import { em } from "common/funcs/em";

const AdminMailingPage = () => {
  // Filters of the request
  const [filters, setFilters] = React.useState<MailingControllerGetAllApiArg>({
    limit: 6,
    page: 1
  });

  // All mailing entries
  const { data: mailing, isLoading, refetch } = useMailingControllerGetAllQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  // Main filter handler
  const onHandleFilters = <T,>(payload: T) => {
    setFilters((prev) => ({
      ...prev,
      ...payload
    }));
  }

  // Pagination handler
  const onPaginationHandler = (page: number) => {
    onHandleFilters({ page })
  }

  const [deleteOneMailing] = useMailingControllerDeleteOneMutation();

  // Delete mailing handler
  const onDeleteMailingHandler = async (id: number) => {
    try {
      await toast.promise(deleteOneMailing({ id }).unwrap(),
        {
          loading: 'Запрос обрабатывается',
          success: 'Запрос выполнен успешно',
          error: "Не удалось выполнить запрос",
        }
      );
      refetch();
    } catch (e) {
      toast.error(em(e));
    }
  }

  const [resendOneMailing] = useMailingControllerResendMutation();

  // Resend mailing handler
  const onResendMailingHandler = async (id: number) => {
    try {
      await toast.promise(resendOneMailing({ id }).unwrap(),
        {
          loading: 'Запрос обрабатывается',
          success: 'Запрос выполнен успешно',
          error: "Не удалось выполнить запрос",
        }
      );
    } catch (e) {
      toast.error(em(e));
    }
  }

  const navigate = useNavigate();
  const location = useLocation();

  useEffectAfterMount(() => {
    if (location.pathname === "/admin/mailing") {
      refetch();
    }
  }, [location.pathname]);

  return (
    <>
      <div className={styles.AdminMailingPage}>
        <AdminHeader className={styles.AdminMailingPage__header} title="Рассылка">
          <BaseButton
            className={styles.AdminMailingPage__button}
            variant="main_contained_tangerine"
            onClick={() => navigate("create_mailing")}
          >
            Добавить
          </BaseButton>
        </AdminHeader>
        <div className={styles.AdminMailingPage__body}>
          <div className={styles.AdminMailingPage__table}>
            <Table<AdminMailingTableType>
              isLoading={isLoading}
              columns={columns(onDeleteMailingHandler, onResendMailingHandler)}
              data={mailing?.items}
            />
          </div>
        </div>
        <AdminFooter className={styles.AdminMailingPage__footer}>
          <BasePagination
            onChange={onPaginationHandler}
            count={mailing?.totalPages}
            page={filters.page}
          />
        </AdminFooter>
      </div>

      {/* OUTLET FOR MODAL */}
      <Outlet />
    </>
  )
}

type AdminMailingTableType = Mailing;

type OnDeleteMailingHandler = (id: number) => Promise<void>;

type OnResendMailingHandler = (id: number) => Promise<void>;

const columns = (
  onDeleteMailingHandler: OnDeleteMailingHandler,
  onResendMailingHandler: OnResendMailingHandler,
): BaseTableColumns<AdminMailingTableType> => [
    {
      name: "Текст",
      selector: (row) => row.text.length > 55 ? row.text.slice(0, 50) + "..." : row.text,
      style: {
        maxWidth: "72.1rem",
      }
    },
    {
      name: "Дата",
      selector: (row) => <BaseDate variant="common" value={row.createdAt} />
    },
    {
      name: "",
      selector: (row) => (
        <>
          <ul className={styles.AdminMailingPage__controls}>
            <li className={styles.AdminMailingPage__control}>
              <BaseImage
                onClick={() => onResendMailingHandler(row.id)}
                src={svgRecycleIcon}
                height="20"
                width="20"
                alt="Повторить"
              />
            </li>
            <li className={styles.AdminMailingPage__control}>
              <Link to={`update_mailing/${row.id ?? 1}`}>
                <BaseImage
                  src={svgEditIcon}
                  width="20"
                  height="20"
                  alt="Редактировать"
                />
              </Link>
            </li>
            <li className={styles.AdminMailingPage__control}>
              <BaseImage
                onClick={() => onDeleteMailingHandler(row.id)}
                src={svgBinIcon}
                height="20"
                width="20"
                alt="Удалить"
              />
            </li>
          </ul>
        </>
      ),
    },
  ];

export default AdminMailingPage;

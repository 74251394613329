import { ThemeOptions } from '@mui/material';

export const MuiTabs: ThemeOptions['components'] = {
  MuiTabs: {
    defaultProps: {
      TabIndicatorProps: {
        sx: {
          borderBottom: '2px solid #ED5338',
        },
      },
      sx: {
        '& button.Mui-selected': {
          color: '#1A1A1A',
        },
        '& button': {
          color: 'rgba(26, 26, 26, 0.7)',
          textTransform: 'unset',
          paddingLeft: 0,
          maxWidth: "fit-content",
          minWidth: "fit-content",
          padding: "0rem",
          marginRight: '2rem',
        },
        '& button:nth-last-child(1)': {
          marginRight: "0rem",
        },
        '& button:hover': {
          color: '#1A1A1A',
          borderBottomColor: '#ED5338',
        },
      },
    },
  },
};

import React from "react";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAuth, useWindowSize } from "hooks";
import toast from "react-hot-toast/headless";

import { BaseButton, BaseLink, BasePageForm } from "components/base";
import { FormCard, FormInput } from "content";

import { setCredentials } from "app/authSlice";
import { SignonScheme } from "common/validation";
import {
  useAuthControllerSignonMutation,
  SignonRequestDto,
} from "app/api/hooks/auth";
import { em } from "common/funcs/em";

const SignonPage = () => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  // Form initialization
  const form = useForm<SignonRequestDto>({
    defaultValues: {
      password: "",
      email: "",
    },
    resolver: yupResolver(SignonScheme),
  });

  // Form state
  const formState = form.watch();
  const errors = form.formState.errors;

  // Form handler
  const onHandleState = (key: keyof SignonRequestDto, value: string) => {
    form.setValue(key, value.trim(), {
      shouldValidate: true,
    });
  };

  // Hooks
  const { setIsAuthed } = useAuth();
  const navigation = useNavigate();
  const [signon] = useAuthControllerSignonMutation();

  // Form submit handler
  const onHandleSubmit = async (signonRequestDto: SignonRequestDto) => {
    try {
      const { accessToken, user } = await signon({ signonRequestDto }).unwrap();

      dispatch(setCredentials({ accessToken, user }));
      setIsAuthed(true);

      user.role === "ADMIN"
        ? navigation("/admin/requests")
        : navigation("/account");
    } catch (e) {
      toast.error(em(e));
    }
  };

  return (
    <>
      <BasePageForm>
        <FormCard<SignonRequestDto>>
          <form onSubmit={form.handleSubmit(onHandleSubmit)}>
            <FormCard.Row>
              <FormCard.Title>Авторизация</FormCard.Title>
            </FormCard.Row>
            <FormCard.Row marginTop={isMobile ? "3rem" : "5rem"}>
              <FormInput
                placeholder="Email"
                errorMessage={errors["email"]?.message}
                onChange={(text) => {
                  onHandleState("email", text);
                }}
                value={formState.email}
                type="text"
              />
            </FormCard.Row>
            <FormCard.Row marginTop="2rem">
              <FormInput
                placeholder="Пароль"
                errorMessage={errors["password"]?.message}
                onChange={(text) => {
                  onHandleState("password", text);
                }}
                value={formState.password}
                type="password"
              />
            </FormCard.Row>
            <FormCard.Row marginTop="1.4rem" textAlign="left">
              <BaseLink variant="restore" to="/restore/password">
                Забыли пароль?
              </BaseLink>
            </FormCard.Row>
            <FormCard.Row marginTop="2.8rem">
              <BaseButton
                fullWidth={true}
                variant="main_contained_tangerine"
                type="submit"
              >
                Продолжить
              </BaseButton>
            </FormCard.Row>
            <FormCard.Row marginTop="3rem">
              <BaseLink variant="form" to="/signup">
                Нет аккаунта? Зарегистрируйтесь
              </BaseLink>
            </FormCard.Row>
          </form>
        </FormCard>
      </BasePageForm>
    </>
  );
};

export default SignonPage;

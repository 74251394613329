import React from 'react';
import { ImgHTMLAttributes } from 'react';

import svgUploadPhotoPreview from "common/svg/svg-upload-icon.svg";
import { mcn } from 'common/funcs/mcn.func';
import styles from './BaseImage.module.scss';

export type BaseImageLazyType = { lazyLoading?: boolean };
export type BaseImagePropsType = ImgHTMLAttributes<HTMLImageElement> & BaseImageLazyType | { className?: string, src: File } & BaseImageLazyType;

const BaseImage = ({ src = svgUploadPhotoPreview, className = "", lazyLoading = false, ...rest }: BaseImagePropsType) => {
  // URL transform
  const url = typeof src === 'object' ? URL.createObjectURL(src) : src;

  // Loading state
  const [isLoaded, setIsLoaded] = React.useState(false);

  // Ref to native image element
  const imageRef = React.useRef<HTMLImageElement | null>(null);

  React.useEffect(() => {
    if (!imageRef.current) return;
    imageRef.current.onload = () => setIsLoaded(true);
  }, []);

  return lazyLoading
    ? (
      <>
        <div className={mcn([className, !isLoaded ? styles.BaseImage_loading : ""])}>
          <link rel="preload" as="image" href={url} />
          <img
            className={mcn([className, !isLoaded ? styles.BaseImage_hide : ""])}
            loading="lazy"
            style={!isLoaded ? { opacity: 0 } : {}}
            ref={imageRef}
            src={!src ? svgUploadPhotoPreview : url}
            {...rest}
          />
        </div>
      </>
    )
    : (
      <>
        <link rel="preload" as="image" href={url} />
        <img
          className={className}
          loading="lazy"
          src={!src ? svgUploadPhotoPreview : url}
          {...rest}
        />
      </>
    )
};

const MemoizedBaseImage = React.memo(BaseImage);

export default MemoizedBaseImage;

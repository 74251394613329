export enum OrganizationTypeEnum {
  "ЮРЛИЦО" = 1,
  "ФИЗЛИЦО" = 2,
  "ИП" = 3,
  "НКО" = 4,
}

export enum RequestStatusEnum {
  'Все' = 0,
  'На рассмотрении' = 1,
  'Опубликовано' = 2,
  'Одобрено' = 3,
  'Отменено' = 4,
  'Отправлено' = 5,
}

export enum RoleEnum {
  "USER" = "USER",
  "ADMIN" = "ADMIN",
}

export enum OrganizationStatusEnum {
  "Новый пользователь" = 1,
  "Кандидат" = 2,
  "Действующий" = 3,
  "Отказ" = 4,
  "На доработке" = 5,
}

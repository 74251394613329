import React from "react";
import { useSelector } from "react-redux";

import { AvatarType, AvatarContext } from "common/context";
import { selectUser } from "app/authSlice";
import { RoleEnum } from "common/enum";
import imageAuthor from 'common/images/w1440/image-material-author-w1440.webp';

type PropsType = {
  children: React.ReactNode;
};

const AvatarProvider = ({ children }: PropsType) => {
  const [avatar, setAvatar] = React.useState<AvatarType>(undefined);

  const user = useSelector(selectUser);

  React.useEffect(() => {
    if (user?.role === RoleEnum.ADMIN) {
      setAvatar(imageAuthor);
    }
  }, [user])

  const onHandleState = (file: AvatarType) => {
    setAvatar(file);
  };


  return (
    <AvatarContext.Provider value={{
      onHandleState,
      avatar,
    }}>
      {children}
    </AvatarContext.Provider>
  );
};

export default AvatarProvider;

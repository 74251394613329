import React from "react";

type UseLoadingResponseDto = {
  isRequestLoading: boolean;
  onRequestLoadingHandler: (isRequestLoading: boolean) => void;
};

export const useLoading = (): UseLoadingResponseDto => {
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);

  const onRequestLoadingHandler = (isRequest: boolean) =>
    setIsRequestLoading(isRequest);

  return { isRequestLoading, onRequestLoadingHandler };
};

import React from "react";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import { OrganizationAdmin } from "content";
import { BaseContent } from "components/base";

import { useUsersControllerSetAvatarMutation } from "app/api/hooks/users";
import { ProfileCreateNonprofitScheme } from "common/validation";
import { ProfileNonprofit } from "pages/private/profile/ProfileNonprofit";
import { OptionType } from "common/types/option.type";
import {
  useOrganizationsControllerUploadDocumentsMutation,
  useOrganizationsControllerCreateOneMutation,
  CreateOneOrganizationRequestDto,
} from "app/api/hooks/organizations";
import { em } from "common/funcs/em";
import { OrganizationTypeEnum } from "common/enum";
import { useUploadDocuments } from "hooks/useUploadDocuments";
import { useUploadAvatar } from "hooks/useUploadAvatar";
import { useWhatHelpTypes } from "hooks/useWhatHelpTypes";
import { useWhoHelpTypes } from "hooks/useWhoHelpTypes";
import { useCities } from "hooks/useCities";

const CreateOrganizationPage = () => {
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const navigate = useNavigate();

  // State mutations
  const [createOrganization] = useOrganizationsControllerCreateOneMutation();
  const [uploadDocuments] = useOrganizationsControllerUploadDocumentsMutation();
  const [uploadAvatar] = useUsersControllerSetAvatarMutation();

  // Form initialize
  const form = useForm<CreateOneOrganizationRequestDto>({
    resolver: yupResolver(ProfileCreateNonprofitScheme),
    defaultValues,
  });

  // Form state
  const errors = form.formState.errors;
  const data = form.watch();

  React.useEffect(() => {
    form.setValue("organizationType.name", OrganizationTypeEnum[4]);
    form.setValue("organizationType.id", OrganizationTypeEnum["НКО"]);
  }, []);

  // Form state handler
  const onFormStateHandler = React.useCallback(
    (
      key: keyof CreateOneOrganizationRequestDto,
      value: Array<OptionType> | OptionType | boolean | string,
    ) => {
      form.setValue(key, value);
    },
    [],
  );

  const {
    requestedDocuments,
    uploadedDocuments,
    onDeleteRequestedDocument,
    onDeleteUploadedDocument,
    onUploadDocument,
  } = useUploadDocuments({});
  const { onUploadAvatar, uploadedAvatar } = useUploadAvatar();
  const { whatHelpTypesList } = useWhatHelpTypes();
  const { whoHelpTypesList } = useWhoHelpTypes();
  const { citiesList } = useCities();

  // Form state submit
  const onFormSubmitHandler = async (
    createOneOrganizationRequestDto: CreateOneOrganizationRequestDto,
  ) => {
    const formData = new FormData();

    try {
      setIsRequestLoading(true);

      const { id, userId } = await createOrganization({
        createOneOrganizationRequestDto,
      }).unwrap();

      // If avatar was uploaded
      if (uploadedAvatar) {
        formData.set("avatar", uploadedAvatar as Blob, uploadedAvatar.name);

        await uploadAvatar({ id: userId, body: formData as any }).unwrap();

        formData.delete("avatar");
      }

      // If documents was uploaded
      if (uploadedDocuments.length > 0) {
        for (const uploadedDocument of uploadedDocuments) {
          formData.append(
            "documents",
            uploadedDocument as Blob,
            encodeURI(uploadedDocument.name),
          );
        }

        await uploadDocuments({ id, body: formData as any }).unwrap();

        formData.delete("documents");
      }

      toast.success(
        `Организация успешно создана, пароль отправлен на ${createOneOrganizationRequestDto.email}`,
        {
          duration: 5000,
        },
      );

      form.reset();

      navigate(-1);
    } catch (e) {
      toast.error(em(e));
    } finally {
      setIsRequestLoading(false);
    }
  };

  return (
    <>
      <BaseContent>
        <form onSubmit={form.handleSubmit(onFormSubmitHandler)}>
          <OrganizationAdmin
            title="Создать организацию"
            onlyView={false}
            isLoading={isRequestLoading}
          >
            <ProfileNonprofit
              whatHelpTypesList={whatHelpTypesList}
              whoHelpTypesList={whoHelpTypesList}
              citiesList={citiesList}
              onDeleteRequestedDocument={onDeleteRequestedDocument(undefined)}
              onFormStateHandler={onFormStateHandler}
              onUploadAvatar={onUploadAvatar(false)}
              avatar={uploadedAvatar}
              errors={errors}
              data={data}
              isDisableEmail={false}
              onDeleteUploadedDocument={onDeleteUploadedDocument}
              onUploadDocument={onUploadDocument}
              requestedDocuments={requestedDocuments}
              uploadedDocuments={uploadedDocuments}
            />
          </OrganizationAdmin>
        </form>
      </BaseContent>
    </>
  );
};

export const defaultValues: CreateOneOrganizationRequestDto = {
  lastName: "",
  firstName: "",
  INN: "",
  name: "",
  siteLink: "",
  email: "",
  phoneNumber1: "",
  phoneNumber2: "",
  city: undefined,
  address: "",
  description: "",
  whatHelpTypes: [],
  whoHelpTypes: [],
  vkLink: undefined,
  okLink: undefined,
  isAcceptedCashPolicy: false,
  isAcceptedNoCashPolicy: false,
};

export default CreateOrganizationPage;

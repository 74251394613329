import { Route, Routes } from 'react-router';

import { RestorePasswordPage } from 'pages/public';
import { Default } from 'layouts';

const RestoreRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Default subscription={false} />}>
          <Route path="password" element={<RestorePasswordPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default RestoreRoutes;

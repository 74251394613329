import { Link } from 'react-router-dom';

import { BaseImage, BaseLink } from 'components/base';

import { adminLinks } from 'common/links';
import svgLogoIcon from 'common/svg/svg-brown-logo.svg';
import styles from './Aside.module.scss';

const Aside = () => {
  return (
    <>
      <aside className={styles.Aside}>
        <div className={styles.Aside__inner}>
          <div className={styles.Aside__head}>
            <Link to="/">
              <BaseImage
                className={styles.Aside__logo}
                height="55"
                width="164"
                src={svgLogoIcon}
                alt="Логотип"
              />
            </Link>
            <p className={styles.Aside__text}>
              Панель <br />
              администратора
            </p>
          </div>
          <ul className={styles.Aside__list}>
            {adminLinks.map(({ text, icon, ...rest }, key) => (
              <li className={styles.Aside__item} key={key}>
                <BaseLink
                  className={styles.Aside__link}
                  variant="header"
                  {...rest}
                >
                  {icon}
                  {text}
                </BaseLink>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Aside;

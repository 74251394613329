import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { BaseImage, BaseLink } from 'components/base';

import { useUsersControllerGetAvatarQuery } from 'app/api/hooks/users';
import { headerAuthedLinks } from 'common/links';
import { useAvatarContext } from 'common/context';
import { selectUser } from 'app/authSlice';
import { RoleEnum } from 'common/enum';
import styles from './HeaderAuthed.module.scss';

export const HeaderAuthed = () => {
  const user = useSelector(selectUser)

  // User context
  const { avatar, onHandleState } = useAvatarContext();

  // Get avatar here
  const { data } = useUsersControllerGetAvatarQuery({ id: user?.userId });

  // Set avatar here
  React.useEffect(() => {
    if (data && "path" in data) {
      onHandleState(data.path);
    }
  }, [data]);

  const isAdmin = user?.role === RoleEnum.ADMIN;

  return (
    <>
      <Grid columnGap="2.5rem" container alignItems="center">
        <Grid item>
          <BaseImage
            className={styles.HeaderAuthed__avatar}
            src={avatar as string | undefined}
            alt="Аватар"
            height="40"
            width="40"
          />
        </Grid>
        {
          headerAuthedLinks(isAdmin).map(({ to, text }, key) => (
            <Grid item key={key}>
              <BaseLink
                className={styles.HeaderAuthed__link}
                variant="header"
                key={key}
                to={to}
              >
                {text}
              </BaseLink>
            </Grid>
          ))
        }
      </Grid>
    </>
  );
};

import React from 'react';
import {
  useSearchParams,
  useLocation,
  Link,
} from 'react-router-dom';

import { MaterialPreview, NoEntries, Select } from 'content';
import {
  BaseButton,
  BaseContent,
  BaseLoader,
  BaseOutlinedSelect,
  BasePage,
  BaseTypography,
} from 'components/base';

import { Materials, MaterialsControllerGetAllApiArg, useMaterialsControllerGetAllQuery } from 'app/api/hooks/materials';
import { useOrganizationsWhoHelpControllerGetAllQuery } from 'app/api/hooks/organizations';
import { useFilters } from 'hooks';
import { OptionType } from 'common/types/option.type';
import styles from './Materials.module.scss';

const MaterialsPage = () => {
  // useFilter is a custom hook for filter of state
  const { filters, onHandleFilters } = useFilters<MaterialsControllerGetAllApiArg>({
    categoryId: undefined,
    createdAt: options[0].value,
    authorId: undefined,
    limit: 12,
    page: 1,
  });

  // Request to API
  const { data: materials, isLoading } = useMaterialsControllerGetAllQuery(filters, { refetchOnMountOrArgChange: true });

  // Request to categories
  const { data: categories } = useOrganizationsWhoHelpControllerGetAllQuery();

  const [category, setCategory] = React.useState<OptionType | undefined>();

  React.useEffect(() => {
    if (!categories) return;
    setCategory(categories[0]);
  }, [categories]);

  // Authors
  const [author, setAuthor] = React.useState<OptionType | undefined>(materialAuthors[0]);

  const [searchParams] = useSearchParams();
  const location = useLocation();

  // Author id
  const authorId = searchParams.get("authorId");

  React.useEffect(() => {
    if (!authorId) return;

    onHandleFilters({ authorId });

    if (!materialAuthors) return;

    const foundAuthor = materialAuthors.find((author) => author.id === +authorId);

    (foundAuthor)
      ? setAuthor(foundAuthor)
      : setAuthor(materialAuthors[0]);
  }, [location.search]);

  React.useEffect(() => {
    if (authorId) return;
    if (!materialAuthors) return;

    onHandleFilters({ authorId: undefined });
    setAuthor(materialAuthors[0]);
  }, [location]);

  const isMoreThenZero = materials?.items && materials.items.length > 0;
  const isCountMoreThenLimit = isMoreThenZero && materials.count > filters.limit;

  return (
    <>
      <BasePage className={styles.MaterialsPage}>
        <BaseContent>
          <div className={styles.MaterialsPage__header}>
            <div className={styles.MaterialsPage__header_top}>
              <BaseTypography
                className={styles.MaterialsPage__title}
                variant="h2"
                type="prt"
              >
                Полезные материалы
              </BaseTypography>
              <BaseOutlinedSelect
                onChange={(selected) => onHandleFilters({ createdAt: selected.value })}
                options={options}
                defaultValue={options[0]}
              />
            </div>
            <div className={styles.MaterialsPage__header_bot}>
              <Select
                onChange={(selected) => {
                  if (Array.isArray(selected)) return;

                  const authorId = "" + selected.id;

                  setAuthor(() => selected);
                  onHandleFilters({ authorId });
                  searchParams.set("authorId", authorId);
                }}
                multiple={false}
                value={author}
                variant="select"
                label="Автор"
              >
                <Select.OptionList>
                  {materialAuthors && materialAuthors?.map((item) => (
                    <Select.OptionItem
                      selected={author?.id === item.id}
                      key={item.id}
                      {...item}
                    />
                  ))}
                </Select.OptionList>
              </Select>
              <Select
                onChange={(selected) => {
                  if (Array.isArray(selected)) return;

                  onHandleFilters({ categoryId: selected.id });
                  setCategory(selected);
                }}
                value={category}
                label="Направление"
                multiple={false}
                variant="select"
              >
                <Select.OptionList>
                  {categories && categories.map((item) => (
                    <Select.OptionItem
                      selected={category?.id === item.id}
                      key={item.id}
                      {...item}
                    />
                  ))}
                </Select.OptionList>
              </Select>
            </div>
          </div>
          <div className={styles.MaterialsPage__body}>
            {
              isLoading
                ? (
                  <div className={styles.MaterialsPage__loader}>
                    <BaseLoader />
                  </div>
                )
                : isMoreThenZero
                  ? (
                    <div className={styles.MaterialsPage__content}>
                      {materials && materials?.items.map((material: Materials, key: number) => (
                        <Link
                          to={`/materials/${material.id}?createdAt=${filters.createdAt}&authorId=${filters.authorId}&categoryId=${filters.categoryId}`}
                          key={key}
                        >
                          <MaterialPreview key={key} {...material} />
                        </Link>
                      ))}
                    </div>
                  )
                  : (
                    <NoEntries />
                  )
            }
          </div>
          <div className={styles.MaterialsPage__footer}>
            {
              isCountMoreThenLimit && (
                <BaseButton variant="main_outlined_tangerine" onClick={() => onHandleFilters({ limit: filters.limit + filters.limit })}>
                  Больше материалов
                </BaseButton>
              )
            }
          </div>
        </BaseContent>
      </BasePage>
    </>
  );
};

const options = [
  {
    name: 'Новые',
    value: 'desc',
  },
  {
    name: 'Старые',
    value: 'asc',
  },
];

const materialAuthors = [
  {
    id: 0,
    name: "Все",
  },
  {
    id: 2,
    name: "Мой бизнес",
  },
  {
    id: 1,
    name: "Ресурсный центр НКО",
  },
]

export default MaterialsPage;

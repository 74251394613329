import React from 'react';

export type State = {
  showPassword: boolean;
  text: string;
};

export const BaseOutlinedInputContext = React.createContext<State>({
  showPassword: false,
  text: '',
});

export const useInputContext = () => {
  const context = React.useContext(BaseOutlinedInputContext);

  if (!context) {
    throw new Error(
      `BaseOutlinedInput compound components cannot be rendered outside the BaseOutlinedInput component`,
    );
  }

  return context;
};

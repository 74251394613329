import React from "react";

import { useOrganizationsWhoHelpControllerGetAllQuery } from "app/api/hooks/who-help";
import { OptionType } from "common/types/option.type";

export function useWhoHelpTypes() {
  // Who help types catergories
  const { data: whoHelpTypes } = useOrganizationsWhoHelpControllerGetAllQuery();

  const [whoHelpTypesList, setWhoHelpTypesList] = React.useState<OptionType[]>(
    [],
  );

  React.useEffect(() => {
    if (!whoHelpTypes) return;

    setWhoHelpTypesList(whoHelpTypes);
  }, [whoHelpTypes]);

  return {
    whoHelpTypesList,
  };
}

import { apiSlice as api } from "../apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    mailingSubscriptionsControllerCreateOne: build.mutation<
      MailingSubscriptionsControllerCreateOneApiResponse,
      MailingSubscriptionsControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/mailing-subscriptions`,
        method: "POST",
        body: queryArg.createOneSubscriptionDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type MailingSubscriptionsControllerCreateOneApiResponse =
  /** status 200  */ Subscriptions;
export type MailingSubscriptionsControllerCreateOneApiArg = {
  createOneSubscriptionDto: CreateOneSubscriptionDto;
};
export type Mailing = {
  id: number;
  text: string;
  isSendToEmail: boolean;
  isSendToPhone: boolean;
  createdAt: string;
  updatedAt: string;
  mailingWhomId: number;
  mailingWhom: MailingWhom;
};
export type MailingWhom = {
  id: number;
  name: string;
  mailing: Mailing[];
};
export type Subscriptions = {
  id: number;
  email: string;
  createdAt: string;
  updatedAt: string;
  mailingWhomId: number;
  mailingWhom: MailingWhom;
};
export type CreateOneSubscriptionDto = {
  email: string;
};
export const { useMailingSubscriptionsControllerCreateOneMutation } =
  injectedRtkApi;

import React from 'react';
import { Link, NavLinkProps, useLocation } from 'react-router-dom';

import styles from './BaseLink.module.scss';

type PropsType = {
  className?: string;
  children: React.ReactNode;
  variant: 'header' | 'footer' | 'material' | 'arrow' | 'form' | 'restore' | 'mobile_menu';
  to: string;
  hiddent?: boolean;
} & NavLinkProps &
  React.RefAttributes<HTMLAnchorElement>;

const BaseLink = React.memo(
  ({ className = '', children, variant, to, hidden = false }: PropsType) => {
    const location = useLocation();
    return (
      <Link
        className={`
                ${styles.BaseLink}
                ${styles['BaseLink_' + variant]}
                ${className}
                ${location.pathname === to ||
            (location.pathname.includes('/' + to) && to !== '/')
            ? styles.BaseLink_active
            : ''
          }
            `.trim()}
        hidden={hidden}
        to={to}
      >
        {children}
      </Link>
    );
  },
);

export default BaseLink;

import { headerNoAuthedLinks } from 'common/links';
import { BaseLink } from 'components/base';

import styles from './HeaderNoAuthed.module.scss';

export const HeaderNoAuthed = () => {
  return (
    <>
      <ul className={styles.HeaderNoAuthed}>
        {headerNoAuthedLinks.map(({ to, text }, key) => (
          <li className={styles.HeaderNoAuthed__item} key={key}>
            <BaseLink
              className={styles.HeaderNoAuthed__link}
              variant="header"
              to={to}
            >
              {text}
            </BaseLink>
          </li>
        ))}
      </ul>
    </>
  );
};


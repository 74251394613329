import { ThemeOptions } from '@mui/material';

export const MuiTypography: ThemeOptions['components'] = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        body1: 'p',
        body2: 'p',
        body3: 'p',
      },
    },
    styleOverrides: {
      h1: {
        fontSize: '3.8rem',
      },
      h2: {
        fontSize: '3.4rem',
      },
      h3: {
        fontSize: '2.4rem',
      },
      h4: {
        fontSize: '1.7rem',
      },
      h5: {
        fontSize: '1.6rem',
      },
      body1: {
        fontSize: '1.8rem',
      },
      body2: {
        fontSize: '1.6rem',
      },
    },
    variants: [
      {
        props: { variant: 'body3' },
        style: {
          fontSize: '1.4rem',
        },
      },
      {
        props: { variant: 'body4' },
        style: {
          fontSize: '1.2rem',
        },
      },
    ],
  },
};

import { Outlet } from 'react-router';

import { Aside, Header } from 'components/partials';
import { BasePage } from 'components/base';

import { useWindowSize } from 'hooks';
import styles from './DefaultAdmin.module.scss';

const DefaultAdmin = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 767;

  return (
    <>
      <div className={styles.DefaultAdmin}>
        <div className={styles.DefaultAdmin__inner}>
          <div className={styles.DefaultAdmin__aside}>
            {isMobile ? <Header /> : <Aside />}
          </div>
          <div className={styles.DefaultAdmin__body}>
            {isMobile ? (
              <BasePage>
                <Outlet />
              </BasePage>
            ) : (
              <Outlet />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultAdmin;

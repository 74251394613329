import { useParams } from "react-router";

import { BaseModal, BasePhoneInput, BaseTextField } from "components/base";

import { useOrganizationsControllerGetOneQuery } from "app/api/hooks/organizations";
import styles from "./WhoHelp.module.scss";

const WhoHelp = () => {
  const { id } = useParams();
  const { data, isLoading } = useOrganizationsControllerGetOneQuery({
    id: id && typeof id !== undefined ? +id : 0,
  });

  return (
    <>
      <BaseModal title="Откликнулся на обращение" isLoading={isLoading}>
        <ul className={styles.WhoHelp}>
          <li className={styles.WhoHelp__item}>
            <BaseTextField
              value={data?.user?.firstName}
              disabled={true}
              label="Фамилия"
              name="firstName"
            />
          </li>
          <li className={styles.WhoHelp__item}>
            <BaseTextField
              value={data?.user?.lastName}
              disabled={true}
              label="Имя"
              name="lastName"
            />
          </li>
          <li className={styles.WhoHelp__item}>
            <BaseTextField
              value={data?.organizationType?.name}
              disabled={true}
              label="Тип организации"
              name="organizationType"
            />
          </li>
          {/* TODO ПРОЧЕКАТЬ, ПОЧЕМУ У ИНПУТОВ НЕТ ON CHANGE */}
          <li className={styles.WhoHelp__item}>
            <BasePhoneInput
              value={data?.phoneNumber1 ?? ""}
              onChange={() => {}}
              disabled={true}
              label="Телефон 1"
              name="phoneNumber1"
              type="tel"
            />
          </li>
          <li className={styles.WhoHelp__item}>
            <BasePhoneInput
              value={data?.phoneNumber2 ?? ""}
              onChange={() => {}}
              disabled={true}
              label="Телефон 2"
              name="phoneNumber2"
              type="tel"
            />
          </li>
          <li className={styles.WhoHelp__item}>
            <BaseTextField
              value={data?.user?.email}
              disabled={true}
              label="Email"
              name="email"
            />
          </li>
        </ul>
      </BaseModal>
    </>
  );
};

export default WhoHelp;

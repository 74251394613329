import React from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { delCredentials } from 'app/authSlice';

import { useAuthControllerSignoutMutation } from 'app/api/hooks/auth';
import { useAvatarContext } from 'common/context';
import { apiSlice } from 'app/api/apiSlice';
import { useAuth } from 'hooks';

const SignoutPage = () => {
  const [signout] = useAuthControllerSignoutMutation();
  const { onHandleState } = useAvatarContext();
  const { setIsAuthed } = useAuth();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    signout()
      .then(() => {
        dispatch(apiSlice.util.resetApiState());
        dispatch(delCredentials({}));
        setIsAuthed(false);
        onHandleState(undefined)
        navigation('/');
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return null;
};

export default SignoutPage;

import React from 'react';

export type AvatarType = string | undefined;

export type AvatarContextType = {
  avatar: AvatarType;
  onHandleState: (state: AvatarType) => void
};

export const AvatarContext = React.createContext<AvatarContextType>({
  avatar: undefined,
  onHandleState: (state: AvatarType) => { },
});

export const useAvatarContext = () => React.useContext(AvatarContext);

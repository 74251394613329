import React from "react";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import {
  BaseButton,
  BaseGoBack,
  BaseInputError,
  BaseLoader,
  BaseTextarea,
  BaseTextField,
  BaseTypography,
} from "components/base";
import { Cropper, ImagePreview, ImageUploader, Select } from "content";

import { useOrganizationsWhoHelpControllerGetAllQuery } from "app/api/hooks/who-help";
import {
  CreateOneMaterialRequestDto,
  useMaterialsControllerCreateOneMutation,
  useMaterialsControllerGetAuthorsQuery,
  useMaterialsControllerUploadImagesMutation,
} from "app/api/hooks/materials";
import { isSelectedOption } from "common/funcs/is-selected-option.func";
import { MaterialScheme } from "common/validation";
import { OptionType } from "common/types/option.type";
import styles from "./AdminMaterial.module.scss";
import { em } from "common/funcs/em";

const AdminMaterialPage = () => {
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);

  const navigate = useNavigate();
  const formData = new FormData();

  // Who help types list
  const { data: whoHelpTypes } = useOrganizationsWhoHelpControllerGetAllQuery(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        data: data ? data.filter((item) => item.id !== 0) : data,
      }),
    },
  );

  // Material authors list
  const { data: materialAuthors } = useMaterialsControllerGetAuthorsQuery(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        data: data ? data.filter((item) => item.id !== 0) : data,
      }),
    },
  );

  // Images list
  const [uploadedImagesList, setUploadedImagesList] = React.useState<
    Array<File>
  >([]);
  const [croppedImagesList, setCroppedImagesList] = React.useState<Array<File>>(
    [],
  );

  const onUploadImageHandler = React.useCallback((file: File) => {
    setTimeout(() => {
      setUploadedImagesList((files) => [...files, file]);
    }, 100);
  }, []);

  const onDeleteImageHandler = React.useCallback(
    (index: number) => () => {
      setTimeout(() => {
        setUploadedImagesList((files) =>
          [...files].filter((_, i) => i !== index),
        );
      }, 100);
    },
    [],
  );

  const onUploadCroppedImage = React.useCallback((file: File) => {
    setTimeout(() => {
      setCroppedImagesList((files) => [...files, file]);
      setUploadedImagesList(() => []);
    }, 100);
  }, []);

  const onDeleteCroppedImage = React.useCallback(
    (index: number) => () => {
      setTimeout(() => {
        setCroppedImagesList((files) =>
          [...files].filter((_, i) => i !== index),
        );
        setUploadedImagesList(() => []);
      }, 100);
    },
    [],
  );

  const [createMaterial] = useMaterialsControllerCreateOneMutation();
  const [createImages] = useMaterialsControllerUploadImagesMutation();

  // Form initialize
  const form = useForm<CreateOneMaterialRequestDto>({
    resolver: yupResolver(MaterialScheme),
    defaultValues,
  });

  // Form state handler
  const onFormStateHandler = (
    key: keyof CreateOneMaterialRequestDto,
    value: Array<OptionType> | OptionType | string,
  ) => {
    form.setValue(key, value, {
      shouldValidate: true,
    });
  };

  // Form state submit
  const onFormSubmitHandler = async (
    createOneMaterialRequestDto: CreateOneMaterialRequestDto,
  ) => {
    try {
      setIsRequestLoading(true);

      // Create material
      const createdMaterial = await createMaterial({
        createOneMaterialRequestDto,
      }).unwrap();

      if (croppedImagesList.length > 0) {
        croppedImagesList.map((file) => formData.append("images", file));

        // Create multimedia
        await createImages({
          id: createdMaterial.id as number,
          body: formData as any,
        }).unwrap();

        formData.delete("images");
      }

      toast.success("Статья успешно опубликована");
      navigate("/admin/requests");
    } catch (e) {
      toast.error(em(e));
    } finally {
      setIsRequestLoading(false);
    }
  };

  // Form state
  const data = form.watch();
  const errors = form.formState.errors;

  return (
    <>
      <section className={styles.AdminMaterialPage}>
        <div className={styles.AdminMaterialPage__header}>
          <BaseGoBack
            className={styles.AdminMaterialPage__go_back}
            text="Назад к списку организаций"
            to="/admin/requests"
          />
          <BaseTypography
            className={styles.AdminMaterialPage__title}
            variant="h2"
            type="at"
          >
            Создание статьи для портала
          </BaseTypography>
        </div>
        <form
          className={styles.AdminMaterialPage__form}
          onSubmit={form.handleSubmit(onFormSubmitHandler)}
          id="id-material-form"
        >
          <ul className={styles.AdminMaterialPage__list}>
            <li className={styles.AdminMaterialPage__item}>
              <BaseTextField
                onChange={(e) => onFormStateHandler("title", e.target.value)}
                label="Заголовок статьи"
                name="title"
                value={data.title}
              />
              <BaseInputError message={errors["title"]?.message} />
            </li>
            <li className={styles.AdminMaterialPage__item}>
              {croppedImagesList.length === 0 ? (
                <ImageUploader onChange={onUploadImageHandler} value="" />
              ) : (
                <div className={styles.AdminMaterialPage__images}>
                  {croppedImagesList.map((file, key) => (
                    <ImagePreview
                      onDelete={onDeleteCroppedImage(key)}
                      onUpload={onUploadImageHandler}
                      value={file}
                      key={key}
                      disabled={croppedImagesList.length === 3}
                    />
                  ))}
                </div>
              )}
            </li>
            <li className={styles.AdminMaterialPage__item}>
              <BaseTextField
                onChange={(e) => onFormStateHandler("sign", e.target.value)}
                label="Подпись к фото"
                value={data.sign}
                name="sign"
              />
              <BaseInputError message={errors["sign"]?.message} />
            </li>
            <li className={styles.AdminMaterialPage__item}>
              <BaseTextarea
                onChange={(e) => onFormStateHandler("text", e.target.value)}
                label="Текст"
                value={data.text}
                name="text"
              />
              <BaseInputError message={errors["text"]?.message} />
            </li>
            <li className={styles.AdminMaterialPage__item}>
              <Select
                onChange={(selected) => {
                  onFormStateHandler("whoHelpTypes", selected);
                }}
                value={data.whoHelpTypes}
                label="Сфера деятельности"
                multiple={true}
                variant="select"
              >
                <Select.OptionList>
                  {whoHelpTypes &&
                    whoHelpTypes?.map((item) => (
                      <Select.OptionItem
                        selected={isSelectedOption(data.whoHelpTypes, item.id)}
                        key={item.id}
                        {...item}
                      />
                    ))}
                </Select.OptionList>
              </Select>
              <BaseInputError message={errors["whoHelpTypes"]?.message} />
            </li>
            <li>
              <Select
                onChange={(selected) => {
                  if (!Array.isArray(selected)) {
                    onFormStateHandler("author", selected);
                  }
                }}
                value={data.author}
                label="Автор статьи"
                multiple={false}
                variant="select"
              >
                <Select.OptionList>
                  {materialAuthors &&
                    materialAuthors.map((item) => (
                      <Select.OptionItem
                        selected={data?.author?.id === item.id}
                        key={item.id}
                        {...item}
                      />
                    ))}
                </Select.OptionList>
              </Select>
              <BaseInputError message={errors["author"]?.message} />
            </li>
          </ul>
        </form>
        <div
          className={styles.AdminMaterialPage__buttons}
          hidden={isRequestLoading}
        >
          <BaseButton
            className={styles.AdminMaterialPage__button}
            variant="main_contained_tangerine"
            type="submit"
            form="id-material-form"
          >
            Сохранить
          </BaseButton>
          <BaseButton
            className={styles.AdminMaterialPage__button}
            variant="main_outlined_tangerine"
            onClick={() => navigate("/admin/requests")}
          >
            Отмена
          </BaseButton>
        </div>
        <div className={styles.AdminMaterialPage__loader}>
          {isRequestLoading && <BaseLoader />}
        </div>
        {uploadedImagesList?.map((file, key) => (
          <Cropper
            onChange={onUploadCroppedImage}
            onCancel={onDeleteImageHandler(key)}
            file={file}
            key={key}
          />
        ))}
      </section>
    </>
  );
};

const defaultValues = {
  title: "",
  text: "",
  sign: "",
  whoHelpTypes: [],
};

export default AdminMaterialPage;

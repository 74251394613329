import React from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import toast from "react-hot-toast/headless";

import {
  BaseButton,
  BaseDate,
  BaseImage,
  BasePagination,
  BaseTab,
  BaseTabs,
} from "components/base";
import { AdminFooter, AdminHeader } from "content/admin";
import { Table } from "content";

import { OrganizationStatusEnum, OrganizationTypeEnum } from "common/enum";
import {
  useOrganizationsControllerUpdateStatusMutation,
  useOrganizationsControllerDeleteOneMutation,
  useOrganizationsControllerGetAllQuery,
  OrganizationsControllerGetAllApiArg,
  UpdateOrganizationStatusRequestDto,
  Organizations,
} from "app/api/hooks/organizations";
import { BaseTableColumns } from "content/Table/Table.types";
import { OptionType } from "common/types/option.type";
import svgOpenEyeIcon from "common/svg/svg-open-eye-icon.svg";
import svgEditIcon from "common/svg/svg-edit-icon.svg";
import svgBinIcon from "common/svg/svg-bin-icon.svg";
import styles from "./AdminRequests.module.scss";
import { em } from "common/funcs/em";

const AdminRequestsPage = () => {
  const navigate = useNavigate();
  const [limit] = React.useState(6);
  const [page] = React.useState(1);

  // Filters of the request
  const [filters, setFilters] =
    React.useState<OrganizationsControllerGetAllApiArg>({
      organizationStatusId: OrganizationStatusEnum["Кандидат"],
      organizationTypeId: OrganizationTypeEnum["НКО"],
      limit,
      page,
    });

  // Main filter handler
  const onHandleFilters = <T,>(payload: T) => {
    setFilters((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  // Requests to API
  const {
    data: organizations,
    isLoading,
    refetch,
  } = useOrganizationsControllerGetAllQuery(filters, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    pollingInterval: 25000,
  });
  const [updateOrganizationStatysById] =
    useOrganizationsControllerUpdateStatusMutation();
  const [deleteOrganizationOneById] =
    useOrganizationsControllerDeleteOneMutation();

  // Tabs state
  const [value, setValue] = React.useState(0);

  // Tabs handler
  const onHandleTabsChange = (index: number, option: OptionType) => {
    onHandleFilters({ organizationStatusId: option.id, limit, page });
    setValue(index);
  };

  // The function updates organization status
  const onHandleUpdateStatus = async (
    id: number,
    updateOrganizationStatusRequestDto: UpdateOrganizationStatusRequestDto,
  ) => {
    try {
      await toast.promise(
        updateOrganizationStatysById({
          id,
          updateOrganizationStatusRequestDto,
        }).unwrap(),
        {
          loading: "Запрос обрабатывается",
          success: "Запрос выполнен успешно",
          error: "Не удалось выполнить запрос",
        },
      );
      refetch();
    } catch (e) {
      toast.error(em(e));
    }
  };

  // The function delete organization
  const onHandleDeleteOrganization = async (id: number) => {
    try {
      await toast.promise(deleteOrganizationOneById({ id }).unwrap(), {
        loading: "Запрос обрабатывается",
        success: "Запрос выполнен успешно",
        error: "Не удалось выполнить запрос",
      });
      refetch();
    } catch (e) {
      toast.error(em(e));
    }
  };

  return (
    <>
      <div className={styles.AdminRequestsPage}>
        <AdminHeader title="НКО">
          <Link to={`/admin/organizations/`}>
            <BaseButton
              className={styles.AdminRequestsPage__button}
              variant="main_contained_tangerine"
            >
              Добавить фонд
            </BaseButton>
          </Link>
        </AdminHeader>
        <div className={styles.AdminRequestsPage__body}>
          <div className={styles.AdminRequestsPage__switches}>
            <BaseTabs
              handleChange={onHandleTabsChange}
              value={value}
              items={items}
            />
          </div>
          <div className={styles.AdminRequestsPage__tabs}>
            {items.map(({ id }, index) => (
              <BaseTab
                className={styles.AdminRequestsPage__tab}
                value={value}
                index={index}
                key={id}
              >
                <Table<AdminRequestTableType>
                  isLoading={isLoading}
                  columns={columns(
                    onHandleUpdateStatus,
                    onHandleDeleteOrganization,
                    navigate,
                  )}
                  data={organizations?.items}
                />
              </BaseTab>
            ))}
          </div>
        </div>
        <AdminFooter>
          <BasePagination
            onChange={(page) => onHandleFilters({ page })}
            count={organizations?.totalPages}
            page={filters.page}
          />
        </AdminFooter>
      </div>
    </>
  );
};

type AdminRequestTableType = Organizations;
type OnHandleUpdateStatus = (
  id: number,
  updateOrganizationStatusRequestDto: UpdateOrganizationStatusRequestDto,
) => Promise<void>;
type OnHandleDeleteOrganization = (id: number) => Promise<void>;

const columns = (
  onHandleUpdateStatus: OnHandleUpdateStatus,
  onHandleDeleteOrganization: OnHandleDeleteOrganization,
  navigate: NavigateFunction,
): BaseTableColumns<AdminRequestTableType> => [
  {
    name: "Название НКО",
    selector: (row) => row?.name || "Не указано",
    style: {
      whiteSpace: "normal",
    },
  },
  {
    name: "Телефон",
    selector: (row) => row?.phoneNumber1 ?? row?.phoneNumber2 ?? "Не указано",
    style: {
      whiteSpace: "nowrap",
    },
  },
  {
    name: "Email",
    selector: (row) => row.user?.email ?? "Не указано",
    style: {
      whiteSpace: "nowrap",
    },
  },
  {
    name: "Город",
    selector: (row) => row.city?.name ?? "Не указано",
    style: {
      whiteSpace: "nowrap",
    },
  },
  {
    name: "Дата",
    selector: (row) => <BaseDate variant="common" value={row.createdAt} />,
    style: {
      whiteSpace: "nowrap",
    },
  },
  {
    name: "",
    selector: (row) =>
      row.organizationStatus.id === OrganizationStatusEnum["Кандидат"] ? (
        <>
          <ul className={styles.AdminRequestsPage__buttons}>
            <li className={styles.AdminRequestsPage__buttons_top}>
              <BaseButton
                variant="main_contained_tangerine"
                onClick={() => {
                  onHandleUpdateStatus(row.id, {
                    statusId: OrganizationStatusEnum["Действующий"],
                  });
                }}
              >
                Одобрить
              </BaseButton>
              <BaseButton
                variant="main_outlined_tangerine"
                onClick={() => {
                  onHandleUpdateStatus(row.id, {
                    statusId: OrganizationStatusEnum["Отказ"],
                  });
                }}
              >
                Отклонить
              </BaseButton>
            </li>
            <li className={styles.AdminRequestsPage__buttons_bot}>
              <BaseButton
                variant="main_text"
                onClick={() => {
                  onHandleUpdateStatus(row.id, {
                    statusId: OrganizationStatusEnum["На доработке"],
                  });
                }}
              >
                Направить на доработку
              </BaseButton>
              <BaseButton
                variant="main_text"
                onClick={() => {
                  navigate(`/admin/organizations/${row.id}?mode=view`);
                }}
              >
                Посмотреть
              </BaseButton>
            </li>
          </ul>
        </>
      ) : (
        <>
          <ul className={styles.AdminRequestsPage__controls}>
            <li className={styles.AdminRequestsPage__control}>
              <Link
                className={styles.AdminRequestsPage__link}
                to={`/admin/organizations/${row.id}?mode=view`}
              >
                <BaseImage
                  src={svgOpenEyeIcon}
                  alt="Посмотреть"
                  height="20"
                  width="20"
                />
              </Link>
            </li>
            <li className={styles.AdminRequestsPage__control}>
              <Link
                className={styles.AdminRequestsPage__link}
                to={`/admin/organizations/${row.id}?mode=update`}
              >
                <BaseImage
                  src={svgEditIcon}
                  alt="Редактировать"
                  height="20"
                  width="20"
                />
              </Link>
            </li>
            <li className={styles.AdminRequestsPage__control}>
              <BaseImage
                onClick={() => onHandleDeleteOrganization(row.id)}
                src={svgBinIcon}
                alt="Удалить"
                height="20"
                width="20"
              />
            </li>
          </ul>
        </>
      ),
    style: {
      whiteSpace: "nowrap",
    },
  },
];

const items: Array<OptionType> = [
  {
    name: "Кандидаты",
    id: 2,
  },
  {
    name: "Действующие",
    id: 3,
  },
  {
    name: "Отказ",
    id: 4,
  },
  {
    name: "На доработке",
    id: 5,
  },
];

export default AdminRequestsPage;

import React from 'react';

export type FormTabsContextType = {
  name: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedValue: string;
};

export const FormTabsContext = React.createContext<FormTabsContextType>(
  {} as FormTabsContextType,
);

import { OptionType } from 'common/types/option.type';
import React from 'react';

import { FormTabsContext } from './_context';
import { FormTab } from './FormTab/FormTab';

import { useEffectAfterMount } from 'hooks/useEffectAfterMount';
import styles from './FormTabs.module.scss';

type PropsType = {
  onChange: (selected: OptionType) => void;
  defaultValue: OptionType;
  children: React.ReactNode;
  name: string;

  Tab?: typeof FormTab;
};

const FormTabs = ({ children, onChange, defaultValue, ...rest }: PropsType) => {
  const [state, setState] = React.useState<OptionType>(defaultValue);

  useEffectAfterMount(() => {
    onChange(state);
  }, [state]);

  const onChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const name = e.target.dataset.label as string;

      setState({
        id: +value,
        name,
      });
    },
    [state],
  );

  return (
    <FormTabsContext.Provider
      value={{
        onChangeHandler,
        selectedValue: state.name,
        ...rest,
      }}
    >
      <ul className={styles.FormTabs}>{children}</ul>
    </FormTabsContext.Provider>
  );
};

FormTabs.Tab = FormTab;

export default FormTabs;

import * as yup from "yup";

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[0-9])(?=.{6,})/;

const CODE_REGEX = /^(?=.*[0-9])(?=.{4,})/;

const LINK_REGEX =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const NO_VALID_PASSWORD =
  "Пароль не менее 6 знаков - (латиница и цифры, без спецсимволов)";
const NO_VALID_EMAIL = "Некоректный email";
const REQUIRED_FIELD = "Обязательное поле";

const NO_VALID_LINK = "Неверный формат ссылки";

// AUTH / REGISTRATION / RESTORE
export const SignonScheme = yup.object({
  email: yup.string().email(NO_VALID_EMAIL).required(REQUIRED_FIELD),
  password: yup.string().required(REQUIRED_FIELD),
});

export const SignupNeedHelpScheme = yup.object({
  email: yup.string().email(NO_VALID_EMAIL).required(REQUIRED_FIELD),
  password: yup
    .string()
    .matches(PASSWORD_REGEX, NO_VALID_PASSWORD)
    .required(REQUIRED_FIELD),
  confirmationCode: yup
    .string()
    .matches(CODE_REGEX, "Код должен состоять из 6 цифр")
    .required(REQUIRED_FIELD),
});

export const SignupWantHelpScheme = SignupNeedHelpScheme.shape({
  organizationTypeId: yup.number().required(REQUIRED_FIELD),
});

export const ForgotPasswordScheme = SignupNeedHelpScheme;

// UPDATE REQUEST
export const UpdateRequestScheme = yup.object({
  name: yup.string().required(REQUIRED_FIELD),
  description: yup
    .string()
    .max(15000, "Не более 15000 символов")
    .required(REQUIRED_FIELD),
  whoHelpTypes: yup.array().min(1, REQUIRED_FIELD).required(REQUIRED_FIELD),
  address: yup
    .string()
    .max(300, "Не более 300 символов")
    .required(REQUIRED_FIELD),
});

// CREATE REQUEST
export const CreateRequestScheme = UpdateRequestScheme;

// MAILING
export const MailingScheme = yup.object().shape(
  {
    text: yup
      .string()
      .max(15000, "Не более 10000 символов")
      .required(REQUIRED_FIELD),
    mailingWhom: yup.object().required(REQUIRED_FIELD),
    isSendToEmail: yup.boolean(),
    isSendToPhone: yup.boolean(),
  },
  [["isSendToEmail", "isSendToPhone"]],
);

// OGANIZATION
export const ProfileBaseOrganizationScheme = yup.object({
  lastName: yup.string().required(REQUIRED_FIELD),
  firstName: yup.string().required(REQUIRED_FIELD),
  email: yup.string().email(NO_VALID_EMAIL).required(REQUIRED_FIELD),
  INN: yup
    .string()
    .matches(/^\d+$/, "ИНН должен состоять из цифр")
    .required(REQUIRED_FIELD),
  phoneNumber1: yup
    .string()
    .matches(/^\+7(\d{10})$/, "После +7 должно быть 10 цифр")
    .required(REQUIRED_FIELD),
  city: yup.object().shape({}).nullable().required(REQUIRED_FIELD),
  address: yup.string().required(REQUIRED_FIELD),
  whatHelpTypes: yup.array().min(1, REQUIRED_FIELD).required(REQUIRED_FIELD),
  whoHelpTypes: yup.array().min(1, REQUIRED_FIELD).required(REQUIRED_FIELD),
  description: yup
    .string()
    .max(3000, "Не более 3000 символов")
    .required(REQUIRED_FIELD),
});

export const ProfileUpdateOrganizationScheme =
  ProfileBaseOrganizationScheme.shape({
    name: yup.string().required(REQUIRED_FIELD),
    siteLink: yup
      .string()
      .matches(LINK_REGEX, NO_VALID_LINK)
      .required(REQUIRED_FIELD),
    phoneNumber2: yup
      .string()
      .matches(/^\+7(\d{10})$/, "После +7 должно быть 10 цифр")
      .required(REQUIRED_FIELD),
  });

export const ProfileUpdateNonprofiScheme = ProfileBaseOrganizationScheme.shape({
  name: yup.string().required(REQUIRED_FIELD),
  siteLink: yup
    .string()
    .matches(LINK_REGEX, NO_VALID_LINK)
    .required(REQUIRED_FIELD),
  phoneNumber2: yup
    .string()
    .matches(/^\+7(\d{10})$/, "После +7 должно быть 10 цифр")
    .required(REQUIRED_FIELD),
  vkLink: yup
    .string()
    .matches(LINK_REGEX, NO_VALID_LINK)
    .required(REQUIRED_FIELD),
  // okLink: yup.string().matches(LINK_REGEX, NO_VALID_LINK).optional(),
  okLink: yup.string().optional(),
  isAcceptedCashPolicy: yup
    .boolean()
    .isTrue(REQUIRED_FIELD)
    .required(REQUIRED_FIELD),
  isAcceptedNoCashPolicy: yup
    .boolean()
    .isTrue(REQUIRED_FIELD)
    .required(REQUIRED_FIELD),
});

// ADMIN ORGANIZATION CREATION
export const ProfileCreateNonprofitScheme = yup.object({
  lastName: yup.string().notRequired(),
  firstName: yup.string().notRequired(),
  INN: yup.string().notRequired(),
  phoneNumber1: yup.string().notRequired(),
  city: yup.object().shape({}).nullable().notRequired(),
  address: yup.string().notRequired(),
  whatHelpTypes: yup.array().notRequired(),
  whoHelpTypes: yup.array().notRequired(),
  description: yup.string().notRequired(),
  email: yup.string().email(NO_VALID_EMAIL).required(REQUIRED_FIELD),
  name: yup.string().notRequired().notRequired(),
  siteLink: yup.string().notRequired(),
  phoneNumber2: yup.string().notRequired(),
  vkLink: yup.string().matches(LINK_REGEX, NO_VALID_LINK).notRequired(),
  okLink: yup.string().matches(LINK_REGEX, NO_VALID_LINK).notRequired(),
  isAcceptedCashPolicy: yup.boolean().notRequired(),
  isAcceptedNoCashPolicy: yup.boolean().notRequired(),
});

// MATERIAL
export const MaterialScheme = yup.object({
  title: yup.string().max(150, "Не более 150 символов").notRequired(),
  text: yup
    .string()
    .max(3000, "Не более 3000 символов")
    .required(REQUIRED_FIELD),
  sign: yup.string().max(100, "Не более 100 символов").required(REQUIRED_FIELD),
  authorId: yup.number(),
  author: yup.object().shape({}).nullable().required(REQUIRED_FIELD),
  whoHelpTypes: yup.array().notRequired(),
});

export const MailingSubscriptionScheme = yup.object({
  email: yup.string().email(NO_VALID_EMAIL).required(REQUIRED_FIELD),
});

import { BaseTypography } from "components/base";

import { mcn } from "common/funcs/mcn.func";
import styles from "./AdminHeader.module.scss";

type PropsType = {
  children: React.ReactNode;
  className?: string;
  title: string;
  variant?: "common" | "filters"
}

const AdminHeader = ({ children, className = "", title, variant = "common" }: PropsType) => {
  return (
    <>
      <div className={mcn([styles.AdminHeader, styles["AdminHeader_" + variant], className])}>
        <BaseTypography
          className={styles.AdminHeader__title}
          variant="h2"
          type="at"
        >
          {title}
        </BaseTypography>
        <div className={styles.AdminHeader__content}>
          {children}
        </div>
      </div>
    </>
  );
};

export default AdminHeader;

import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { BaseInputError, BaseOutlinedInput } from 'components/base';

import { csw } from 'common/funcs/csw.func';
import { useCounter } from 'hooks/useCounter';

type PropsType = {
  placeholder: string;
  onChange: (text: string) => void;
  onClick: () => void;
  errorMessage?: string;
  value: string;
};

const FormCode = React.memo(
  ({ onClick, onChange, errorMessage = '', ...rest }: PropsType) => {
    const { counter, setCounter } = useCounter(0);
    return (
      <>
        <Box>
          <Grid container alignItems="end">
            <Grid item xs={4}>
              <BaseOutlinedInput onChange={onChange} type={'text'} {...rest} />
            </Grid>
            <Grid item xs={8}>
              {counter === 0 ? (
                <>
                  <Typography
                    fontSize={csw('1.4rem', '1.4rem', '1.4rem', '1.4rem')}
                    textAlign="end"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setCounter(60);
                      onClick();
                    }}
                    color="#109CF1"
                  >
                    Отправить код
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    fontSize={csw('1.4rem', '1.4rem', '1.4rem', '1.4rem')}
                    textAlign="right"
                    sx={{ cursor: 'pointer' }}
                    color="#109CF1"
                  >
                    Отправить еще раз через {counter} сек
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box textAlign="left">
          <BaseInputError message={errorMessage} />
        </Box>
      </>
    );
  },
);

export default FormCode;

import { Route, Routes } from 'react-router';
import { DefaultAdmin } from 'layouts';

import { CreateMailing } from 'pages/private/admin-mailing/CreateMailing';
import { UpdateMailing } from 'pages/private/admin-mailing/UpdateMailing';
import { CreateOrganizationPage, UpdateOrganizationPage } from 'pages/private/admin-organization';
import { WhoHelp } from 'pages/private/admin-appeals/WhoHelp';
import {
  AdminMaterialPage,
  AdminRequestsPage,
  AdminAppealsPage,
  AdminMailingPage,
} from 'pages/private';

const AdminRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DefaultAdmin />}>
          <Route path="material" element={<AdminMaterialPage />} />
          <Route path="requests" element={<AdminRequestsPage />} />
          <Route path="appeals" element={<AdminAppealsPage />}>
            <Route path=":id" element={<WhoHelp />} />
          </Route>
          <Route path="mailing/" element={<AdminMailingPage />}>
            <Route path="create_mailing" element={<CreateMailing />} />
            <Route path="update_mailing/:id" element={<UpdateMailing />} />
          </Route>
          <Route path="organizations/" element={<CreateOrganizationPage />} />
          <Route path="organizations/:id" element={<UpdateOrganizationPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AdminRoutes;

import { BaseTableColumns, BaseTableData } from './Table.types';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table as TableBase
} from '@mui/material';

import { BaseLoader } from 'components/base';
import { NoEntries } from 'content';

import { mcn } from 'common/funcs/mcn.func';
import styles from './Table.module.scss';

type PropsType<T> = {
  className?: string;
  columns: BaseTableColumns<T>;
  data: BaseTableData<T>;
  isLoading?: boolean;
  onClick?: <T extends number>(id: T) => void;
};

const Table = <T,>({
  className = '',
  columns,
  data,
  isLoading,
  ...rest
}: PropsType<T>) => {
  return (
    <>
      <TableBase className={mcn([styles.Table, className])}>
        <TableHead className={styles.Table__head}>
          <TableRow className={styles.Table__head_row}>
            <TableHeadColumns<T> columns={columns} />
          </TableRow>
        </TableHead>
        <TableBody className={styles.Table__body}>
          <TableBodyColumns<T>
            columns={columns}
            data={data}
            isLoading={isLoading}
            {...rest}
          />
        </TableBody>
      </TableBase>
    </>
  );
};

const TableHeadColumns = <T,>({
  columns,
}: {
  columns: BaseTableColumns<T>;
}) => {
  return (
    <>
      {columns.map(({ name }, key) => (
        <TableCell className={styles.Table__head_cell} key={key}>
          {name}
        </TableCell>
      ))}
    </>
  );
};

const TableBodyColumns = <T,>({
  columns,
  data,
  isLoading,
  onClick,
}: PropsType<T>) => {
  return isLoading ? (
    <>
      <TableRow className={styles.Table__row}>
        <TableCell
          className={styles.Table__cell_loader}
          colSpan={columns?.length}
        >
          <BaseLoader />
        </TableCell>
      </TableRow>
    </>
  ) : data?.length > 0 ? (
    <>
      {data.map((row: T, dataIndex: number) => (
        <TableRow className={styles.Table__row} key={dataIndex} onClick={() => onClick && onClick(((row as T)["id" as unknown as keyof T]) as number)}>
          {columns.map(({ selector, ...rest }, columnIndex) => (
            <TableCell
              className={styles.Table__cell}
              key={columnIndex}
              {...rest}
            >
              {selector && selector(row)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  ) : (
    <>
      <TableRow>
        <TableCell
          colSpan={columns?.length}
        >
          <NoEntries />
        </TableCell>
      </TableRow>
    </>
  );
};

export default Table;

import React from 'react';

import { BaseDate, BaseImage } from 'components/base';

import { Materials } from 'app/api/hooks/materials';
import styles from './MaterialPreview.module.scss';

type PropsType = Materials;

const MaterialPreview = ({ title, createdAt, multimedia }: PropsType) => {
  // Image preview
  const preview = multimedia?.length > 0 ? multimedia.find(({ device }) => device === "mobile")?.path : undefined;

  return (
    <>
      <article className={styles.MaterialPreview}>
        <div className={styles.MaterialPreview__header}>
          {
            !preview
              ? (
                <div className={styles.MaterialPreview__not_found_image}>
                  <p className={styles.MaterialPreview__not_found_text}>
                    Изображение <br />не загруженно
                  </p>
                </div>
              )
              : (
                < BaseImage
                  className={styles.MaterialPreview__image}
                  src={preview}
                  lazyLoading={true}
                  height="176.34"
                  width="358"
                  alt="Превью материала"
                />
              )
          }
        </div>
        <ul className={styles.MaterialPreview__body}>
          <li className={styles.MaterialPreview__item}>
            <h3 className={styles.MaterialPreview__title}>
              {
                title.trim().length > 62
                  ? title.trim().slice(0, 62) + "..."
                  : title.trim()
              }
            </h3>
          </li>
          <li className={styles.MaterialPreview__item}>
            <BaseDate
              className={styles.MaterialPreview__date}
              variant="material"
              value={createdAt}
            />
          </li>
        </ul>
      </article>
    </>
  );
};

const MemoizedMaterialPreview = React.memo(MaterialPreview);

export default MemoizedMaterialPreview;

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { To, useNavigate } from 'react-router';
import { Typography } from '@mui/material';

import { mcn } from 'common/funcs/mcn.func';
import styles from './BaseGoBack.module.scss';

type PropsType = {
  className?: string;
  text?: string;
  to?: (To & number) | string;
};

const BaseGoBack = ({ className = '', text = 'Назад', to = -1 }: PropsType) => {
  const navigation = useNavigate();
  return (
    <>
      <ul
        className={mcn([styles.BaseGoBack, className])}
        onClick={() => navigation(to as any)}
      >
        <li className={styles.BaseGoBack__item}>
          <ArrowBackIosNewIcon className={styles.BaseGoBack__icon} />
        </li>
        <li className={styles.BaseGoBack__item}>
          <Typography
            className={styles.BaseGoBack__text}
            variant="body1"
            fontFamily="Montserrat, sans-serif"
            fontWeight="600"
            lineHeight="1.8rem"
            fontSize="1.6rem"
          >
            {text}
          </Typography>
        </li>
      </ul>
    </>
  );
};

export default BaseGoBack;

import { Outlet } from 'react-router';

import { Footer, Header } from 'components/partials';
import { BaseContainer } from 'components/base';
import { MailingSubscription } from 'content';

import styles from './Default.module.scss';

type PropsType = {
  subscription?: boolean;
};

const Default = ({ subscription = false }: PropsType) => {
  return (
    <>
      <div className={styles.Default}>
        <div className={styles.Default__header}>
          <Header />
        </div>
        <div className={styles.Default__body}>
          <BaseContainer>
            <Outlet />
          </BaseContainer>
        </div>
        <div className={styles.Default__footer}>
          {subscription && <MailingSubscription />}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Default;

import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { RoutePrivate, RoutePublic } from "components/protection";
import {
  AccountRoutes,
  RestoreRoutes,
  PublicRoutes,
  SignonRoutes,
  SignupRoutes,
  AdminRoutes,
  SignoutRoutes,
} from "routes";

import { NavigatorExtendedType } from "common/types/navigator-extended.type";
import { RoleEnum } from "common/enum";

const App = () => {
  const location = useLocation();

  React.useEffect(() => {
    if ("virtualKeyboard" in navigator) {
      (navigator as NavigatorExtendedType).virtualKeyboard.overlaysContent =
        true;
    }
  }, []);

  return (
    <>
      <Routes location={location}>
        <Route path="/*" element={<PublicRoutes />} />

        <Route element={<RoutePublic />}>
          <Route path="/restore/*" element={<RestoreRoutes />} />
          <Route path="/signup/*" element={<SignupRoutes />} />
          <Route path="/signon/*" element={<SignonRoutes />} />
        </Route>

        <Route element={<RoutePrivate allowedRoles={[RoleEnum.USER]} />}>
          <Route path="/account/*" element={<AccountRoutes />} />
        </Route>

        <Route element={<RoutePrivate allowedRoles={[RoleEnum.ADMIN]} />}>
          <Route path="/admin/*" element={<AdminRoutes />} />
        </Route>

        <Route path="/actions/*" element={<SignoutRoutes />} />
      </Routes>
    </>
  );
};

export default App;

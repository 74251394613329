import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { RequestStatusEnum } from 'common/enum';
import { mcn } from 'common/funcs/mcn.func';
import styles from './RequestStatus.module.scss';

type PropsType = {
  className?: string;
  name?: string;
  hidden?: boolean;
};

const RequestStatus = ({ className = "", name = "", ...rest }: PropsType) => {
  return name
    ? (
      <>
        <div className={mcn([styles.RequestStatus, className])} {...rest}>
          <FiberManualRecordIcon
            className={styles.RequestStatus__icon}
            htmlColor={colors[name]}
          />
          {name}
        </div>
      </>
    )
    : null;
};

const colors = {
  [RequestStatusEnum[1]]: '#109CF1',
  [RequestStatusEnum[2]]: '#FBCEB1',
  [RequestStatusEnum[3]]: '#2ED47A',
  [RequestStatusEnum[4]]: '#FA4D4A',
  [RequestStatusEnum[5]]: '#4D5BAA',
};

export default RequestStatus;

enum LocalStorageEnum {
  FILTERS_BY_MATERIALS = 'FILTERS_BY_MATERIALS',
  FILTERS_BY_REQUESTS = 'FILTERS_BY_REQUESTS',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  USER = 'USER',
}

export type LocalStorageKeys = keyof typeof LocalStorageEnum;

export const setIntoStorage = <T>(key: LocalStorageKeys, data: T) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getFromStorage = (key: LocalStorageKeys) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const delFromStorage = (key: LocalStorageKeys) => {
  localStorage.removeItem(key);
};

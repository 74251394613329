import { ThemeOptions } from '@mui/material';

export const MuiSvgIcon: ThemeOptions['components'] = {
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        maxHeight: '2.2rem',
        minHeight: '2.2rem',
        maxWidth: '2.2rem',
        minWidth: '2.2rem',
      },
    },
  },
};

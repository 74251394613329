import { Route, Routes } from 'react-router';
import { Default } from 'layouts';

import { ProfilePage, ProfileActionsPage, ProfileWaitingPage, ProfileRejectPage } from 'pages/private';
import { OrganizationTypeEnum } from 'common/enum';
import { RouteOrganization } from 'components/protection';
import { CreateRequest } from 'pages/private/profile-actions/NeedHelp/CreateRequest';
import { UpdateRequest } from 'pages/private/profile-actions/NeedHelp/UpdateRequest';
import CreateAppeal from 'pages/private/profile-actions/WantHelp/CreateAppeal/CreateAppeal';

const AccountRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Default />}>
          <Route path="" element={<ProfilePage />} />
          <Route path="actions" element={<ProfileActionsPage />}>

            {/* For organizations, that is NGO */}
            <Route element={<RouteOrganization allowedOrganizationsTypesIDs={[OrganizationTypeEnum.НКО]} />}>
              <Route path="create_request" element={<CreateRequest />} />
              <Route path="update_request/:id" element={<UpdateRequest />} />
            </Route>

            {/* For organizations, that is't NGO */}
            <Route element={<RouteOrganization allowedOrganizationsTypesIDs={[OrganizationTypeEnum.ЮРЛИЦО, OrganizationTypeEnum.ФИЗЛИЦО, OrganizationTypeEnum.ИП]} />}>
              <Route path="create_appeal/:id" element={<CreateAppeal />} />
            </Route>

          </Route>

          {/* For waiting of the NGO organization */}
          <Route path="waiting" element={<ProfileWaitingPage />} />
          <Route path="reject" element={<ProfileRejectPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AccountRoutes;

import React from 'react';
import {
  InputAdornment,
  OutlinedInput,
  FormControl,
  IconButton,
} from '@mui/material';

import { BaseImage } from '../';

import { BaseOutlinedInputContext, useInputContext } from './_context';
import { mcn } from 'common/funcs/mcn.func';
import svgCloseEye from 'common/svg/svg-close-eye.svg';
import svgOpenEye from 'common/svg/svg-open-eye.svg';
import styles from './BaseOutlinedInput.module.scss';

type PropsType = {
  className?: string;
  type?: 'text' | 'password';
  onChange: (text: string) => void;
  children?: React.ReactNode;
  placeholder?: string;
  variant?: 'subscription';
  value: string;

  Visibility?: typeof BaseOutlinedInputVisibility;
  VisibilityOff?: typeof BaseOutlinedInputVisibilityOff;
};

type State = {
  showPassword: boolean;
  text: string;
};

const BaseOutlinedInput = ({ className = '', children, placeholder, variant, type = 'text', value, onChange }: PropsType) => {

  // Input state
  const [state, setState] = React.useState<State>({
    showPassword: false,
    text: '',
  });

  // Input onchange handler
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  // Input open password handler
  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  // Input event prevent default settings
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  const inputType = (type === 'text' || state.showPassword) ? 'text' : 'password';

  return (
    <BaseOutlinedInputContext.Provider value={state}>
      <FormControl fullWidth={true}>
        <OutlinedInput
          className={mcn([
            styles.BaseOutlinedInput,
            styles['BaseOutlinedInput_' + variant],
            className,
          ])}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          type={inputType}
          endAdornment={
            type === 'password' ? (
              <InputAdornment position="end">
                <IconButton
                  onMouseDown={handleMouseDownPassword}
                  onClick={handleClickShowPassword}
                  aria-label="toggle password visibility"
                  edge="end"
                  sx={{ marginRight: ".2rem" }}
                >
                  {children}
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            )
          }
        />
      </FormControl>
    </BaseOutlinedInputContext.Provider>
  );
};


const BaseOutlinedInputVisibility = () => {
  const { showPassword } = useInputContext();

  if (showPassword) {
    return null;
  }

  return (
    <>
      <BaseImage
        height="24"
        width="24"
        src={svgOpenEye}
        alt="Открыть"
      />
    </>
  );
};

const BaseOutlinedInputVisibilityOff = () => {
  const { showPassword } = useInputContext();

  if (!showPassword) {
    return null;
  }

  return (
    <>
      <BaseImage
        height="24"
        width="24"
        src={svgCloseEye}
        alt="Закрыть"
      />
    </>
  );
};

BaseOutlinedInput.Visibility = BaseOutlinedInputVisibility;
BaseOutlinedInput.VisibilityOff = BaseOutlinedInputVisibilityOff;

export default BaseOutlinedInput;

import { apiSlice as api } from "../apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    requestStatusesControllerGetAll: build.query<
      RequestStatusesControllerGetAllApiResponse,
      RequestStatusesControllerGetAllApiArg
    >({
      query: () => ({ url: `/api/v1/request-statuses` }),
    }),
    requestsControllerGetAll: build.query<
      RequestsControllerGetAllApiResponse,
      RequestsControllerGetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/requests`,
        params: {
          statusId: queryArg.statusId,
          userId: queryArg.userId,
          organizationId: queryArg.organizationId,
          cityId: queryArg.cityId,
          directionId: queryArg.directionId,
          organizationHelpedId: queryArg.organizationHelpedId,
          published: queryArg.published,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    requestsControllerCreateOne: build.mutation<
      RequestsControllerCreateOneApiResponse,
      RequestsControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/requests`,
        method: "POST",
        body: queryArg.createOneRequestDto,
      }),
    }),
    requestsControllerGetOne: build.query<
      RequestsControllerGetOneApiResponse,
      RequestsControllerGetOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/requests/${queryArg.id}` }),
    }),
    requestsControllerUpdateOne: build.mutation<
      RequestsControllerUpdateOneApiResponse,
      RequestsControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/requests/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateOneRequestDto,
      }),
    }),
    requestsControllerDeleteOne: build.mutation<
      RequestsControllerDeleteOneApiResponse,
      RequestsControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/requests/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    requestsControllerUpdateStatus: build.mutation<
      RequestsControllerUpdateStatusApiResponse,
      RequestsControllerUpdateStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/requests/${queryArg.id}/status`,
        method: "PATCH",
        body: queryArg.updateStatusRequestDto,
      }),
    }),
    requestsControllerSendHelpByRequest: build.mutation<
      RequestsControllerSendHelpByRequestApiResponse,
      RequestsControllerSendHelpByRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/requests/${queryArg.id}/help`,
        method: "PATCH",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type RequestStatusesControllerGetAllApiResponse =
  /** status 200  */ RequestStatuses[];
export type RequestStatusesControllerGetAllApiArg = void;
export type RequestsControllerGetAllApiResponse = /** status 200  */ any & {
  items?: Requests[];
  totalPages?: number;
  count?: number;
};
export type RequestsControllerGetAllApiArg = {
  statusId?: number;
  userId?: number;
  organizationId?: number;
  cityId?: number;
  /** Allow to get entries by category id */
  directionId?: number[];
  organizationHelpedId?: number;
  published?: boolean;
  page: number;
  limit: number;
};
export type RequestsControllerCreateOneApiResponse =
  /** status 200  */ Requests;
export type RequestsControllerCreateOneApiArg = {
  createOneRequestDto: CreateOneRequestDto;
};
export type RequestsControllerGetOneApiResponse = /** status 200  */ Requests;
export type RequestsControllerGetOneApiArg = {
  id: number;
};
export type RequestsControllerUpdateOneApiResponse =
  /** status 200  */ Requests;
export type RequestsControllerUpdateOneApiArg = {
  id: number;
  updateOneRequestDto: UpdateOneRequestDto;
};
export type RequestsControllerDeleteOneApiResponse =
  /** status 200  */ Requests;
export type RequestsControllerDeleteOneApiArg = {
  id: number;
};
export type RequestsControllerUpdateStatusApiResponse =
  /** status 200  */ Requests;
export type RequestsControllerUpdateStatusApiArg = {
  id: number;
  updateStatusRequestDto: UpdateStatusRequestDto;
};
export type RequestsControllerSendHelpByRequestApiResponse =
  /** status 200  */ Requests;
export type RequestsControllerSendHelpByRequestApiArg = {
  id: number;
};
export type OrganizationStatuses = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type OrganizationTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type WhatHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type UserSessions = {
  id: number;
  refreshToken?: string;
  fingerPrint?: string;
  userId: number;
  user: Users;
};
export type MaterialAuthors = {
  id: number;
  name: string;
  Materials: Materials[];
};
export type Materials = {
  id: number;
  title: string;
  sign: string;
  text: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  multimedia: Multimedia[];
  authorId: number;
  author: MaterialAuthors;
};
export type Multimedia = {
  id: number;
  fileSize: number;
  fileName: string;
  originalFileName: string;
  mimeType: string;
  path: string;
  device?: string;
  createdAt: string;
  updatedAt: string;
  userId?: number;
  user?: Users;
  organizationId?: number;
  organization?: Organizations;
  materialsId?: number;
  materials?: Materials;
};
export type UserRoles = {
  id: number;
  name: string;
  users: Users[];
};
export type Users = {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  vkLink?: string;
  okLink?: string;
  isAcceptedCashPolicy: boolean;
  isAcceptedNoCashPolicy: boolean;
  createdAt: string;
  updatedAt: string;
  sessions?: UserSessions;
  origanization?: Organizations;
  requests: Requests[];
  avatar?: Multimedia;
  roleId: number;
  role: UserRoles;
};
export type Cities = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type Organizations = {
  id: number;
  name?: string;
  siteLink?: string;
  INN?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  address?: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  organizationStatusId: number;
  organizationStatus: OrganizationStatuses;
  organizationTypeId: number;
  organizationType: OrganizationTypes;
  whatHelpTypes: WhatHelpTypes[];
  whoHelpTypes: WhoHelpTypes[];
  userId: number;
  user: Users;
  documents: Multimedia[];
  requests: Requests[];
  cityId?: number;
  city?: Cities;
};
export type WhoHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
  materials: Materials[];
  requests: Requests[];
};
export type Requests = {
  id: number;
  name: string;
  description: string;
  address: string;
  organizationHelpedId?: number;
  publishedAt?: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  statusId: number;
  status: RequestStatuses;
  organizationId: number;
  organization: Organizations;
  userId: number;
  user: Users;
};
export type RequestStatuses = {
  id: number;
  name: string;
  requests: Requests[];
};
export type OptionDto = {
  id: number;
  name: string;
};
export type CreateOneRequestDto = {
  name: string;
  description: string;
  address: string;
  whoHelpTypes: OptionDto[];
};
export type UpdateOneRequestDto = {
  name: string;
  description: string;
  address: string;
  whoHelpTypes: OptionDto[];
};
export type UpdateStatusRequestDto = {
  statusId: number;
};
export const {
  useRequestStatusesControllerGetAllQuery,
  useRequestsControllerGetAllQuery,
  useRequestsControllerCreateOneMutation,
  useRequestsControllerGetOneQuery,
  useRequestsControllerUpdateOneMutation,
  useRequestsControllerDeleteOneMutation,
  useRequestsControllerUpdateStatusMutation,
  useRequestsControllerSendHelpByRequestMutation,
} = injectedRtkApi;

import { Box, BoxProps } from '@mui/material';

export const FormCardRow = <C extends React.ElementType>({
  children,
  ...rest
}: BoxProps<C, { component?: C }>) => {
  return (
    <>
      <Box {...rest}>{children}</Box>
    </>
  );
};

export const csw = <T>(w1440: T, w1024: T, w768: T, w480: T) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  if (window.innerWidth > 1024) {
    return w1440;
  } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
    return w1024;
  } else if (window.innerWidth <= 768 && window.innerWidth > 480) {
    return w768;
  } else if (window.innerWidth <= 480) {
    return w480;
  }
};

import React from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import toast from "react-hot-toast/headless";

import { BaseLoader, BasePage } from "components/base";
import { ProfileOrganization } from "./ProfileOrganization";
import { ProfilePerson } from "./ProfilePerson";
import { ProfileNonprofit } from "./ProfileNonprofit";
import { OrganizationUser } from "content";

import {
  ProfileUpdateNonprofiScheme,
  ProfileBaseOrganizationScheme,
  ProfileUpdateOrganizationScheme,
} from "common/validation";
import { useUsersControllerSetAvatarMutation } from "app/api/hooks/users";
import { OrganizationStatusEnum, OrganizationTypeEnum } from "common/enum";
import { useOrganizationFields } from "hooks";
import { selectUser } from "app/authSlice";
import {
  OnFormStateHandlerKeys,
  OnFormStateHandlerValue,
  ProfileNestedPropsType,
  ProfileFormPropsType,
} from "./Profile.types";
import {
  useOrganizationsControllerUpdateOneMutation,
  useOrganizationsControllerGetMyQuery,
  UpdateOneOrganizationRequestDto,
} from "app/api/hooks/organizations";
import { em } from "common/funcs/em";
import { useWhatHelpTypes } from "hooks/useWhatHelpTypes";
import { useWhoHelpTypes } from "hooks/useWhoHelpTypes";
import { useCities } from "hooks/useCities";
import { useUploadAvatar } from "hooks/useUploadAvatar";
import { useUploadDocuments } from "hooks/useUploadDocuments";

const ProfilePage = () => {
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);

  const navigate = useNavigate();
  const user = useSelector(selectUser);

  // Mutations of the state
  const [updateOrganization] = useOrganizationsControllerUpdateOneMutation();
  // const [uploadDocuments] = useOrganizationsControllerUploadDocumentsMutation();
  const [uploadAvatar] = useUsersControllerSetAvatarMutation();

  // Form initialize
  const form = useForm<UpdateOneOrganizationRequestDto>({
    resolver: yupResolver(validationSchemeSwitch(user.organizationTypeId)),
    defaultValues,
  });

  // Form state
  const errors = form.formState.errors;
  const data = form.watch();

  console.log("STATE: ", data);

  // Form state handler
  const onFormStateHandler = React.useCallback(
    (
      key: OnFormStateHandlerKeys<UpdateOneOrganizationRequestDto>,
      value: OnFormStateHandlerValue,
    ) => {
      form.setValue(key, value, {
        shouldValidate: true,
      });
    },
    [],
  );

  const {
    requestedDocuments,
    uploadedDocuments,
    onDeleteRequestedDocument,
    onSetRequestedDocuments,
    onDeleteUploadedDocument,
    onUploadDocument,
  } = useUploadDocuments({ organizationId: user.organizationId });
  const { onUploadAvatar, uploadedAvatar, avatar } = useUploadAvatar();
  const { whatHelpTypesList } = useWhatHelpTypes();
  const { whoHelpTypesList } = useWhoHelpTypes();
  const { citiesList } = useCities();

  // Form submit handler
  const onFormSubmitHandler = async (
    updateOneOrganizationRequestDto: UpdateOneOrganizationRequestDto,
  ) => {
    const formData = new FormData();

    if (user.organizationTypeId === OrganizationTypeEnum["НКО"]) {
      if (requestedDocuments.length + uploadedDocuments.length < 5) {
        toast.error("Необходимо загрузить документы согласно списку");
        return;
      }
    }

    try {
      setIsRequestLoading(true);

      const updatedOrganization = await updateOrganization({
        id: user.organizationId,
        updateOneOrganizationRequestDto,
      }).unwrap();

      // If avatar was uploaded
      if (uploadedAvatar) {
        formData.set("avatar", uploadedAvatar as Blob, uploadedAvatar.name);

        await uploadAvatar({
          id: user.userId,
          body: formData as any,
        }).unwrap();

        formData.delete("avatar");
      }

      if (updatedOrganization) {
        // Routing for organizations that have status "Кандидат"
        if (
          updatedOrganization.organizationStatus.id ===
          OrganizationStatusEnum["Кандидат"]
        ) {
          navigate("waiting");
        } else {
          navigate("actions");
        }
      }

      form.reset();

      toast.success("Информация успешно сохранена");
    } catch (e) {
      console.log(e);
      toast.error(em(e));
    } finally {
      setIsRequestLoading(false);
    }
  };

  // Organization data from request
  const { data: organization, isLoading } =
    useOrganizationsControllerGetMyQuery(undefined, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
      pollingInterval: 60000,
    });

  // Hook convert organization fields to form state
  useOrganizationFields<UpdateOneOrganizationRequestDto>({
    organization,
    data,
    cbFv: (key, value) => {
      form.setValue(key, value);
    },
    cbDc: (documents) => {
      onSetRequestedDocuments(documents);
    },
  });

  // Organization accepted or not
  const isAccepted =
    organization?.organizationStatusId ===
      OrganizationStatusEnum["Действующий"] && !!organization?.INN;

  if (isLoading) {
    return (
      <>
        <BasePage>
          <BaseLoader />
        </BasePage>
      </>
    );
  }

  return (
    <>
      <BasePage>
        <form onSubmit={form.handleSubmit(onFormSubmitHandler)}>
          <ProfileRoute
            organizationStatusId={organization?.organizationStatus.id}
          >
            <OrganizationUser
              {...organizationUserProps(
                user.organizationTypeId,
                isRequestLoading,
                data.city?.name,
                isAccepted,
              )}
            >
              <ProfileForm
                onFormStateHandler={onFormStateHandler}
                onUploadDocument={onUploadDocument}
                onDeleteRequestedDocument={onDeleteRequestedDocument(
                  user.organizationId,
                )}
                onDeleteUploadedDocument={onDeleteUploadedDocument}
                onUploadAvatar={onUploadAvatar(true)}
                requestedDocuments={requestedDocuments}
                uploadedDocuments={uploadedDocuments}
                organizationTypeId={user.organizationTypeId}
                whatHelpTypesList={whatHelpTypesList}
                whoHelpTypesList={whoHelpTypesList}
                citiesList={citiesList}
                isAccepted={isAccepted}
                errors={errors}
                avatar={avatar}
                data={data}
              />
            </OrganizationUser>
          </ProfileRoute>
        </form>
      </BasePage>
    </>
  );
};

// The profile form, it checks organization type id and displays of form
const ProfileForm = ({
  organizationTypeId,
  ...rest
}: ProfileFormPropsType &
  ProfileNestedPropsType<UpdateOneOrganizationRequestDto>) => {
  switch (organizationTypeId) {
    case OrganizationTypeEnum["ЮРЛИЦО"]:
      return <ProfileOrganization {...rest} />;
    case OrganizationTypeEnum["НКО"]:
      return <ProfileNonprofit {...rest} />;
    case OrganizationTypeEnum["ФИЗЛИЦО"]:
    case OrganizationTypeEnum["ИП"]:
      return <ProfilePerson {...rest} />;
    default:
      return <>Что-то пошло не так...</>;
  }
};

// If organization has status "Кандидат" or "Отказ" returns to waiting page or home page
const ProfileRoute = React.memo(
  (props: { children: JSX.Element; organizationStatusId?: number }) => {
    switch (props.organizationStatusId) {
      case OrganizationStatusEnum["Кандидат"]:
        return <Navigate to="waiting" />;
      case OrganizationStatusEnum["Отказ"]:
        return <Navigate to="reject" />;
      default:
        return props.children;
    }
  },
);

const organizationUserProps = (
  organizationTypeId: number | undefined | null,
  isRequestLoading: boolean,
  city: string | undefined,
  isAccepted: boolean,
) => {
  switch (organizationTypeId) {
    case OrganizationTypeEnum["ЮРЛИЦО"]:
    case OrganizationTypeEnum["НКО"]:
      return { city, subtitle: "Организация", isRequestLoading, isAccepted };
    case OrganizationTypeEnum["ФИЗЛИЦО"]:
    case OrganizationTypeEnum["ИП"]:
      return {
        city,
        subtitle: "Личная информация",
        isRequestLoading,
        isAccepted,
      };
    default:
      return { city, subtitle: "Организация", isRequestLoading, isAccepted };
  }
};

const validationSchemeSwitch = (organizationTypeId: number) => {
  switch (organizationTypeId) {
    case OrganizationTypeEnum["ЮРЛИЦО"]:
      return ProfileUpdateOrganizationScheme;
    case OrganizationTypeEnum["НКО"]:
      return ProfileUpdateNonprofiScheme;
    case OrganizationTypeEnum["ФИЗЛИЦО"]:
    case OrganizationTypeEnum["ИП"]:
      return ProfileBaseOrganizationScheme;
    default:
      return ProfileBaseOrganizationScheme;
  }
};

const defaultValues: UpdateOneOrganizationRequestDto = {
  lastName: "",
  firstName: "",
  INN: "",
  name: "",
  organizationType: undefined,
  siteLink: "",
  email: "",
  phoneNumber1: "",
  phoneNumber2: "",
  city: undefined,
  address: "",
  description: "",
  whatHelpTypes: [],
  whoHelpTypes: [],
  vkLink: undefined,
  okLink: undefined,
  isAcceptedCashPolicy: false,
  isAcceptedNoCashPolicy: false,
};

export default ProfilePage;

import { apiSlice as api } from "../apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    organizationsWhatHelpControllerGetAll: build.query<
      OrganizationsWhatHelpControllerGetAllApiResponse,
      OrganizationsWhatHelpControllerGetAllApiArg
    >({
      query: () => ({ url: `/api/v1/what-helps` }),
    }),
    organizationsWhoHelpControllerGetAll: build.query<
      OrganizationsWhoHelpControllerGetAllApiResponse,
      OrganizationsWhoHelpControllerGetAllApiArg
    >({
      query: () => ({ url: `/api/v1/who-helps` }),
    }),
    organizationsControllerGetAll: build.query<
      OrganizationsControllerGetAllApiResponse,
      OrganizationsControllerGetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations`,
        params: {
          organizationStatusId: queryArg.organizationStatusId,
          organizationTypeId: queryArg.organizationTypeId,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    organizationsControllerCreateOne: build.mutation<
      OrganizationsControllerCreateOneApiResponse,
      OrganizationsControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations`,
        method: "POST",
        body: queryArg.createOneOrganizationRequestDto,
      }),
    }),
    organizationsControllerGetMy: build.query<
      OrganizationsControllerGetMyApiResponse,
      OrganizationsControllerGetMyApiArg
    >({
      query: () => ({ url: `/api/v1/organizations/my` }),
    }),
    organizationsControllerGetOne: build.query<
      OrganizationsControllerGetOneApiResponse,
      OrganizationsControllerGetOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/organizations/${queryArg.id}` }),
    }),
    organizationsControllerUpdateOne: build.mutation<
      OrganizationsControllerUpdateOneApiResponse,
      OrganizationsControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateOneOrganizationRequestDto,
      }),
    }),
    organizationsControllerDeleteOne: build.mutation<
      OrganizationsControllerDeleteOneApiResponse,
      OrganizationsControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    organizationsControllerUpdateStatus: build.mutation<
      OrganizationsControllerUpdateStatusApiResponse,
      OrganizationsControllerUpdateStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/${queryArg.id}/status`,
        method: "PATCH",
        body: queryArg.updateOrganizationStatusRequestDto,
      }),
    }),
    organizationsControllerUploadDocument: build.mutation<
      OrganizationsControllerUploadDocumentApiResponse,
      OrganizationsControllerUploadDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/${queryArg.id}/document`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    organizationsControllerGetDocuments: build.query<
      OrganizationsControllerGetDocumentsApiResponse,
      OrganizationsControllerGetDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/${queryArg.id}/documents`,
      }),
    }),
    organizationsControllerUploadDocuments: build.mutation<
      OrganizationsControllerUploadDocumentsApiResponse,
      OrganizationsControllerUploadDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/${queryArg.id}/documents`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    organizationsControllerDeleteDocument: build.mutation<
      OrganizationsControllerDeleteDocumentApiResponse,
      OrganizationsControllerDeleteDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/${queryArg.id}/documents`,
        method: "DELETE",
        body: queryArg.deleteOrganizationDocumentRequestDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type OrganizationsWhatHelpControllerGetAllApiResponse =
  /** status 200  */ WhatHelpTypes[];
export type OrganizationsWhatHelpControllerGetAllApiArg = void;
export type OrganizationsWhoHelpControllerGetAllApiResponse =
  /** status 200  */ WhoHelpTypes[];
export type OrganizationsWhoHelpControllerGetAllApiArg = void;
export type OrganizationsControllerGetAllApiResponse =
  /** status 200  */ any & {
    items?: Organizations[];
    totalPages?: number;
    count?: number;
  };
export type OrganizationsControllerGetAllApiArg = {
  organizationStatusId?: number;
  organizationTypeId?: number;
  page: number;
  limit: number;
};
export type OrganizationsControllerCreateOneApiResponse =
  /** status 200  */ Organizations;
export type OrganizationsControllerCreateOneApiArg = {
  createOneOrganizationRequestDto: CreateOneOrganizationRequestDto;
};
export type OrganizationsControllerGetMyApiResponse =
  /** status 200  */ Organizations;
export type OrganizationsControllerGetMyApiArg = void;
export type OrganizationsControllerGetOneApiResponse =
  /** status 200  */ Organizations;
export type OrganizationsControllerGetOneApiArg = {
  id: number;
};
export type OrganizationsControllerUpdateOneApiResponse =
  /** status 200  */ Organizations;
export type OrganizationsControllerUpdateOneApiArg = {
  id: number;
  updateOneOrganizationRequestDto: UpdateOneOrganizationRequestDto;
};
export type OrganizationsControllerDeleteOneApiResponse =
  /** status 200  */ Organizations;
export type OrganizationsControllerDeleteOneApiArg = {
  id: number;
};
export type OrganizationsControllerUpdateStatusApiResponse =
  /** status 200  */ Organizations;
export type OrganizationsControllerUpdateStatusApiArg = {
  id: number;
  updateOrganizationStatusRequestDto: UpdateOrganizationStatusRequestDto;
};
export type OrganizationsControllerUploadDocumentApiResponse =
  /** status 200  */ Multimedia[];
export type OrganizationsControllerUploadDocumentApiArg = {
  id: number;
  body: {
    avatar?: Blob;
  };
};
export type OrganizationsControllerGetDocumentsApiResponse =
  /** status 200  */ Multimedia;
export type OrganizationsControllerGetDocumentsApiArg = {
  id: number;
};
export type OrganizationsControllerUploadDocumentsApiResponse =
  /** status 200  */ Multimedia[];
export type OrganizationsControllerUploadDocumentsApiArg = {
  id: number;
  body: {
    multimedia?: Blob;
  };
};
export type OrganizationsControllerDeleteDocumentApiResponse =
  /** status 200  */ Multimedia;
export type OrganizationsControllerDeleteDocumentApiArg = {
  id: number;
  deleteOrganizationDocumentRequestDto: DeleteOrganizationDocumentRequestDto;
};
export type OrganizationStatuses = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type OrganizationTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type UserSessions = {
  id: number;
  refreshToken?: string;
  fingerPrint?: string;
  userId: number;
  user: Users;
};
export type RequestStatuses = {
  id: number;
  name: string;
  requests: Requests[];
};
export type Requests = {
  id: number;
  name: string;
  description: string;
  address: string;
  organizationHelpedId?: number;
  publishedAt?: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  statusId: number;
  status: RequestStatuses;
  organizationId: number;
  organization: Organizations;
  userId: number;
  user: Users;
};
export type UserRoles = {
  id: number;
  name: string;
  users: Users[];
};
export type Users = {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  vkLink?: string;
  okLink?: string;
  isAcceptedCashPolicy: boolean;
  isAcceptedNoCashPolicy: boolean;
  createdAt: string;
  updatedAt: string;
  sessions?: UserSessions;
  origanization?: Organizations;
  requests: Requests[];
  avatar?: Multimedia;
  roleId: number;
  role: UserRoles;
};
export type Multimedia = {
  id: number;
  fileSize: number;
  fileName: string;
  originalFileName: string;
  mimeType: string;
  path: string;
  device?: string;
  createdAt: string;
  updatedAt: string;
  userId?: number;
  user?: Users;
  organizationId?: number;
  organization?: Organizations;
  materialsId?: number;
  materials?: Materials;
};
export type MaterialAuthors = {
  id: number;
  name: string;
  Materials: Materials[];
};
export type Materials = {
  id: number;
  title: string;
  sign: string;
  text: string;
  createdAt: string;
  updatedAt: string;
  whoHelpTypes: WhoHelpTypes[];
  multimedia: Multimedia[];
  authorId: number;
  author: MaterialAuthors;
};
export type WhoHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
  materials: Materials[];
  requests: Requests[];
};
export type Cities = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type Organizations = {
  id: number;
  name?: string;
  siteLink?: string;
  INN?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  address?: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  organizationStatusId: number;
  organizationStatus: OrganizationStatuses;
  organizationTypeId: number;
  organizationType: OrganizationTypes;
  whatHelpTypes: WhatHelpTypes[];
  whoHelpTypes: WhoHelpTypes[];
  userId: number;
  user: Users;
  documents: Multimedia[];
  requests: Requests[];
  cityId?: number;
  city?: Cities;
};
export type WhatHelpTypes = {
  id: number;
  name: string;
  organizations: Organizations[];
};
export type OptionDto = {
  id: number;
  name: string;
};
export type CreateOneOrganizationRequestDto = {
  INN?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  email?: string;
  city?: OptionDto;
  address?: string;
  siteLink?: string;
  organizationType?: OptionDto;
  name?: string;
  whoHelpTypes: OptionDto[];
  whatHelpTypes: OptionDto[];
  description?: string;
  firstName?: string;
  lastName?: string;
  vkLink?: string;
  okLink?: string;
  isAcceptedCashPolicy?: boolean;
  isAcceptedNoCashPolicy?: boolean;
};
export type UpdateOneOrganizationRequestDto = {
  INN?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  email?: string;
  city?: OptionDto;
  address?: string;
  siteLink?: string;
  organizationType?: OptionDto;
  name?: string;
  whoHelpTypes: OptionDto[];
  whatHelpTypes: OptionDto[];
  description?: string;
  firstName?: string;
  lastName?: string;
  vkLink?: string;
  okLink?: string;
  isAcceptedCashPolicy?: boolean;
  isAcceptedNoCashPolicy?: boolean;
};
export type UpdateOrganizationStatusRequestDto = {
  statusId: number;
};
export type DeleteOrganizationDocumentRequestDto = {
  multimediaId: number;
};
export const {
  useOrganizationsWhatHelpControllerGetAllQuery,
  useOrganizationsWhoHelpControllerGetAllQuery,
  useOrganizationsControllerGetAllQuery,
  useOrganizationsControllerCreateOneMutation,
  useOrganizationsControllerGetMyQuery,
  useOrganizationsControllerGetOneQuery,
  useOrganizationsControllerUpdateOneMutation,
  useOrganizationsControllerDeleteOneMutation,
  useOrganizationsControllerUpdateStatusMutation,
  useOrganizationsControllerUploadDocumentMutation,
  useOrganizationsControllerGetDocumentsQuery,
  useOrganizationsControllerUploadDocumentsMutation,
  useOrganizationsControllerDeleteDocumentMutation,
} = injectedRtkApi;

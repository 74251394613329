import React from "react";
import { styled } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const StyledTextarea = styled(TextField)(({ theme }) => ({
  '& > .MuiInputBase-multiline': {
    padding: "3rem 1.6rem !important",
  },
  "& > .MuiInputBase-multiline > textarea": {
    padding: "0 !important",
    overflow: "auto !important",
    minHeight: "14.4rem",
    maxHeight: "14.4rem",
  },
  [theme.breakpoints.down('md')]: {
    '& > label': {
      fontSize: '1.4rem !important',
    },
    '& > .MuiInputBase-multiline > fieldset > legend': {
      fontSize: '1.1 !important',
    },
  },
}));

type PropsType = TextFieldProps;

const BaseTextarea = ({ className = '', ...rest }: PropsType) => {
  return (
    <>
      <StyledTextarea
        className={className}
        multiline
        {...rest}
        rows={4}
      />
    </>
  );
};

const MemoizedBaseTextarea = React.memo(BaseTextarea, (prevProps, nextProps) => {
  if (prevProps.value === nextProps.value) {
    return true;
  }

  return false;
});

export default MemoizedBaseTextarea;

import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import { BaseButton, BaseLink, BasePageForm } from "components/base";
import { FormCard, FormCode, FormInput } from "content";

import { useUsersControllerSendConfirmationCodeMutation } from "app/api/hooks/users";
import { SignupNeedHelpScheme } from "common/validation";
import { OrganizationTypeEnum } from "common/enum";
import {
  SignupRequestDto,
  useAuthControllerSignupMutation,
} from "app/api/hooks/auth";
import { useWindowSize } from "hooks";
import { em } from "common/funcs/em";

const SignupNeedHelpPage = () => {
  const { isMobile } = useWindowSize();

  // Form initialization
  const form = useForm<SignupRequestDto>({
    defaultValues: {
      password: "",
      email: "",
      confirmationCode: "",
      organizationTypeId: OrganizationTypeEnum["НКО"], // НКО
    },
    resolver: yupResolver(SignupNeedHelpScheme),
  });

  // Form state
  const formState = form.watch();
  const errors = form.formState.errors;

  // Form handler
  const onHandleState = React.useCallback(
    (key: keyof SignupRequestDto, value: string) => {
      form.setValue(key, value.trim(), {
        shouldValidate: true,
      });
    },
    [],
  );

  // Hooks
  const [sendConfirmationCode] =
    useUsersControllerSendConfirmationCodeMutation();
  const [signup] = useAuthControllerSignupMutation();
  const navigation = useNavigate();

  // Form submit handler
  const onHandleSubmit = async (signupRequestDto: SignupRequestDto) => {
    try {
      await signup({ signupRequestDto }).unwrap();

      navigation("/signon");
    } catch (e: any) {
      toast.error(em(e));
    }
  };

  // Confirmation code submit handler
  const onSubmitConfirmationCode = async () => {
    try {
      if (formState.email.trim().length < 1) {
        form.setError("email", {
          message: "Заполните email",
        });

        return;
      }

      await sendConfirmationCode({
        sendConfirmationCodeRequestDto: {
          email: formState.email,
          isRestore: false,
        },
      }).unwrap();
    } catch (e: any) {
      toast.error(em(e));
    }
  };

  return (
    <>
      <BasePageForm>
        <FormCard<SignupRequestDto>>
          <form onSubmit={form.handleSubmit(onHandleSubmit)}>
            <FormCard.Row>
              <FormCard.Title>Регистрация</FormCard.Title>
            </FormCard.Row>
            <FormCard.Row marginTop={isMobile ? "3rem" : "5rem"}>
              <FormInput
                placeholder="Email"
                errorMessage={errors["email"]?.message}
                onChange={(text) => {
                  onHandleState("email", text);
                }}
                value={formState.email}
                type="text"
              />
            </FormCard.Row>
            <FormCard.Row marginTop="2rem">
              <FormInput
                placeholder="Пароль"
                errorMessage={errors["password"]?.message}
                onChange={(text) => {
                  onHandleState("password", text);
                }}
                value={formState.password}
                type="password"
              />
            </FormCard.Row>
            <FormCard.Row marginTop="2rem">
              <FormCode
                placeholder="Код"
                errorMessage={errors["confirmationCode"]?.message}
                onChange={(text) => {
                  onHandleState("confirmationCode", text);
                }}
                onClick={() => {
                  onSubmitConfirmationCode();
                }}
                value={formState.confirmationCode}
              />
            </FormCard.Row>
            <FormCard.Row marginTop="4rem">
              <BaseButton
                fullWidth={true}
                variant="main_contained_tangerine"
                type="submit"
              >
                Продолжить
              </BaseButton>
            </FormCard.Row>
            <FormCard.Row marginTop="3rem">
              <BaseLink variant="form" to="/signon">
                Войти
              </BaseLink>
            </FormCard.Row>
          </form>
        </FormCard>
      </BasePageForm>
    </>
  );
};

export default SignupNeedHelpPage;

import { Navigate, Outlet, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { selectUser } from 'app/authSlice';
import { RoleEnum } from 'common/enum';
import { useAuth } from 'hooks/useAuth';

type PropsType = {
  allowedRoles: Array<RoleEnum>;
}

const RoutePrivate = ({ allowedRoles }: PropsType) => {
  const { isAuthed } = useAuth();
  const location = useLocation();
  const user = useSelector(selectUser);

  const foundRole = allowedRoles.find((role) => user?.role === role);

  if (foundRole) {
    return (
      <>
        <Outlet />
      </>
    );
  } else {
    if (isAuthed) {
      return (
        <>
          <Navigate
            to="/unauthorized"
            replace
            state={{
              path: location.pathname,
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <Navigate
            to="/signon"
            replace
            state={{
              path: location.pathname,
            }}
          />
        </>
      );
    }
  }
};

export default RoutePrivate;

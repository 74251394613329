import React from 'react';

export type UseToggleResponse = {
  isActive: boolean;
  onToggle: () => void;
  onClose: () => void;
  onOpen: () => void;
};

export const useToggle = (defaultState: boolean = false): UseToggleResponse => {
  const [isActive, setActive] = React.useState<boolean>(defaultState);

  const onToggle = () => setActive((value) => !value);
  const onClose = () => setActive(false);
  const onOpen = () => setActive(true);

  return { isActive, onToggle, onClose, onOpen };
};

import toastHeadle, { Toast, ToastType, useToaster } from 'react-hot-toast/headless';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import styles from "./Alert.module.scss";
import { BaseLoader } from 'components/base';

const Alert = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  const typeIcon = (type: ToastType) => {
    switch (type) {
      case 'success':
        return <DoneIcon htmlColor={"#177943"} />;
      case 'loading':
        return <BaseLoader style={{ width: "2rem", height: "2rem" }} />
      case 'error':
      default:
        return <ErrorOutlineIcon htmlColor={"#ed5338"} />;
    }
  };

  const onCalcOffset = <T extends Toast>(toast: T) => calculateOffset(toast, {
    reverseOrder: false,
    gutter: 8,
  });

  const onRef = <T extends Toast>(toast: T) => (el: HTMLElement) => {
    if (el && typeof toast.height !== "number") {
      const height = el.getBoundingClientRect().height;
      updateHeight(toast.id, height);
    }
  }

  return (
    <>
      <div
        className={styles.Alert}
        onMouseEnter={startPause}
        onMouseLeave={endPause}
      >
        {
          toasts.filter((toast) => toast.visible).map((toast: any) => {
            const offset = onCalcOffset(toast);
            const ref = onRef(toast);
            return (
              <div
                style={{
                  transition: 'all 0.5s ease-out',
                  transform: `translateY(${offset / 4}px)`,
                  opacity: toast.visible ? 1 : 0,
                }}
                key={toast.id}
                ref={ref}
                {...toast.ariaProps}
              >
                <div className={styles.Alert__inner}>
                  <div className={styles.Alert__icon}>
                    {typeIcon(toast.type)}
                  </div>
                  <p className={styles.Alert__text}>
                    {toast.message}
                  </p>
                  <div className={styles.Alert__icon}>
                    <CloseIcon onClick={() => toastHeadle.dismiss(toast.id)} />
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  );
};

export default Alert;

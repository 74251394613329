import React from "react";
import { NoEntries, RequestPreview, Select } from "content";
import { useSelector } from "react-redux";
import {
  BaseContent,
  BaseLoader,
  BasePage,
  BasePagination,
  BaseTypography,
} from "components/base";

import {
  useRequestsControllerGetAllQuery,
  RequestsControllerGetAllApiArg,
} from "app/api/hooks/requests";
import { useCitiesControllerGetAllQuery } from "app/api/hooks/cities";
import { RequestStatusEnum } from "common/enum";
import { propsRequest } from "common/funcs/props-request.func";
import { useFilters } from "hooks";
import { selectUser } from "app/authSlice";
import { OptionType } from "common/types/option.type";
import { Requests } from "app/api/hooks/organizations";
import styles from "./Requests.module.scss";

const RequestsPage = () => {
  // useFilter is a custom hook for filter of state
  const { filters, onHandleFilters } =
    useFilters<RequestsControllerGetAllApiArg>({
      directionId: undefined,
      statusId: RequestStatusEnum["Опубликовано"],
      cityId: undefined,
      limit: 5,
      page: 1,
    });

  // Request to cities
  const { data: cities } = useCitiesControllerGetAllQuery({
    limit: 322,
    page: 1,
  });

  const [city, setCity] = React.useState<OptionType | undefined>();

  React.useEffect(() => {
    if (!cities && !cities?.items) return;
    setCity(cities.items[0]);
  }, [cities]);

  // All requests
  const { data, isLoading } = useRequestsControllerGetAllQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  const isItemsMoreThenZero = data?.items?.length > 0;
  const user = useSelector(selectUser);

  return (
    <>
      <BasePage className={styles.RequestsPage}>
        <BaseContent className={styles.RequestsPage__inner}>
          <div className={styles.RequestsPage__header}>
            <BaseTypography variant="h2" type="prt">
              Запрос услуги/ресурсы
            </BaseTypography>
          </div>
          <div className={styles.RequestsPage__filters}>
            <Select
              className={styles.RequestsPage__filter}
              onChange={(selected) => {
                if (Array.isArray(selected)) return;

                setCity(selected);
                onHandleFilters({ cityId: selected.id });
              }}
              multiple={false}
              value={city}
              variant="select"
              label="Город"
            >
              <Select.OptionList>
                {cities?.items &&
                  cities.items.map((item: OptionType, i: number) => (
                    <Select.OptionItem
                      selected={city?.id === item.id}
                      key={item.id}
                      {...item}
                    />
                  ))}
              </Select.OptionList>
            </Select>
          </div>
          <div className={styles.RequestsPage__body}>
            {isLoading ? (
              <BaseLoader />
            ) : isItemsMoreThenZero ? (
              data.items.map((item: Requests) => (
                <RequestPreview
                  {...propsRequest(item)}
                  organizationTypeId={user?.organizationTypeId}
                />
              ))
            ) : (
              <NoEntries />
            )}
          </div>
          <div className={styles.RequestsPage__footer}>
            {isItemsMoreThenZero ? (
              <BasePagination
                onChange={(page) => onHandleFilters({ page })}
                count={data.totalPages}
                page={filters.page}
              />
            ) : null}
          </div>
        </BaseContent>
      </BasePage>
    </>
  );
};

export default RequestsPage;

import { mcn } from 'common/funcs/mcn.func';
import { HTMLAttributes } from 'react';

import styles from './BaseContent.module.scss';

type PropsType = {
  className?: string;
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const BaseContent = ({ className = '', children, ...rest }: PropsType) => {
  return (
    <div className={mcn([styles.BaseContent, className])} {...rest}>
      {children}
    </div>
  );
};

export default BaseContent;

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import { AuthProvider, ThemeProvider, AvatarProvider } from 'components/providers';
import { Alert } from 'content';
import App from './App';

import store from './app/store';
import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    main_contained_published: true;
    main_contained_complaint: true;
    main_contained_blue_dk: true;
    main_contained_orange: true;
    main_contained_blue: true;
    main_contained_tangerine: true;
    main_contained_green: true;
    main_outlined_tangerine: true;
    main_outlined_blue: true;
    main_outlined_red: true;
    main_text: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <AvatarProvider>
          <ThemeProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
            <Alert />
          </ThemeProvider>
        </AvatarProvider>
      </AuthProvider>
    </Provider>
  </BrowserRouter>,
);

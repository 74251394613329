import { Link } from 'react-router-dom';

import { BaseContainer, BaseImage, BaseLink } from 'components/base';

import styles from './Footer.module.scss';
import logoKaliningrad from 'common/svg/svg-logo-kaliningrad.svg';
import logoFond from 'common/svg/svg-white-logo.svg';
import logoNGO from 'common/svg/svg-logo-ngo-footer-icon.svg';

const Footer = () => {
  return (
    <footer className={styles.Footer}>
      <BaseContainer className={styles.Footer__inner}>
        <Link to="/">
          <BaseImage
            className={styles.Footer__logo}
            height="83"
            width="129"
            alt="Логотип"
            src={logoFond}
          />
        </Link>
        <ul className={styles.Footer__navigation}>
          {navigationLinks.map(({ to, name }, key) => (
            <li className={styles.Footer__item} key={key}>
              <BaseLink
                className={styles.Footer__link}
                variant="footer"
                to={to}
              >
                {name}
              </BaseLink>
            </li>
          ))}
        </ul>
        <ul className={styles.Footer__additional}>
          <li className={styles.Footer__logo_kaliningrad}>
            <a
              className={styles.Footer__logo_kaliningrad}
              href='https://mvp.gov39.ru/'
              target="_blank"
              rel="noreferrer"
            >
              <BaseImage
                src={logoKaliningrad}
                alt="Калиниградская область"
                height="41"
                width="41"
              />
              <p>Министерство по муниципальному развитию и внутренней политике Калининградской области</p>
            </a>
          </li>
          <li className={styles.Footer__logo_ngo}>
            <a
              className={styles.Footer__logo_ngo}
              href='https://nko.gov39.ru/'
              target="_blank"
              rel="noreferrer"
            >
              <BaseImage
                src={logoNGO}
                alt="Логотип НКО"
                height="41"
                width="41"
              />
            </a>
          </li>
          <li className={styles.Footer__year}>
            2022
          </li>
        </ul>
      </BaseContainer>
    </footer>
  );
};

const navigationLinks = [
  {
    to: '/requests',
    name: 'Запросы',
  },
  {
    to: '/',
    name: 'О проекте',
  },
  {
    to: '/materials',
    name: 'Полезные материалы',
  },
];

export default Footer;

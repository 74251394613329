import React from 'react';

import { BaseTag } from 'components/base';

import { OptionType } from 'common/types/option.type';
import { mcn } from 'common/funcs/mcn.func';
import styles from './Tags.module.scss';

type PropsType = {
  className?: string;
  items?: Array<OptionType>;
  variant: 'common' | 'preview';
};

const Tags = React.memo(
  ({
    className = '',
    variant,
    items = [],
  }: PropsType) => {
    return (
      <>
        <div className={mcn([styles.Tags, className])}>
          {items.map((item, key) => {
            return (
              <BaseTag
                className={styles.Tags__item}
                variant={variant}
                key={key}
                {...item}
              />
            );
          })}
        </div>
      </>
    );
  },
);

export default Tags;

import React from "react";

import { Multimedia, Organizations, Users } from "app/api/hooks/organizations";

type UseOrganizationFieldsPropsType<T, V> = {
  organization: Organizations | undefined;
  data: T;
  cbFv: (key: keyof T, value: V) => void;
  cbDc: (documents: Array<Multimedia>) => void;
};

export const useOrganizationFields = <T, V = any>({
  organization,
  data,
  cbFv,
  cbDc,
}: UseOrganizationFieldsPropsType<T, V>): void => {
  React.useEffect(() => {
    if (!organization) return;

    for (const key in data) {
      // If organization field exists
      if (organization && key in organization) {
        if (organization[key as keyof Organizations] === null) continue;

        cbFv(
          key as keyof T,
          organization[key as keyof Organizations] as unknown as V,
        );

        continue;
      }

      // If user field exists
      if (organization?.user && key in organization.user) {
        if (organization.user[key as keyof Users] === null) continue;

        cbFv(
          key as keyof T,
          organization.user[key as keyof Users] as unknown as V,
        );

        continue;
      }

      continue;
    }

    // If organization has documents we set them to state
    if ("documents" in organization) {
      cbDc(organization.documents);
    }
  }, [organization]);
};

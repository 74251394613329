import React from 'react';

export type State<T> = {
  [K in keyof T]: T[K];
};

export type FormCardContextPropsType<T> = {
  state: State<T>;
  errors: {
    [T in keyof State<T>]:
      | {
          message: 'Невалидный Email';
          ref: any;
          type: keyof State<T>;
        }
      | undefined;
  };
  onHandleState: (key: keyof State<T>, value: string | number) => void;
};

export const FormCardContext = React.createContext({});

export const useFormCardContext = <T,>() =>
  React.useContext(FormCardContext) as FormCardContextPropsType<T>;
